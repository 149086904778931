<template>
  <div v-loading="detail.loading">
    <my-list :list="[detail.output.scPairInfo]" mode="large" stopToDetail>
      <template #right="{i}">
        <!--  0已终止1未启动2已启动3已完成-->
        <el-button type="primary" size="small" @click="detail.changeStatus('2')" v-if="detail.output.scPair.status < 2">启动项目</el-button>
        <el-button type="primary" size="small" @click="detail.changeStatus('3')" v-if="detail.output.scPair.status == 2">完成项目</el-button>
        <el-button type="danger" size="small" @click="detail.changeStatus('0')" v-if="detail.output.scPair.status == 2">终止项目</el-button>
        <el-button type="primary" size="small" plain v-if="detail.output.scPair.status == 3">已完成</el-button>
      </template>
    </my-list>

  </div>
</template>
<style src="./index.scss" scoped lang="scss"></style>
<script src="./index.js"></script>