<template>
  <div>
    <el-tag
        v-for="tag in tags"
        :key="tag.name"
        class="mx-1"
        closable
        :type="tag.type"
    >{{ tag.name }}</el-tag>
    <el-button plain type="primary">+添加标签</el-button>

    <el-dialog
        v-model="dialogVisible"
        title="标签选中" top="3vh"
        width="80%">

      <template #footer>
        <div style="text-align: center">
          <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script src="./index.js"></script>
<style scoped lang="scss">

</style>