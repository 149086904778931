import mixin from "@/common/mixin";
import {getCurrentInstance, reactive, watch} from "vue";
import ajax from "@/common/ajax";
import {useRouter, useRoute} from "vue-router";
import {apis} from "@/utils/apis";

export default {
  mixins: [mixin],
  setup () {
    const vm = getCurrentInstance()
    const router = useRouter()
    const route = useRoute()


    const notice = apis.list({
      api: 'noticeList',
      input: {
        pageSize: 15,
        type: route.query.type || 1
      },
    })
    notice.update()

    return {notice, router}
  }
}