import mixin from "@/common/mixin";
import {getCurrentInstance, reactive, watch} from "vue";
import ajax from "@/common/ajax";
import {useRouter} from "vue-router";
import {apis} from "@/utils/apis";
import enums from "../../../../../common/model/enums";

export default {
  mixins: [mixin],
  setup () {
    const vm = getCurrentInstance()
    const router = useRouter()
    const achievement = apis.list({api: 'caseList', input: {pageSize: 15}})
    achievement.update()

    const pairInfo = reactive({
      output: {total: 0, rows: []},
      input: {
        pageNum: 1, pageSize: 4
      },
      async update () {
        let res = await ajax({url: '/website/common/pairInfoList', data: pairInfo.input})
        if (res.code !== 200) return
        pairInfo.output = res
      }
    })
    pairInfo.update()

    return {enums, achievement, pairInfo, router}
  }
}