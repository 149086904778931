<template>
  <div class="form">
    <el-button class="edit-button" size="small" @click="back">取消</el-button>
    <div fx class="title">
      <div v-if="isAchievement || $route.query.type=='cg'">成果{{props.id ? '编辑' : '发布'}}<span>(*为必填)</span></div>
      <div v-else>{{ enums.itemType.getItem(user.type).label }}{{props.id ? '编辑' : '发布'}}<span>(*为必填)</span></div>
    </div>
    <el-form :model="form.input" ref="formRef" label-width="150px" :rules="form.rules">
      <el-form-item label="名称" prop="name" :rules="[{required: true, message: '必填项'}]">
        <el-input v-model="form.input.name" placeholder="请输入"></el-input>
      </el-form-item>
      <my-industry :input="form.input"></my-industry>
      <el-form-item label="服务类型" prop="type" v-if="user.type === '3' && !isAchievement" :rules="[{required: true, message: '必填项'}]">
        <el-select v-model="form.input.type" placeholder="请选择">
          <el-option :label="i" :value="i" v-for="i in enums.serviceType"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签" prop="labels" :rules="[{required: true, message: '必填项'}]">
        <el-select v-model="form.input.labels"
            multiple placeholder="请选择">
          <el-option-group label="我的标签">
            <el-option v-for="i in enums.orgLabel(user.orgId)" :key="i.id" :label="i.name" :value="i.name"></el-option>
          </el-option-group>
          <el-option-group label="公共标签">
            <el-option v-for="i in enums.commonLabel" :key="i.id" :label="i.name" :value="i.name"></el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="详细信息" prop="describeInfo" style="width:950px" :rules="[{required: true, message: '必填项'}]">
        <my-editor v-model:value="form.input.describeInfo"></my-editor>
      </el-form-item>
      <el-form-item label="相关文档" prop="docPath" v-if="$route.query.type !='cg'">
        <my-upload v-model:value="form.input.docPath" :info="{type: user.type}" :fileType="form.fileType"></my-upload>
      </el-form-item>
      <el-form-item label="单位名称" prop="orgName">
        <el-input v-model="form.input.orgName" placeholder="请输入" disabled></el-input>
      </el-form-item>
      <el-form-item label="所属地区" prop="areaId" :rules="[{required: true, message: '必填项'}]">
        <el-select v-model="form.input.areaId" placeholder="请选择" @change="id => form.input.areaName = enums.areaList.getItem(i => i.areaId === id).areaName">
          <el-option :label="i.areaName" :value="i.areaId" v-for="i in enums.areaList"></el-option>
        </el-select>
      </el-form-item>
      <!-- 需求管理 -->
      <template v-if="user.type === '1' && !isAchievement">
        <el-form-item label="期望交付周期" prop="deliverDays" :rules="[{required: true, message: '必填项'}]">
          <el-input v-model="form.input.deliverDays" @input="v => form.input.deliverDays = (v||'').replace(/[^\d]/g, '')" placeholder="请输入">
            <template #append>天</template>
          </el-input>
        </el-form-item>
        <el-form-item label="资金规模" prop="amountRange" :rules="[{required: true, message: '必填项'}]">
          <el-select v-model="form.input.amountRange" placeholder="请选择">
            <el-option :label="i" :value="i" v-for="i in enums.amountRange"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需求类别" prop="type" :rules="[{required: true, message: '必填项'}]">
          <el-select v-model="form.input.type" placeholder="请选择">
            <el-option :label="i" :value="i" v-for="i in enums.requirementType"></el-option>
          </el-select>
        </el-form-item>
      </template>

      <!-- 资源管理、服务管理 -->
      <template v-if="(user.type === '2' || user.type === '3') || isAchievement">
        <el-form-item label="金额范围" prop="amountRange" :rules="[{required: true, message: '必填项'}]">
          <el-select v-model="form.input.amountRange" placeholder="请选择">
            <el-option :label="i" :value="i" v-for="i in enums.amountRange"></el-option>
          </el-select>
        </el-form-item>
      </template>

      <!-- 资源管理 -->
      <template v-if="user.type === '2' || isAchievement">
        <el-form-item label="成熟度" prop="maturity" :rules="[{required: true, message: '必填项'}]">
          <el-select v-model="form.input.maturity" placeholder="请选择">
            <el-option :label="i" :value="i" v-for="i in enums.maturity"></el-option>
          </el-select>
        </el-form-item>
      </template>

      <el-form-item label="合作方式" prop="cpMode" :rules="[{required: true, message: '必填项'}]">
        <el-checkbox-group v-model="form.input.cpMode">
          <el-checkbox :label="i" :value="i" v-for="i in enums.cpMode"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="封面" prop="cover" :rules="[{required: true, message: '必填项'}]">
        <my-upload v-model:value="form.input.cover" :info="{type: user.type}">
          <p>允许上传 1M 以内的图片</p>
        </my-upload>
      </el-form-item>
      <el-form-item label="联系人" prop="techDirector" :rules="[{required: true, message: '必填项'}]">
        <el-input v-model="form.input.techDirector" placeholder="请输入" style="margin-bottom:10px"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="tel">
        <el-input v-model="form.input.tel" placeholder="请输入" style="margin-bottom:10px"></el-input>
      </el-form-item>
      <el-form-item label="电子邮箱" prop="email">
        <el-input v-model="form.input.email" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit($refs.formRef)">保存</el-button>
        <el-button @click="back">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script src="./index.js"></script>
<style src="./index.scss" scoped lang="scss"></style>