<template>
  <div class="user-detail" v-if="detail">
    <slot></slot>
    <div>
      <div fx class="div-img">
        <img :src="detail.logo || '/banner.jpg'" alt="" @load="resizeImage">
      </div>
      <h1>{{ detail.orgName }}
        <el-button @click="$router.push({path: '/org/detail', query: {id: detail.organizationId}})" size="small" type="primary" plain style="margin-left:10px"><i>&#xe71f;</i>企业主页</el-button>
      </h1>
      <template v-if="user.orgId === detail.organizationId">
        <div v-if="detail.vipStatus == 1" class="vip">
          <template v-if="detail.status != 1">
            <i style="color:rgb(36,158,220)">&#xe6f6;</i>普通会员，<router-link to="/user/info/update">升级为高级会员？</router-link>
          </template>
          <template v-if="detail.status == 1">
            <i style="color:rgb(245,193,100)">&#xe6fa;</i>高级会员
            <span style="color: #999" @click="$router.push({path: '/user/info/update', query: {readonly: 1}})">（{{enums.orgStatus.getItem(detail.status).label}}）</span>
          </template>
        </div>
        <div v-if="detail.vipStatus == 2" class="vip">
          <i style="color:rgb(245,193,100)">&#xe6fa;</i>高级会员
        </div>
        <div v-if="detail.vipStatus == 3" class="vip">
          <i style="color:#999">&#xe6ef;</i>受限会员
        </div>
      </template>
      <p><i>“</i><i>”</i>{{ detail.manageRange || '暂无简介' }}</p>
    </div>
    <div>
      <el-descriptions title="基本信息" column="2">
        <el-descriptions-item label="企业规模">{{ detail.staffCnt || '无' }}</el-descriptions-item>
        <el-descriptions-item label="成立时间">{{ detail.establishDate ? new Date(detail.establishDate).format('yyyy-MM-dd') : '无'}}</el-descriptions-item>
        <el-descriptions-item label="行业领域">{{ enums.industryList.getItem(i => i.industryId == detail.industryId).name || detail.industryId}}</el-descriptions-item>
        <el-descriptions-item label="所属地市">{{ detail.areaName || '无'}}</el-descriptions-item>
        <el-descriptions-item label="注册资金">{{ detail.registCapital || '无'}}</el-descriptions-item>
        <el-descriptions-item label="社会信用代码">{{ detail.shxyNo || '无'}}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div>
      <el-descriptions title="实力展示" column="2">
        <el-descriptions-item label="擅长服务类型">{{ detail.expertServType || '无'}}</el-descriptions-item>
        <el-descriptions-item label="擅长技能">{{ detail.expertSkill || '无'}}</el-descriptions-item>
        <el-descriptions-item label="擅长领域">{{ detail.expertIndustry || '无'}}</el-descriptions-item>
        <el-descriptions-item label="科研投入">{{ detail.techInvestment || 0}}</el-descriptions-item>
      </el-descriptions>
    </div>
    <div>
      <el-descriptions title="客服信息" column="1">
        <el-descriptions-item label="官方客服">{{ detail.customerService || '无'}}</el-descriptions-item>
        <el-descriptions-item label="服务时间">{{ detail.serviceTime || '无'}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>
<script>
import mixin from "@/common/mixin";
import {getCurrentInstance} from "vue";
import enums from "../../../../common/model/enums";

export default {
  mixins: [mixin],
  props: ['detail'],
  setup() {
    const vm = getCurrentInstance()
    const user = vm.proxy.$root.userInfo
    return {user, enums}
  },
}
</script>
<style lang="scss" scoped>
.vip{
  display: flex;
  align-items: center;
  justify-content: center;
  i{
    font-size: 20px;
    margin-right: 3px;
  }
  a{
    color:#358af2;
  }
}
.user-detail{
  background-color:#FFF;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);
  position:relative;
  text-align: center;
  .edit-button{
    position:absolute;
    top:20px;
    right:20px;
  }
}
.user-detail > div{
  padding: 20px;
}
.user-detail > div + div{
  border-top: 1px solid #EEE;
}
.user-detail h1{
  text-align:center;
  font-weight: 600;
  color: #333333;
  margin-top:15px;
  margin-bottom:28px;
  font-size:30px;
}
.user-detail h1 + p{
  color:#9D9D9D;
  font-size:14px;
  padding:0 100px;
  position:relative;
}
.user-detail p{
  position:relative;
  padding:30px 80px;
  display:inline-block;
  i{
    color:#CCC;
    position:absolute;
  }
}
.user-detail p i:first-child{
  top:50%;
  font-size:60px;
  left:0;
  transform:translateY(-30%);
}
.user-detail p i:last-child{
  top:50%;
  font-size:60px;
  right:0;
  transform:translateY(-30%);
}
.div-img{
  border-radius:50%;
  width:120px;
  height:120px;
  margin:0 auto;
  margin-top: 40px;
}

</style>