import {apis} from "@/utils/apis";
import mixin from "../../../website/src/common/mixin";
import enums from "../../model/enums";
import { ElMessage, ElMessageBox } from 'element-plus'
import {useRoute, useRouter} from "vue-router";
import {reactive} from 'vue'
// import ajax from "@/common/ajax";
export default {
  mixins: [mixin],
  props: ['id', 'toDetail', 'serviceId', 'provideId', 'requirementId'],
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const user = apis.user || reactive({})
    const detail = apis.projectDetail({
      dialogVisible_start: false,
      get barStatus () {
        if ((detail.output.targetStatus === '3' || detail.output.targetStatus === '0') && detail.output.auditStatus === '1') return 3
        if (detail.output.status === '3') return 4
        return detail.output.status
      },
      // 启动项目
      async start () {
        // 符合启动条件
        if (
          detail.output.deliverDays &&
          [detail.output.requirement?.orgName, detail.output.provide?.orgName, detail.output.service?.orgName].filter(i => i).length > 1,
          detail.output.startTime &&
          detail.output.cpMode &&
          detail.output.cpMode.length &&
          detail.output.director
        ) {
          await form.submitStatus('2')
          await detail.update()
          if (detail.output.status === '2') {
            detail.dialogVisible_start = true
          } else {
            return
          }
        }

        setTimeout(() => {
          detail.dialogVisible_start = true
        }, 200)
      },
      input: JSON.parse(JSON.stringify(props)),
    })
    detail.update(null, () => {
      form.input.pairInfoId = detail.output.pairInfoId
      if (!user.type) {
        user.type = detail.output.requirement ? '1' : '2'
      }
    })

    const form = apis.form({
      api: 'projectForm', method: 'POST',
      input: {
        docPath: [], endDocPath: []
      },
      dialogVisibleEnd: false,
      docPath: [],
      endDocPath: [],
      async submitStatus (status) {
        return apis.form({
          api: 'projectStatusForm', method: 'PUT',
          input: {
            pairInfoId: detail.output.pairInfoId,
            pairId: detail.output.pairId,
            status,
            contractDocPath: detail.output.contractDocPath,
            docPath: detail.output.docPath,
            endDocPath: detail.output.endDocPath,
          }
        }).submit()
      },
      async submitDocPath (formRef) {
        form.input = {docPath: detail.output.docPath.concat(form.docPath), pairInfoId: detail.output.pairInfoId}
        await form.submit(formRef)
        form.dialogVisible = false
        form.docPath = []
        detail.update()
      },
      async submitEndDocPath (formRef) {
        detail.output.endDocPath = form.endDocPath // 覆盖旧文件，只保留新文件
        if (formRef) {
          // 表单验证
          await new Promise(resolve => {
            formRef.validate(async valid => {
              if (!valid) {
                setTimeout(() => {
                  document.querySelector('.el-form-item__error').parentNode.querySelector('input').focus()
                }, 200)
                return
              }
              resolve()
            })
          })
        }
        await form.submitStatus(form.input.status)
        form.dialogVisibleEnd = false
        form.endDocPath = []
        detail.update()
      },
      async del (id) {
        await new Promise(res => {
          ElMessageBox.confirm('您确定要删除吗？', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback (action) {
              if (action === 'confirm') res()
            }
          })
        })
        form.input = {docPath: detail.output.docPath.filter(i => i.fileId !== id), pairInfoId: detail.output.pairInfoId}
        await form.submit()
        form.dialogVisible = false
        form.docPath = []
        detail.update()
      }
    })

    return {detail, toDetail: props.toDetail, enums, form, timeoutShow (callback) {
        setTimeout(callback, 200)
      }, user, router, route
    }
  }
}