<template>
  <main v-loading="detail.loading">
    <section>
      <slot name="top" :detail="detail"></slot>
      <div fx style="align-items:flex-start;margin-top:20px">
        <slot name="left" :detail="detail"></slot>
        <div class="right">
          <div fx class="top">
            <div class="div-img" style="height:80px">
              <img :src="detail.output.cover" @load="resizeImage"/>
            </div>
            <div>
              <h1>{{ detail.output.name }}</h1>
              <div class="des">
                <span>行业分类：{{ detail.output.guidName || '无' }}</span>
                <span>需求类别：{{ detail.output.type || '无' }}</span>
                <span>交付周期：{{ detail.output.deliverDays }}天</span>
              </div>
              <div class="des">
                <span>行业领域：{{ detail.output.industryName }}</span>
                <span>资金规模：{{ detail.output.amountRange || '无' }}</span>
                <span v-if="detail.output.createTime">发布时间：{{ detail.output.createTime.replace(/\s.+$/, '') }}</span>
              </div>
              <div class="tag">
                标签：
                <div class="tags" v-if="detail.output.labels">
                  <span v-for="j in detail.output.labels">{{ j }}</span>
                </div>
                <span v-else>无</span>
              </div>
              <div>
                <div class="linear" :style="{background:
                `linear-gradient(90deg, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 10%, rgba(255,255,255, 0) ${detail.output.status / 4 * 100 - 5}%, rgba(255,255,255, 0.7) ${detail.output.status / 4 * 100 + 5}%, rgba(255,255,255, 0.7) 90%, rgba(255,255,255, 1) 100%)`
                }"></div>
                <div class="line"></div>
                <i :style="{left: detail.output.status / 4 * 100 + (detail.output.status - 2) * 2 + '%'}">&#xe701;</i>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <button style="z-index:2">需求发布</button>
                <button>审核公开</button>
                <button>公开招募</button>
                <button>达成合作</button>
              </div>
            </div>
            <el-button class="pair-btn" v-if="canPair"
                type="primary" size="small"
                @click="router.push({path: 'pair', query: {id: detail.output.requirementId}})">我要对接</el-button>
          </div>
          <div class="title-tag"><span>需求描述</span></div>
          <div class="detail ql-container ql-snow"><div class="ql-editor" v-html="detail.output.describeInfo"></div></div>
          <div class="title-tag" v-if="detail.output.docPath"><span>相关文档</span></div>
          <div class="detail" v-if="detail.output.docPath">
            <ul class="docs">
              <li v-for="i in detail.output.params.files">
                <a download :href="i.url" target="_blank">
                  <div class="doc-icon">
                    <i>&#xe6fd;</i>
                  </div>
                  <div class="doc-name">
                    <p>{{ i.fileName }}</p>
                    <span>{{ i.createTime }}</span>
                  </div>
                  <div class="doc-download">
                    <i>&#xe70b;</i>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="title-tag"><span>需求方信息</span></div>
          <div class="detail">
            <el-descriptions title="单位信息" :column="1">
              <el-descriptions-item label="名称">{{ detail.output.orgName }}<el-button @click="$router.push({path: '/org/detail', query: {id: detail.output.orgId}})" size="small" type="primary" plain style="margin-left:10px"><i>&#xe71f;</i>企业主页</el-button></el-descriptions-item>
              <el-descriptions-item label="合作方式">{{ detail.output.cpMode  && detail.output.cpMode.join('、') }}</el-descriptions-item>
              <el-descriptions-item label="所属地区">{{ detail.output.areaName || '无' }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="联系信息" :column="1">
              <el-descriptions-item label="联系人">{{ detail.output.techDirector }}</el-descriptions-item>
              <el-descriptions-item label="联系电话">{{ detail.output.tel }}</el-descriptions-item>
              <el-descriptions-item label="电子邮箱">{{ detail.output.email }}</el-descriptions-item>
            </el-descriptions>
            <div v-if="user.vipStatus==1">
              <div class="pop-up">
                <h4>当前功能为高级会员专享，请开启高级会员后再试{{searchCon}}</h4>
                <p>您当前为普通会员用户成为高级会员，即可马上开启以下特权：</p>
                <div><img src="/vip.png" /></div>
                <el-button type="primary" @click="$router.push({path: '/user/info/update'})">升级会员</el-button>
              </div>
              <div class="mask"></div>
            </div>
          </div>
        </div>
        <slot name="right" :detail="detail"></slot>
      </div>
    </section>
  </main>
</template>
<script src="./index.js"></script>
<style src="./index.scss" scoped lang="scss"></style>