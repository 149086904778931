<template>
  <main>
    <section>
      <my-list :list="achievement.output.rows" mode="case" style="margin-top:20px"></my-list>

      <el-pagination v-if="achievement.output.total > achievement.output.rows.length"
         :page-size="achievement.input.pageSize"
         :page="achievement.input.pageNum"
         background @current-change="p => achievement.update({pageNum: p})"
         layout="prev, pager, next"
         :total="achievement.output.total">
      </el-pagination>
    </section>
  </main>
</template>
<style src="./index.scss" scoped lang="scss"></style>
<script src="./index.js"></script>