<template>
  <div fx class="condition">
    <div fx>
      <div>需求状态：<el-select
          v-model="requirement.input.status" placeholder="请选择" style="width:100px" @change="requirement.update({pageNum: 1})">
        <el-option label="全部" :value="undefined"></el-option>
        <el-option
            v-for="i in enums.itemStatus"
            :key="i.value"
            :label="i.label"
            :value="i.value">
        </el-option>
      </el-select></div>
<!--      <div>发布日期：<el-date-picker-->
<!--          type="daterange"-->
<!--          range-separator="至"-->
<!--          start-placeholder="开始日期"-->
<!--          end-placeholder="结束日期">-->
<!--      </el-date-picker></div>-->
    </div>
  </div>
  <div>
    <my-list :list="requirement.output.rows" mode="large" stopToDetail>
      <template #right="{i}">
        <el-button style="color:#35B6F2" size="small" @click="router.push({path: '/user/requirement/detail', query: {id: i.requirementId}})">查看详情</el-button>
        <el-button style="color:#B07BD6" size="small" v-if="i.status !== '5'" @click="router.push({path: '/user/requirement/add', query: {id: i.requirementId}})">编辑项目</el-button>
        <el-button size="small" v-if="i.status == '4'" @click="changeStatus(i.requirementId, '3')">上架</el-button>
        <el-button size="small" v-if="i.status == '3'" @click="changeStatus(i.requirementId, '4')">下架</el-button>
        <p style="color:#358AF2;cursor:pointer" @click="router.push({path: 'pair', query: {id: i.requirementId}})"><i style="margin-right:5px">&#xe6ee;</i>对接意向人</p>
      </template>
    </my-list>
    <el-pagination v-if="requirement.output.total > requirement.output.rows.length"
                   :page-size="requirement.input.pageSize"
                   :page="requirement.input.pageNum"
                   @current-change="p => requirement.update({pageNum: p})"
                   background
                   layout="prev, pager, next"
                   :total="requirement.output.total">
    </el-pagination>
  </div>
</template>
<script src="./index.js"></script>
<style src="./index.scss" scoped lang="scss"></style>