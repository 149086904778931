<template>
  <main>
    <section>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/my-project/list' }">我参与的项目</el-breadcrumb-item>
        <el-breadcrumb-item>项目详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div fx>
        <div class="left">
          <div class="user" style="height:200px">
            <div fx><img :src="user.organization.logo || '/banner.jpg'" alt="" @load="resizeImage"></div>
            <h5>{{ user.name }}</h5>
            <p>{{ user.orgName }}</p>
          </div>
        </div>
        <my-project-detail class="right"
                           :id="$route.query.id"
                           :requirementId="$route.query.requirementId"
                           :provideId="$route.query.provideId"
                           :serviceId="$route.query.serviceId"
                           :toDetail="toDetail"
        ></my-project-detail>
      </div>
    </section>
  </main>
</template>
<style scoped src="../../user/index.scss" lang="scss"></style>
<script src="./index.js"></script>
<style scoped>
.condition{
  background-color:#FFF;
  padding:20px;
  margin-top: -20px;
  margin-bottom:20px;
}
</style>
