<template>
  <div fx v-if="route.path === '/user/tags'" class="page-tags">
    <div class="left">
      <div class="card">
        <div>数据标签<el-button type="text" class="add" @click="tag.dialogVisible = true"><i>&#xe708;</i>添加</el-button></div>
        <div class="card-content">
          <el-input
              v-model="tagsList.input.name"
              placeholder="搜索标签"
          />
          <el-tree style="margin-top:10px" node-key="id" v-if="tagsList.output.rows.length"
                   :default-expanded-keys="['co']" @node-click="tagsList.setActive"
              ref="treeRef" :highlight-current="true"
              :data="tagsList.output.rows"
              :props="{
                value: 'id',
                label: 'name',
                children: 'children',
              }"
              :filter-method="(q, n) => !tagsList.input.name || n.label.includes(tagsList.input.name)"
          />
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top" v-if="tagsList.activeOne">
        <el-descriptions :title="tagsList.activeOne.name" column="3">
          <el-descriptions-item label="类型">{{ tagsList.activeOne.type === '1' ? '公共标签' : '自定义标签' }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ tagsList.activeOne.createTime || tagsList.activeOne.updateTime || '无' }}</el-descriptions-item>
          <el-descriptions-item label="标签状态">{{ tagsList.activeOne.status === '1' ? '正常' : '失效' }}</el-descriptions-item>
        </el-descriptions>
        <div class="btn-group" v-if="tagsList.activeOne.type === '2'">
          <el-button type="text" @click="tag.input = tagsList.activeOne;tag.dialogVisible = true"><i>&#xe74d;</i>编辑</el-button>
          <el-button type="text" @click="tag.del(tagsList.activeOne)" style="color: #f56c6c"><i>&#xe744;</i>删除</el-button>
        </div>
      </div>
      <div v-if="tagsList.activeOne">
        <h3 style="font-weight:500">使用情况</h3>
        <canvas id="chart"></canvas>
      </div>
      <el-empty v-else description="请选择标签"></el-empty>
    </div>
    <el-dialog
        v-model="tag.dialogVisible"
        :title="tag.input.id ? '编辑标签' : '添加标签'" top="3vh"
        width="50%">
      <el-form :model="tag.input" ref="form" label-width="80px">
        <el-form-item label="标签名" prop="name" :rules="[{ required: true, message: '必填项' }]">
          <el-input v-model="tag.input.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="标签描述"  prop="describeInfo" :rules="[{ required: true, message: '必填项' }]">
          <el-input v-model="tag.input.describeInfo" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="标签状态"  prop="status">
          <el-radio v-model="tag.input.status" label="1" size="large">启用</el-radio>
          <el-radio v-model="tag.input.status" label="2" size="large">停用</el-radio>
        </el-form-item>
      </el-form>
      <template #footer>
        <div style="text-align: center">
          <el-button @click="tag.dialogVisible = false">关闭</el-button>
          <el-button type="primary" @click="tag.submit">提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <router-view v-else></router-view>
</template>
<style scoped lang="scss">
.page-tags{
  align-items: flex-start;
}
.card{
  position:relative;
}
.card-content{
  height:calc(100vh - 330px);
  overflow:auto;
}
.left{
  width:300px;
  margin-right:20px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);
}
.right{
  flex: 1;
  background-color:#FFF;
  min-height:400px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);
}
.add{
  right:20px;
  top:5px;
  position:absolute;
  i{
    font-size:14px;
  }
}
.top{
  padding:20px;
  border-bottom:1px solid #DDD;
  position:relative;
  + div{
    padding:20px;
  }
  .btn-group{
    position:absolute;
    right: 20px;
    top:10px;
    i{
      font-size:20px;
    }
  }
  :deep(*) {
    .el-descriptions__label{
      color:#999;
    }
    .el-descriptions__title{
      &:before{
        content: '\e6fb';
        font-family:i;
        color:#358AF2;
        margin-right:5px;
      }
    }
  }
}
</style>
<script src="./index.js"></script>
