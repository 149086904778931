<template>
  <main>
    <section>
      <div fx class="shadow">
        <p class=title>注册</p>
        <el-form :model="register.input" ref="form" label-width="120px" class="form" :rules="rules">
          <el-form-item label="用户名" prop="username" :rules="[{ required: true, message: '请输入用户名' }]">
            <el-input v-model="register.input.username" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="单位名称" prop="orgName" :rules="[{ required: true, message: '请输入单位名称' }]">
            <el-input v-model="register.input.orgName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input type="password" v-model="register.input.password" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="确认登录密码" prop="repassword" class="custom" :rules="[{
            required: true,
            validator: (r, v, c) => register.repassword === register.input.password ? c() : c('输入的密码不一致'),
            trigger: 'blur'}]">
            <el-input type="password" v-model="register.repassword" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item prop="agree" class="custom" :rules="[{ required: true, validator: (r, v, c) => register.agree ? c() : c('请同意遵守协议') }]" style="margin:10px 0 5px">
            <el-checkbox v-model="register.agree">我同意遵守《产学研管一体化服务协议》</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit">免费注册</el-button>
            <p class="haved">已有账号，去<router-link to="login">登录</router-link> </p>
          </el-form-item>
        </el-form>
      </div>
    </section>
  </main>
</template>
<script src="./index.js"></script>
<style scoped src="./index.scss" lang="scss"></style>
