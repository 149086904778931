<template>
  <div class="image-hidden" :style="{
    width: width || height,
    height: height || width
  }">
    <div class="image-box">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    src: { type: String },
    color: { type: String, default: '#333'},
    height: { type: String, default: '3vw'},
    width: {type: String}
  }
}
</script>
<style scoped>
.image-hidden{
  overflow:hidden;
  display:inline-block;
}
.image-box{
  display:inline-block;
  /*margin-top:-9.8vw; 不管用*/
  /*position:absolute;top:-9.8vw; 不管用*/
  /*transform:translateY(-9.8vw); !* 管一点用，首次渲染异常 *!*/
  transform:translate3d(0, -9.8vw, 0);
}
:deep(*) img{
  box-sizing: content-box;
  border-bottom:12vw solid transparent;
  filter: drop-shadow(0 10vw currentColor);
  margin:0!important;
}
</style>