import mixin from "@/common/mixin";
import ajax from "@/common/ajax";
import {ref, reactive, getCurrentInstance} from 'vue'
import {useRoute, useRouter} from "vue-router";
import enums from "../../../../../../common/model/enums";

export default {
  setup() {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()

    const user = reactive({
      output: vm.proxy.$root.userInfo,
      input: JSON.parse(JSON.stringify(vm.proxy.$root.userInfo))
    })

    return {user, route, router, enums}
  },
  methods: {
    upload (e) {
      let formData = new FormData()
      formData.append('file', e)
      formData.append('type', '1')
      ajax({url: '/website/file/uploadAvatar', data: formData, method: 'POST',
        success: (res) => {
          if (res.code === 200) {
            this.user.input.avatar = res.url
            this.user.input.organization.logo = res.url
          }
        }
      })
      return false
    },
    submit () {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        // 更新用户头像

        // 更新企业信息
        let res = await ajax({
          url: '/website/org/' + this.user.input.organization.organizationId,
          data: this.user.input, method: 'POST'})
        if (res.code === 200) {
          this.$message.success('操作成功')
          Object.assign(this.user.output, this.user.input)
          Object.assign(this.$root.userInfo, this.user.input)
          localStorage.userInfo = JSON.stringify(this.$root.userInfo)
          this.router.push({path: '/user/info'})
        }
      })
    }
  }
}