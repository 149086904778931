import mixin from "@/common/mixin";
import {getCurrentInstance} from 'vue'
import {useRoute, useRouter} from "vue-router";
import enums from "../../../../../common/model/enums";
import {apis} from "@/utils/apis";

export default {
  mixins: [mixin],
  setup() {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()

    const user = vm.proxy.$root.userInfo

    const pList = apis.list({api: 'myProjectList', input: {
      pageSize: 10, 
      // orgnizationId: user.orgId
    }})
    const update = pList.update;
    pList.update = (input = {}) => {
      window.scroll(0,0)
      return update(input)
    }

    const list = apis.list({api: enums.itemType.getItem(user.type).name + 'List', input: {pageSize: 3}});

    (async function () {
      if (!user.type) await new Promise(res => setTimeout(res, 200))
      pList.update()
      list.update()
    })()

    return {enums, user, list, route, router, pList}
  }
}