import ajax from "@/common/ajax";
import { reactive, getCurrentInstance, watch} from 'vue'
import {useRoute, useRouter} from "vue-router";
import { ElMessage } from 'element-plus'
import enums from "../../../../common/model/enums";
import mixin from "@/common/mixin";
import {apis} from "@/utils/apis";

export default {
  props: ['id', 'apiType'],
  mixins: [mixin],
  setup(props) {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    const user = vm.proxy.$root.userInfo
    const detail = apis.detail({
      api: props.apiType + 'Detail',
      input: {id: props.id}
    })

    const form = apis.form({
      api: props.apiType + 'Form',
      fileType: ['.rar', '.zip', '.doc', '.docx', '.pdf', '.jpg'],
      input: {
        labels: [], docPath: [],
        orgName: user.orgName
      },
      rules: {
        tel: [{required: true, message: '必填项'},{ pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: "请输入正确的手机号码", trigger: "blur" }],
        email:[{required: true, message: '必填项'},{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: "请输入正确的邮箱", trigger: "blur" }],
      },
    });
    (async function () {
      if (props.id) {
        await detail.update()
        form.input = detail.output
      }
    })()

    return {form, enums, route, router, user, props}
  },
  methods: {
    async submit (formRef) {
      if (this.form.input.status === '2') {
        this.form.input.status = '1' // 说明：status=2审核未通过，再次提交修改时设置status=1
      }
      this.form.input.brief = this.html_f30(this.form.input.describeInfo)
      await this.form.submit(formRef)
      this.back()
    },
    back () {
      this.router.push({path: `/user/${this.isAchievement ? 'achievement' : this.enums.itemType.getItem(this.user.type).name}/list`})
    }
  }
}