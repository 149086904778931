<template>
  <el-select v-model="currentValue" ref="select" :style="{width: 40 + 14 * (currentValue || '100').length + 'px'}"
             @mouseenter="show" :popper-class="popperClass"
             @mouseleave="hide"
  >
    <slot></slot>
  </el-select>
</template>
<script>
export default {
  created () {
    this.popperClass = 'my-hover-select' + parseInt(10000 * Math.random())
  },
  mounted () {
    document.querySelector('.' + this.popperClass).addEventListener('mouseleave', this.hide)
    document.querySelector('.' + this.popperClass).addEventListener('mouseenter', this.show)
  },
  beforeDestroy () {
    document.querySelector('.' + this.popperClass).removeEventListener('mouseleave', this.hide)
    document.querySelector('.' + this.popperClass).removeEventListener('mouseenter', this.show)
  },
  methods: {
    show () {
      clearTimeout(this.t)
      if (this.$refs.select.visible) return
      this.$refs.select.visible = true
    },
    hide () {
      this.t = setTimeout(() => {
        if (!this.$refs.select.visible) return
        this.$refs.select.visible = false
      }, 100)
    }
  },
  props: ['value'],
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  }
}
</script>