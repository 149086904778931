<template>
  <main v-loading="detail.loading">
    <my-org-top :orgId="detail.output.orgId" v-if="detail.output.orgId" @getType="getType"></my-org-top>
    {{searchCon }}
    <section style="margin-bottom:20px">
      <slot name="top" :detail="detail"></slot>
    </section>
    <section>
      <div fx style="align-items: flex-start">
        <slot name="left" :detail="detail"></slot>
        <div class="right">
          <my-list :list="[detail.output]" mode="large" :key="canPair" stopToDetail>
            <template #right="{i}" v-if="canPair">
              <el-button class="pair-btn"
                type="primary" size="small"
                @click="router.push({path: 'pair', query: {id: route.query.id}})" v-need-vip>我要对接</el-button>
            </template>
          </my-list>
          <div class="title-tag"><span>详细信息</span></div>
          <div class="detail ql-container ql-snow"><div class="ql-editor" v-html="detail.output.describeInfo"></div></div>
          <div class="title-tag" v-if="detail.output.docPath && detail.output.docPath.length"><span>相关文档</span></div>
          <div class="detail" v-if="detail.output.docPath && detail.output.docPath.length">
            <ul class="docs">
              <li v-for="i in detail.output.params.files">
                <a download :href="i.url" target="_blank">
                  <div class="doc-icon">
                    <i>&#xe6fd;</i>
                  </div>
                  <div class="doc-name">
                    <p>{{ i.fileName }}</p>
                    <span>{{ i.createTime }}</span>
                  </div>
                  <div class="doc-download">
                    <i>&#xe70b;</i>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="title-tag"><span>单位信息</span></div>
          <div class="detail">
            <el-descriptions :column="1">
              <el-descriptions-item label="名称">{{ detail.output.orgName }}<el-button @click="$router.push({path: '/org/detail', query: {id: detail.output.orgId}})" size="small" type="primary" plain style="margin-left:10px"><i>&#xe71f;</i>企业主页</el-button></el-descriptions-item>
              <el-descriptions-item label="合作方式">{{ detail.output.cpMode?.join('、') }}</el-descriptions-item>
              <el-descriptions-item label="所属地区">{{ detail.output.areaName || '无' }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="联系信息" :column="1" style="margin-top:20px">
              <el-descriptions-item label="联系人">{{ detail.output.techDirector }}</el-descriptions-item>
              <el-descriptions-item label="联系电话">{{ detail.output.tel }}</el-descriptions-item>
              <el-descriptions-item label="电子邮箱">{{ detail.output.email }}</el-descriptions-item>
            </el-descriptions>
            <div v-if="user.vipStatus==1">
              <div class="pop-up">
                <h4>当前功能为高级会员专享，请开启高级会员后再试{{searchCon}}</h4>
                <p>您当前为普通会员用户成为高级会员，即可马上开启以下特权：</p>
                <div><img src="/vip.png" /></div>
                <el-button type="primary" @click="$router.push({path: '/user/info/update'})">升级会员</el-button>
              </div>
              <div class="mask"></div>
            </div>
          </div>
        </div>
        <slot name="right" :detail="detail"></slot>
      </div>
    </section>
  </main>
</template>
<style src="./index.scss" scoped lang="scss"></style>
<script src="./index.js"></script>