<template>
  <my-ps-detail api="serviceDetail" :id="$route.query.id">
    <template #top="{detail}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/service/list' }">创新服务</el-breadcrumb-item>
        <el-breadcrumb-item>{{ detail.output.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <template #left="{detail}">
      <my-org-left :orgId="detail.output.orgId" v-if="detail.output.orgId"></my-org-left>
    </template>
  </my-ps-detail>
</template>
