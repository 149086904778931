import mixin from "@/common/mixin";
import {getCurrentInstance, reactive, watch} from "vue";
import ajax from "@/common/ajax";
import {useRouter, useRoute} from "vue-router";
import {apis} from "@/utils/apis";

export default {
  mixins: [mixin],
  setup () {
    const vm = getCurrentInstance()
    const router = useRouter()
    const route = useRoute()

    const org = apis.list({
      api: 'orgList',
      input: {
        pageSize: 15,
        status: 2,
        attributeType: route.query.attributeType
      }
    })
    org.update(org.input, () => {
      window.scroll(0,0)
    })

    return {org, router}
  }
}