import mixin from "@/common/mixin";
import ajax from "@/common/ajax";
import {ref, reactive, getCurrentInstance} from 'vue'
import {useRoute, useRouter} from "vue-router";
import {ElMessage, ElMessageBox} from "element-plus";
import enums from "../../../../../../common/model/enums";

export default {
  setup() {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()

    const user = reactive({
      output: vm.proxy.$root.userInfo,
      input: {}
    })

    const message = reactive({
      output: {total: 0, rows: []},
      input: {
        pageNum: 1, pageSize: 15
      },
      async update (input = {}) {
        Object.assign(message.input, input)
        window.scroll(0,0)
        let res = await ajax({url: '/website/message/myMessagelist', data: message.input})
        if (res.code !== 200) return
        message.output = res
        vm.proxy.$root.message.update()
      },
      async read (i) {
        i.status = '2'
        let res = await ajax({url: '/website/message', data: i, method: 'PUT'})
        if (res.code === 200) {
          vm.proxy.$root.message.output.total--
        } else {
          ElMessage.error(res.msg)
          i.status = '1'
        }
      },
      async del (i) {
        await new Promise(res => {
          ElMessageBox.confirm('您确定要删除这条记录吗？', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback (action) {
              if (action === 'confirm') res()
            }
          })
        })
        let res = await ajax({url: '/website/message/' + i.messageId, method: 'DELETE'})
        if (res.code === 200) {
          ElMessage.success('操作成功')
          message.update()
          if (i.status == '1') {
            vm.proxy.$root.message.output.total--
          }
        } else {
          ElMessage.error(res.msg)
        }
      }
    })
    message.update()

    return {user, message, route, router, enums}
  },
  methods: {
    clickTab (i) {
      if (i === '全部') {
        this.message.update({status: undefined, pageNum: 1})
      }
      if (i === '未读') {
        this.message.update({status: '1', pageNum: 1})
      }
    },
    toDetail (i) {
      if (i.status == '1') {
        this.message.read(i)
      }
      if (i.type === '0') {
        this.$router.push({path: '/user/message/detail', query: {id: i.messageId}})
      }
      else {
        this.$router.push({path: `/user/${this.enums.itemType.getItem(i.projectType).name}/pair`, query: {id: i.projectId}})
      }
    }
  }
}