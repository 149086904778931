<template>
  <div fx class="condition">
    <div fx>
      <div>需求状态：<el-select
          v-model="achievement.input.status" placeholder="请选择" style="width:100px" @change="achievement.update({pageNum: 1})">
        <el-option
            v-for="i in [{v: undefined, n: '全部'}, {v: '1', n: '上架'}, {v: '0', n: '下架'}]"
            :key="i.v"
            :label="i.n"
            :value="i.v">
        </el-option>
      </el-select></div>
<!--      <div>发布日期：<el-date-picker-->
<!--          type="daterange"-->
<!--          range-separator="至"-->
<!--          start-placeholder="开始日期"-->
<!--          end-placeholder="结束日期">-->
<!--      </el-date-picker></div>-->
    </div>
  </div>
  <div>
    <my-list :list="achievement.output.rows" mode="large" stopToDetail>
      <template #right="{i}">
        <el-button style="color:#35B6F2" size="small" @click="router.push({path: '/user/achievement/detail', query: {id: i.achievementId}})">查看详情</el-button>
        <el-button style="color:#B07BD6" size="small" @click="router.push({path: '/user/achievement/add', query: {id: i.achievementId}})">编辑成果</el-button>
        <el-button size="small" v-if="i.status == '0'" @click="changeStatus(i.achievementId, '1')">上架</el-button>
        <el-button size="small" v-if="i.status == '1'" @click="changeStatus(i.achievementId, '0')">下架</el-button>
      </template>
    </my-list>
    <el-pagination v-if="achievement.output.total > achievement.output.rows.length"
                   :page-size="achievement.input.pageSize"
                   :page="achievement.input.pageNum"
                   @current-change="p => achievement.update({pageNum: p})"
                   background
                   layout="prev, pager, next"
                   :total="achievement.output.total">
    </el-pagination>
  </div>
</template>
<script src="./index.js"></script>
<style src="./index.scss" scoped lang="scss"></style>