import ajax from "./ajax";
import {reactive} from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'

export const needNormal = async function (vm, opt = {goBack: true}) {
  ElMessageBox.confirm('当前用户权限受限，如有疑问，请联系系统管理员。<br><img width="90px" style="padding: 30px;" src="/lock.jpg">', '用户权限受限，无法使用此功能。', {
    showConfirmButton: false,
    cancelButtonText: '关闭',
    customClass: 'big-messagebox warning',
    center: true,
    showClose: false,
    dangerouslyUseHTMLString: true,
    type: 'warning',
    callback (action) {
      opt.goBack && vm.$router.go(-1)
    }
  })
}

export const needVip = async function (vm, opt = {goBack: true}) {
  ElMessageBox.confirm('您当前为普通会员用户成为高级会员，即可马上开启以下特权：<br><img width="420px" style="padding: 30px;" src="/vip.png">', '当前功能为高级会员专享，请开启高级会员后再试。', {
    confirmButtonText: '升级会员',
    cancelButtonText: '取消',
    customClass: 'big-messagebox',
    center: true,
    showClose: false,
    dangerouslyUseHTMLString: true,
    type: 'warning',
    callback (action) {
      if (action === 'confirm') {
        vm.$router.push({path: '/user/info/update'})
      } else {
        opt.goBack && vm.$router.go(-1)
      }
    }
  })
}

export const getUserInfo = async function (callback) {
  let res = await ajax({url: '/website/getCurrentLoginUser'})
  if (!res || res.code !== 200) return
  window.localStorage.userInfo = JSON.stringify(res.data)
  userInfo.clear()
  Object.assign(userInfo, res.data)
  callback && callback()
}

export const userInfo = reactive(localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {})

Object.defineProperty(userInfo, 'clear', {
  value: function () {
    setUndefined(this)
  },
  enumerable: false
})
function setUndefined (obj) {
  Object.keys(obj).forEach(key => {
    let item = obj[key]
    if (typeof item != 'object' || !item) {
      obj[key] = undefined
    } else {
      setUndefined(obj[key])
    }
  })
}