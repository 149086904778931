<template>
  <div v-if="route.path === '/user/info'">
    <my-org-detail :detail="$root.userInfo.organization">
      <el-button class="edit-button" size="small" @click="router.push({path: '/user/info/edit'})">编辑</el-button>
    </my-org-detail>
  </div>
  <router-view v-else></router-view>
</template>
<script>
import {getCurrentInstance, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";

export default {
  setup() {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()

    return {route, router}
  },
}
</script>
<style scoped>
.edit-button{
  position:absolute;
  top:20px;
  right:20px;
  line-height:30px;
  height:30px;
}
</style>