import mixin from "@/common/mixin";
import {getCurrentInstance, reactive, watch} from "vue";
import ajax from "@/common/ajax";
import {useRouter} from "vue-router";

export default {
  mixins: [mixin],
  setup () {
    const vm = getCurrentInstance()
    const router = useRouter()
    const pairInfoList = reactive({
      output: {
        total: 0,
        rows: []
      },
      input: {
        guidId: '', cpMode: '',
        pageNum: 1, pageSize: 15
      },
      get guidId () {
        return pairInfoList.input.guidId
      },
      set guidId (val) {
        pairInfoList.input.guidId = val.replace(/^,/, '')
      },
      async update (input) {
        Object.assign(pairInfoList.input, input)
        let res = await ajax({url: '/website/common/pairInfoList', data: pairInfoList.input})
        if (res.code !== 200) return
        pairInfoList.output = res
      }
    })
    pairInfoList.update()

    const pairInfo = reactive({
      output: {total: 0, rows: []},
      input: {
        pageNum: 1, pageSize: 4
      },
      async update () {
        let res = await ajax({url: '/website/common/pairInfoList', data: pairInfo.input})
        if (res.code !== 200) return
        pairInfo.output = res
      }
    })
    pairInfo.update()

    return {pairInfoList, pairInfo, router}
  }
}