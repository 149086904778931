<template>
  <main>
    <section style="height:30px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item v-if="notice.input.type == 1">通知公告</el-breadcrumb-item>
        <el-breadcrumb-item v-if="notice.input.type == 2">政策法规</el-breadcrumb-item>
      </el-breadcrumb>
    </section>
    <section>
      <div class="left">
        <div :class="{active: notice.input.type == 1}" @click="notice.update({type: 1, pageNum: 1})">通知公告</div>
        <div :class="{active: notice.input.type == 2}" @click="notice.update({type: 2, pageNum: 1})">政策法规</div>
      </div>
      <div class="right" v-loading="notice.loading">
        <ul class="list" v-if="notice.output.rows.length">
          <li v-for="(i, index) in notice.output.rows">
            <router-link class="title" :to="'/notice/detail?id=' + i.noticeId">{{ i.title }}</router-link>
            <div>{{ (i.createTime || '').replace(/\s.+$/, '') }}</div>
          </li>
        </ul>
        <el-empty v-else></el-empty>
        <el-pagination v-if="notice.output.total > notice.output.rows.length"
                       :page-size="notice.input.pageSize"
                       :page="notice.input.pageNum"
                       background @current-change="p => notice.update({pageNum: p})"
                       layout="prev, pager, next"
                       :total="notice.output.total">
        </el-pagination>
      </div>
    </section>
  </main>
</template>
<style src="../../requirement/list/index.scss" lang="scss" scoped></style>
<style src="./index.scss" lang="scss" scoped></style>
<script src="./index.js"></script>
<style scoped>
.condition{
  background-color:#FFF;
  padding:20px;
  margin-top: -40px;
  margin-bottom:20px;
}
</style>