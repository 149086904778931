<template>
  <main>
    <section>
      <div fx class="shadow">
        <p class=title>身份选择</p>
        <div fx>
          <router-link fx to="registered?type=1" :class="{active: active === 1}" @mouseenter="active = 1">
            <img src="./images/top.png" v-if="active === 1" class="top">
            <img src="./images/edit.png" v-if="active === 1">
            <img src="./images/edit-gray.png" v-else>
            <h4>需求方</h4>
            <p>可在创新资源或企业合作，实现需求</p>
          </router-link>
          <router-link fx to="registered?type=2" :class="{active: active === 2}" @mouseenter="active = 2">
            <img src="./images/top.png" v-if="active === 2" class="top">
            <img src="./images/shop.png" v-if="active === 2">
            <img src="./images/shop-gray.png" v-else>
            <h4>资源方</h4>
            <p>让创新资源，价值实现最大化</p>
          </router-link>
          <router-link fx to="registered?type=3" :class="{active: active === 3}" @mouseenter="active = 3">
            <img src="./images/top.png" v-if="active === 3" class="top">
            <img src="./images/heart.png" v-if="active === 3">
            <img src="./images/heart-gray.png" v-else>
            <h4>服务方</h4>
            <p>专业机构，提供更专业的服务</p>
          </router-link>
        </div>
      </div>
    </section>
  </main>
</template>
<script src="./index.js"></script>
<style src="./index.scss" scoped lang="scss"></style>