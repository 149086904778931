<template>
  <div>
    <div class="message">
      <el-tabs type="border-card" @tab-change="clickTab" :model-value="'全部'">
        <el-tab-pane :label="j" :name="j" v-for="j in ['全部', '未读']">
          <ul v-if="message.output.total">
            <li v-for="i in message.output.rows">
              <el-badge :is-dot="i.status == '1'">
                <div style="font-size:28px;background-color:rgb(175,122,212)" v-if="i.type == 0"><i>&#xe706;</i></div>
                <div style="font-size:28px" v-else-if="i.type == 1"><i>&#xe6fa;</i></div>
                <div style="font-size:28px;background-color:rgb(53,180,241)" v-else><i>&#xe707;</i></div>
              </el-badge>
              <div @click="toDetail(i)" style="cursor: pointer">
                <div>
                  <span v-if="i.type == 0">系统消息</span>
                  <span v-if="i.type == 1">需求推送</span>
                  <span v-if="i.type == 2">资源推送</span>
                  <span v-if="i.type == 3">服务推送</span>
                  <span>{{ i.createTime }}</span></div>
                <p>{{ i.title }}</p>
              </div>
              <div>
                <el-button type="text" style="margin-right:20px" @click="toDetail(i)">查看</el-button>
                <el-dropdown>
                  <el-button type="text">更多<i style="font-size: 12px;
              margin-left: 5px;
              transform: scale(0.7);
              display: inline-block;">&#xe6f1;</i></el-button>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="message.read(i)" v-if="i.status == '1'">设为已读</el-dropdown-item>
                      <el-dropdown-item @click="message.del(i)">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </li>
          </ul>
          <el-empty description="暂无数据" v-else/>
          <el-pagination v-if="message.output.total > message.output.rows.length"
                         :page-size="message.input.pageSize"
                         :page="message.input.pageNum"
                         background @current-change="p => message.update({pageNum: p})"
                         layout="prev, pager, next"
                         :total="message.output.total"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style src="./index.scss" scoped lang="scss"></style>