import ajax from "@/common/ajax";
import {ref, reactive, getCurrentInstance} from 'vue'
import {useRoute, useRouter} from "vue-router";

export default {
  setup() {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()

    const form = reactive({
      input: {}
    })
    return {route, router, form}
  },
  methods: {
    submit () {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return
        let res = await ajax({
          url: '/website/updatePassword',
          data: this.form.input, method: 'POST'})
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.router.push({path: '/user/info'})
        }
      })
    }
  }
}