<template>
  <main>
    <section>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/my-project/list' }">我参与的项目</el-breadcrumb-item>
      </el-breadcrumb>
      <div fx>
        <div class="left">
          <div class="user" style="height:200px">
            <div fx><img :src="user.organization.logo || '/banner.jpg'" alt="" @load="resizeImage"></div>
            <h5>{{ user.name }}</h5>
            <p>{{ user.orgName }}</p>
          </div>
          <div class="card">
            <div><i style="background-image: linear-gradient(to bottom, rgb(253,209,114), rgb(246,147,42))">&#xe6f5;</i>创新资源推荐</div>
            <my-list :list="list.output.rows" mode="mini"></my-list>
          </div>
        </div>
        <div class="right">
          <div class="search-bar" id="search-bar">
            <ul class="tabs">
              <li class="active">我参与的项目</li>
            </ul>
          </div>
          <div fx class="condition">
            <div fx>
              <div>项目状态：<el-select @change="pList.update({pageNum: 1})"
                   placeholder="请选择" style="width:100px" v-model="pList.input.status">
                <el-option :value="undefined" label="全部"></el-option>
                <el-option
                    v-for="i in enums.pairStatus"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value">
                </el-option>
              </el-select></div>
            </div>

            <div></div>
          </div>
          <my-list :list="pList.output.rows" mode="large" stopToDetail>
            <template #right="{i}">
              <el-button @click="router.push({path: 'detail', query: {id: i.pairInfoId, requirementId: i.requirementId, provideId: i.provideId}})" style="background: linear-gradient(270deg, #65BEFA 0%, #358AF2 100%);
box-shadow: 0px 0px 10px 0px rgba(53,138,242,0.3500);border:0;color:#fff">项目操作</el-button>
              <el-button v-if="i.status!=3" @click="router.push({path: 'edit', query: {id: i.pairInfoId, requirementId: i.requirementId, provideId: i.provideId}})" style="color:#0E87E2">编辑项目</el-button>
            </template>
          </my-list>
        </div>
      </div>
    </section>
  </main>
</template>
<script src="./index.js"></script>
<style scoped src="../../user/index.scss" lang="scss"></style>
<style scoped>
.condition{
  background-color:#FFF;
  padding:20px;
  margin-top: -20px;
  margin-bottom:20px;
}
</style>