import {ref, reactive} from 'vue'
import ajax from "@/common/ajax";
import { emitChangeFn } from 'element-plus';
export default {
  props: ['orgId', 'detail'],
  setup (props,{emit}) {
    const detail = reactive({
      output: {},
      async update () {
        ajax({url: '/website/org/' + props.orgId,
          success (res) {
            if (res.code !== 200) return
            detail.output = res.data
            num.update()
          }
        })
      }
    })
    const num = reactive({
      output: {},
      async update () {
        let res = await ajax({
          url: '/website/req/projectSuccessCount',
          data: {
            orgId: props.orgId,
            type: +detail.output.type
          }, method: 'POST'})
        if (res.code !== 200) return
        num.output = res.data
        emit('getType', res.data)
      }
    })

    // function handleClick () {
    //   emit('getType', num.output)
    // }
    if (props.detail) {
      detail.output = props.detail.output
      num.update()
    } else {
      detail.update()
    }
    return {
      dialogVisible: ref(false), detail, num,
      // handleClick
    }
  }
}