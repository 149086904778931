import enums from "../../../common/model/enums";

export default {
  data () {
    return {enums}
  },
  methods: {
    resizeImage: function (e) {
      var img = e.target
      var box = img.parentNode
      var width = parseInt(getComputedStyle(box, null).getPropertyValue("width"))
      var height = parseInt(getComputedStyle(box, null).getPropertyValue("height"))
      if (img.naturalWidth / img.naturalHeight < width / height) {
        img.style.width = '100%'
      } else {
        img.style.height = '100%'
      }
    },
    html_f30 (html) {
      let div = document.querySelector('#html_f30')
      if (!div) {
        div = document.createElement('div')
        div.id = 'html_f30'
        div.style.width = 0
        div.style.height = 0
        div.style.overflow = 'hidden'
        document.body.appendChild(div)
      }
      div.innerHTML = html || ''
      let innerText = div.innerText.replace(/[\r\n]/g, '')
      if (innerText.length > 30) {
        return innerText.slice(0, 30) + '...'
      }
      return innerText.slice(0, 30)
    },
    getPath (e) {
      let path = []
      if (e.path) {
        Array.prototype.forEach.call(e.path, i => path.push(i))
      } else {
        let target = e.target
        while (target !== document.body) {
          path.push(target)
          target = target.parentNode
        }
      }
      return path
    },
    getType (obj) {
      if (obj.requirementId) return '1'
      if (obj.provideId) return '2'
      if (obj.serviceId) return '3'
    },
    getSubmitPairType (o1, o2) {
      // 1 需求-资源
      // 2 需求-服务
      // 3 资源-服务
      return (parseInt(this.getType(o1)) + parseInt(this.getType(o2)) - 2).toString()
    }
  }
}