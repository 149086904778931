import {getCurrentInstance, reactive, ref} from "vue";
import ajax from "@/common/ajax";
import {useRoute, useRouter} from "vue-router";
import enums from "../../../../../common/model/enums";
import {apis} from "@/utils/apis";

export default {
  setup () {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()

    const detail = reactive({
      output: {},
      async update () {
        ajax({url: '/website/org/' + route.query.id,
          success (res) {
            if (res.code !== 200) return
            Object.assign(detail.output, res.data)
            detail.output.orgId = route.query.id
            list.api = enums.itemType.getItem(detail.output.type).name + 'List'
            list.update()
          }
        })
      }
    })
    detail.update()

    const list = apis.list({
      input: {
        industryId: undefined, orgId: route.query.id,
        pageNum: 1, pageSize: 6
      }
    })
    const update = list.update
    list.update = async (input = {}) => {
      window.scroll(0,0)
      await update(input)
    }

    const pList = reactive({
      output: {
        total: 0,
        rows: []
      },
      input: {
        pageNum: 1, pageSize: 10,
        orgId: route.query.id, status: '3'
      },
      async update (input = {}) {
        Object.assign(pList.input, input)
        window.scroll(0,0)
        let res = await ajax({url: '/website/common/pairInfoList', data: pList.input})
        if (res.code !== 200) return
        pList.output = res
      }
    })
    pList.update()

    return {list, detail, pList, enums,
      activeTab: ref(1), router, route
    }
  }
}