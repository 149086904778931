<template>
  <div fx class="upload-section">
    <el-upload v-if="isList" :disabled="disabled !== undefined || disabled === false"
        action="呵"
        :before-upload="upload.bind(this)"
        :file-list="currentValue"
        :multiple="true"
        :accept="(fileType || []).join(',')">
      <el-button size="small" type="primary">点击上传</el-button>
      <div slot="tip" class="el-upload__tip">支持扩展名：{{ (fileType || []).join(' ') }}</div>
    </el-upload>

    <el-upload v-else :disabled="disabled !== undefined || disabled === false"
        action="呵"
        :before-upload="upload.bind(this)"
        :show-file-list="false"
        :limit="1">
      <img v-if="currentValue" :src="currentValue" class="avatar">
      <div fx class="upload-icon" v-else>
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <span>上传图片</span>
      </div>
      <div slot="tip" class="el-upload__tip"><slot></slot></div>
    </el-upload>
  </div>
</template>
<script>
import {apis} from '@/utils/apis'
export default {
  props: {
    value: {},
    fileType: {
      default: ['.rar', '.zip', '.doc', '.docx', '.pdf', '.jpg'],
    },
    info: {
      default: {}
    },
    disabled: {}
  },
  computed: {
    isList () {
      return this.currentValue instanceof Array
    },
    currentValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('update:value', val)
        this.$emit('change', val)
      }
    }
  },
  methods: {
    upload (e) {
      let info = this.info || {}
      let formData = new FormData()
      formData.append('file', e)
      Object.keys(info).map(key => formData.append(key, info[key]))
      apis.upload({api: 'upload', input: formData, method: 'post', guid: Math.random()}).then(res => {
        if (res.code === 200) {
          res.url = res.url || res.data.url
          if (this.isList) {
            this.currentValue.push({
              fileId: res.fileId || res.data.fileId,
              url: res.url,
              name: res.name
            })
          } else {
            this.currentValue = res.url
          }
        }
      })
      return false
    },
  }
}
</script>
<style scoped lang="scss">
:deep(*) {
  .el-upload{
    display:block;
  }
  .el-upload-list{
    min-width: 300px;
  }
}
</style>