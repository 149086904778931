import mixin from "@/common/mixin";
import {getCurrentInstance, reactive, computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import {apis} from "@/utils/apis";
import {userInfo, getUserInfo} from "../../common/user";
export default {
  mixins: [mixin],
  props: ['id'],
  setup (props) {
    const vm = getCurrentInstance()
    const router = useRouter()
    const detail = apis.detail({api: 'requirementDetail', input: {id: props.id}})
    detail.update()

    const user = vm.proxy.$root.userInfo
    const canPair = computed(() => {
      if (!user.type) return false
      if (!detail.output.name) return false
      if (detail.output.status != 3) return false
      // 需求，只能让非需求方对接
      if (detail.output.requirementId && user.type === '1') return false
      // 资源，只能让非资源方对接
      if (detail.output.provideId && user.type === '2') return false
      // 服务，只能让非服务方对接
      if (detail.output.serviceId && user.type === '3') return false
      // 其他的都能自由对接
      return true
    })
    return {detail, canPair, router,user}
  }
}