<template>
  <my-r-detail :id="$route.query.id">
    <template #top="{detail}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/requirement/list' }">创新需求</el-breadcrumb-item>
        <el-breadcrumb-item>{{ detail.output.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <template #left="{detail}">
      <div class="left" style="margin-right:20px">
        <div class="card">
          <div><i style="background-image: linear-gradient(to bottom, rgb(106,221,124), rgb(60,174,75))">&#xe6f7;</i>用户须知</div>
          <div>
            <ul class="question">
              <li><router-link to="/QA/service-manage">平台用户服务管理规则 （试 行）</router-link></li>
              <li><router-link to="/QA/publish">平台用户信息发布规则 （试 行）</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </template>
  </my-r-detail>
</template>