<template>
  <my-submit-pair :id="$route.query.id" type="achievement">
    <template #top="{detail}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/achievement/list' }">创新服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/achievement/detail', query: {id: detail.output.achievementId}}">{{ detail.output.name }}</el-breadcrumb-item>
        <el-breadcrumb-item>我要对接</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
  </my-submit-pair>
</template>