import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { quillEditor, Quill } from 'vue3-quill' // 这里的 Quill 不能删掉
import {needVip, needNormal,userInfo} from "@/common/user";
export const app = createApp(App)
app.use(quillEditor)
app.use(ElementPlus, {
  locale: zhCn,
})
import '../../common/common.scss'
import '../../common/utils/index.js'

app.directive('need-vip', {
  created(el, vm, vnode) {
    el.addEventListener('click', e => {
      if (userInfo.vipStatus === '2') return // vip
      if (userInfo.vipStatus === '1') { // 普通用户
        needVip(vm.instance, {goBack: false})
      }
      if (userInfo.vipStatus === '3') { // 受限用户
        needNormal(vm.instance, {goBack: false})
      }
      el.removeEventListener('click', el._vei.onClick)
      setTimeout(() => el.addEventListener('click', el._vei.onClick))
    })
  }
})

/**
 * 路由
 */
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = []
const pages = []
function getPathInfo (filePath) {
  let _p1 = filePath.split('/')
  let _p2 = filePath.split('\\')
  let pathArr = (_p1.length > _p2.length ? _p1 : _p2).filter(i => i !== '.')
  pathArr.pop()
  return {
    pagePath: '/' + pathArr.join('/'),
    pageName: pathArr.pop(),
    parentPath: '/' + pathArr.join('/'),
    filePath
  }
}
// 查找界面
require.context('./pages', true, /index.vue$/).keys().forEach((filePath) => {
  pages.push(getPathInfo(filePath))
})
// 注册界面
pages.forEach(p => {
  // 顶级界面
  if (p.parentPath === '/') routes.push(setPage(p))
})
function setPage (p) {
  return {
    name: p.pagePath.replace(/^\//, ''),
    path: (p.parentPath === '/' ? '/' : '') + (p.pagePath === '/index' ? '' : p.pageName),
    component: () => import('./pages' + p.filePath.replace(/^\./, '')),
    children: pages.filter(i => i.parentPath === p.pagePath).map(p => setPage(p))
  }
}

// 自动注册全局组件
require.context('../../common/components', true, /index.vue$/).keys().forEach((filePath) => {
  const name = filePath.match(/([^\\/]+)[\\/]index\.vue$/)[1]
  app.component(
    name,
    defineAsyncComponent(() => import('../../common/components' + filePath.replace(/^\./, '')))
  )
})
// 自动注册全局组件
require.context('./components', true, /index.vue$/).keys().forEach((filePath) => {
  const name = filePath.match(/([^\\/]+)[\\/]index\.vue$/)[1]
  app.component(
    name,
    defineAsyncComponent(() => import('./components' + filePath.replace(/^\./, '')))
  )
})
export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})
app.use(router)
app.mount('#app')

// 时间格式转换
Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1,                 //月份
    "d+": this.getDate(),                    //日
    "h+": this.getHours(),                   //小时
    "m+": this.getMinutes(),                 //分
    "s+": this.getSeconds(),                 //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds()             //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}