import {getCurrentInstance} from "vue";
import mixin from "../../../website/src/common/mixin";
import enums from "../../model/enums";

export default {
  props: ['list', 'mode', 'type', 'stopToDetail'],
  mixins: [mixin],
  setup() {
    const vm = getCurrentInstance()
    return {enums, hasRightSlot: vm.proxy.$slots.right}
  },
  methods: {
    toDetail(i, event) {
      if (this.stopToDetail !== undefined) {
        this.$emit('row-click', i, event)
        return
      }
      if (i.serviceId) {
        this.$router.push({path: '/service/detail', query: {id: i.serviceId}})
      }
      if (i.provideId) {
        this.$router.push({path: '/provide/detail', query: {id: i.provideId}})
      }
      if (i.requirementId) {
        this.$router.push({path: '/requirement/detail', query: {id: i.requirementId}})
      }
      if (i.achievementId) {
        this.$router.push({path: '/achievement/detail', query: {id: i.achievementId}})
      }
      if (i.attributeType) {
        this.$router.push({path: '/org/detail', query: {id: i.organizationId}})
      }
    }
  }
}