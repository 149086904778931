import {reactive, getCurrentInstance, ref} from "vue";
import {apis} from "@/utils/apis";

const areaList = ref([])
const industryList = ref([])
const guidList = ref([])
const subGuidList = {}
const commonLabel = ref([])
const orgLabel = {}
const dictList = {}
const orgList = ref([])
export default reactive({
  // 用户级别
  vipStatus: [
    {value: '1', label: '普通会员'},
    {value: '2', label: '高级会员'},
    {value: '3', label: '受限用户'},
  ],
  // 企业状态
  orgStatus: [
    {value: '1', label: '审核中'},
    {value: '2', label: '已审核'},
    {value: '3', label: '已驳回'},
    {value: '4', label: '未申请'},
  ],
  // 未匹配的“项目”状态
  itemStatus: [
    {value: '1', label: '审核中'},
    {value: '2', label: '审核未通过'},
    {value: '3', label: '上架'},
    {value: '4', label: '下架'},
    {value: '5', label: '达成合作'},
  ],
  // 审核状态
  auditStatus: [
    {label: '审核未通过', value: '-1'},
    {label: '未审核', value: '0'},
    {label: '待审核', value: '1'},
    {label: '审核通过', value: '2'}
  ],
  // 已匹配的项目状态
  pairStatus: [
    // {label: '已作废', value: '-1'},
    {label: '已终止', value: '0'},
    {label: '未启动', value: '1'},
    {label: '已启动', value: '2'},
    {label: '已结项', value: '3'}
  ],
  // item类型
  itemType: [
    {label: '需求', value: '1', name: 'requirement'},
    {label: '资源', value: '2', name: 'provide'},
    {label: '服务', value: '3', name: 'service'},
  ],
  // 单位性质
  attributeType: [
    {label: '企业', value: '1'},
    {label: '高校', value: '2'},
    {label: '科研机构', value: '3'},
    {label: '服务机构', value: '4'},
  ],
  // 金额范围、资金规模
  amountRange: [
    '20万元以下',
    '20至50万元',
    '50至100万元',
    '100至200万元',
    '200至500万元',
    '500至1000万元',
    '1000万以上',
    '面议',
  ],
  // 成熟度
  maturity: [
    '在研',
    '小试',
    '中试',
    '已有样品',
    '可以量产',
  ],
  // 需求类型
  requirementType: [
    '技术难题解决',
    '新技术开发',
    '专利购买',
    '技术储备',
    '项目投资',
    '其他',
  ],
  // 服务类型
  serviceType: [
    '技术研究',
    '技术转移',
    '检验认证',
    '创业孵化',
    '知识产权',
    '科技咨询',
    '科技金融',
    '其他',
  ],
  // 合作方式
  cpMode: [
    '技术转让','技术入股'  ,'联合开发'  ,'委托研发','委托团队','专家长期技术服务','共建新研发','生产实体'
  ],
  // 所属区域
  get areaList () {
    if (!areaList.value.length) {
      apis.list({api: 'areaList'}).update().then(res => {
        if (!res || res.code !== 200) return
        areaList.value = res.data
      })
    }
    return areaList.value
  },
  // 配对类型
  pairType: [
    {value: '1', label: '主动报名'},
    {value: '2', label: '人工推荐'},
    {value: '3', label: '系统智能配对'},
  ],
  // 字典
  dictList (type) {
    if (dictList[type]) return dictList[type].value
    dictList[type] = ref([])
    apis.list({api: 'dictList', input: {type}}).update().then(res => {
      if (!res || res.code !== 200) return
      dictList[type].value = (res.data || []).map(i => Object.assign(i, {label: i.dictLabel, value: i.dictValue}))
    })
    return dictList[type].value
  },
  // 组织机构
  get orgList () {
    if (!orgList.value.length && !orgList.loading) {
      orgList.loading = true
      apis.list({api: 'orgList'}).update().then(res => {
        if (!res || res.code !== 200 || res.rows.length === 0) return
        orgList.value = res.data
        orgList.loading = false
      })
    }
    return orgList
  },
  // 行业领域
  get industryList () {
    if (!industryList.value.length && !industryList.loading) {
      industryList.loading = true
      apis.list({api: 'industryList'}).update().then(res => {
        if (!res || res.code !== 200 || res.rows.length === 0) return
        industryList.value = res.data
        industryList.loading = false
      })
    }
    return industryList
  },
  // 行业分类
  get guidList () {
    if (!guidList.value.length && !guidList.loading) {
      guidList.loading = true
      apis.list({api: 'guidList'}).update().then(res => {
        if (!res || res.code !== 200 || res.rows.length === 0) return
        guidList.value = res.data
        guidList.loading = false
      })
    }
    return guidList
  },
  // 行业二级分类
  subGuidList (guidId) {
    if (!guidId) return []
    if (subGuidList[guidId]) return subGuidList[guidId].value
    subGuidList[guidId] = ref([])
    apis.list({api: 'subGuidList', input: {guidId}}).update().then(res => {
      if (!res || res.code !== 200 || res.rows.length === 0) return
      subGuidList[guidId].value = res.data
    })
    return subGuidList[guidId].value
  },
  // 公用标签
  get commonLabel () {
    if (!commonLabel.value.length && !commonLabel.loading) {
      commonLabel.loading = true
      apis.list({api: 'labelList', input: {type: 1, pageSize: 999}}).update().then(res => {
        if (!res || res.code !== 200 || res.rows.length === 0) return
        commonLabel.value = (res.rows || []).filter(i => i.type == 1)
        commonLabel.loading = false
      })
    }
    return commonLabel
  },
  // 我的标签
  orgLabel(orgId) {
    if (orgLabel[orgId]) return orgLabel[orgId].value
    orgLabel[orgId] = ref([])
    apis.list({api: 'labelList', input: {type: '2', orgId, pageSize: 999}}).update().then(res => {
      if (!res || res.code !== 200 || res.rows.length === 0) return
      orgLabel[orgId].value = (res.rows || []).filter(i => i.type == 2)
    })
    return orgLabel[orgId].value
  }
})
