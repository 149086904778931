// 快捷获取
Object.defineProperty(Array.prototype, 'getItem', {
  value: function (fn) {
    if (typeof fn === 'function') {
      return this.find(fn) || {}
    } else {
      return this.find(i => i.value == fn) || this.find(i => i.label == fn) || {}
    }
  },
  enumerable: false
})
// 不重复地插入数组
Object.defineProperty(Array.prototype, 'add', {
  value: function (data) {
    let has = this.find(i => i === data)
    if (!has) {
      this.push(data)
    }
  },
  enumerable: false
})