<template>
  <main>
    <section>
      <div class="search-bar" id="search-bar">
        <ul class="tabs">
          <li class="active">全部</li>
        </ul>
        <el-input placeholder="请输入内容" v-model="achievement.input.name">
          <template #append>
            <i @click="achievement.update({pageNum: 1})">&#xe6f2;</i>
          </template>
        </el-input>
        <div fx class="condition">
          <div fx class="select">
            <my-order v-model:value="achievement.input.orderBy" name="create_time" label="发布时间" @change="achievement.update({pageNum: 1})"></my-order>
            <div>合作方式：<my-hover-select v-model:value="achievement.input.cpMode"
                                 @change="achievement.update({pageNum: 1})"
            >
              <el-option
                  v-for="item in [{n: '全部', v: ''}].concat(enums.cpMode.map(i => ({n:i, v: i})))"
                  :key="item.n"
                  :label="item.n"
                  :value="item.v">
              </el-option>
            </my-hover-select></div>
          </div>
        </div>
      </div>
      <my-list :list="achievement.output.rows" mode="small" style="margin-top:20px"></my-list>

      <el-pagination v-if="achievement.output.total > achievement.output.rows.length"
         :page-size="achievement.input.pageSize"
         :page="achievement.input.pageNum"
         background @current-change="p => achievement.update({pageNum: p})"
         layout="prev, pager, next"
         :total="achievement.output.total">
      </el-pagination>
    </section>
  </main>
</template>
<style src="./index.scss" scoped lang="scss"></style>
<script src="./index.js"></script>