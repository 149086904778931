import mixin from "@/common/mixin";
import {ref, reactive, getCurrentInstance, watch} from 'vue'
import {useRoute, useRouter} from "vue-router";

export default {
  mixins: [mixin],
  setup() {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    const search = reactive({
      visible: false,
      left: 0,
      type: '1',
      input: {
        name: undefined
      },
      showSearch (e) {
        search.left = e.layerX + 20
        search.visible = true
        setTimeout(() => {
          document.querySelector('#search-box').querySelector('.el-input-group > input').focus()
        })
      },
      submit () {
        setTimeout(() => search.visible = false)
        router.push({path: '/search', query: {type: search.type, name: search.input.name}})
      }
    })
    watch(() => route.path, () => {
      search.input.name = /^.search/.test(route.path) ? route.query.name : undefined
    })

    document.body.addEventListener('click', function (e) {
      let box = document.querySelector('#search-box')
      if (search.visible && box && vm.proxy.getPath(e).indexOf(box) < 0) {
        search.visible = false
      }
    })

    return {user: vm.proxy.$root.userInfo, route, message: vm.proxy.$root.message, search, router}
  },
  methods: {
    logout () {
      this.$root.userInfo.clear()
      this.$root.message.output.total = 0
      localStorage.removeItem('Authorization')
      localStorage.removeItem('userInfo')
      this.$router.push({path: '/login'})
    }
  }
}