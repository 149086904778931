export default function (opt, project, user = {}) {
  return {
    pairList: {
      website: '/website/common/pairInfoList',
      console: ''
    },
    requirementList: {
      website: '/website/common/scRequirementList',
      console: '/scManager/requirement/list'
    },
    caseList: {
      website: '/website/common/caseList'
    },
    requirementDetail: {
      website: '/website/requirement/{id}',
      console: '/scManager/requirement/{id}'
    },
    requirementForm: {
      website: '/website/requirement',
      console: '/scManager/requirement'
    },
    provideList: {
      website: '/website/common/scProvideList',
      console: '/scManager/provide/list'
    },
    provideDetail: {
      website: '/website/provide/{id}',
      console: '/scManager/provide/{id}'
    },
    provideForm: {
      website: '/website/provide',
      console: '/scManager/provide'
    },
    serviceList: {
      website: '/website/common/scServiceList',
      console: '/scManager/service/list'
    },
    serviceDetail: {
      website: '/website/service/{id}',
      console: '/scManager/service/{id}'
    },
    serviceForm: {
      website: '/website/service',
      console: '/scManager/service'
    },
    achievementList: {
      website: '/website/common/scAchievementList',
      console: '/scManager/achievement/list'
    },
    achievementDetail: {
      website: '/website/achievement/{id}',
      console: '/scManager/achievement/{id}'
    },
    achievementForm: {
      website: '/website/achievement',
      console: '/scManager/achievement'
    },
    orgList: {
      website: '/website/common/orgList',
      console: '/scManager/common/organizationList'
    },
    noticeList: {
      website: '/website/common/noticeList',
      console: '/scManager/notice/list'
    },
    noticeDetail: {
      website: '/website/common/noticeInfo/{noticeId}',
      console: '/scManager/notice/{noticeId}'
    },
    noticeForm: {
      website: '/website/common/noticeInfo/{noticeId}',
      console: '/scManager/notice'
    },
    noticeDelete: {
      website: 'noUrl',
      console: '/scManager/notice/{ids}'
    },
    upload: {
      website: '/website/file/upload',
      console: '/scManager/file/upload'
    },
    areaList: {
      website: '/website/common/areaList',
      console: '/website/common/areaList'
    },
    industryList: {
      website: '/website/common/industryList',
      console: '/website/common/industryList'
    },
    guidList: {
      website: '/website/common/guidListByPid/0',
      console: '/website/common/guidListByPid/0'
    },
    subGuidList: {
      website: '/website/common/guidListByPid/{guidId}',
      console: '/website/common/guidListByPid/{guidId}'
    },
    labelList: {
      website: '/website/label/list',
      console: '/scManager/common/labels'
    },
    myProjectList: {
      website: '/website/pairInfo/list',
      console: 'noUrl'
    },
    myItemList: {
      website: {
        '1': '/website/requirement/myRequirementList',
        '2': '/website/provide/myProvidelist',
        '3': '/website/service/getMyServiceList',
      }[user.type],
      console: 'noUrl'
    },
    myPairList: {
      website: {
        '1': '/website/req/requirementPairList',
        '2': '/website/req/provideToRequirementList',
        '3': '/website/req/serviceToRequirementList',
      }[user.type],
      console: 'noUrl'
    },
    recommendList: {
      website: {
        '1': '/website/req/requirementPairList',
        '2': '/website/req/provideToRequirementList',
        '3': '/website/req/serviceToRequirementList',
      }[user.type],
      console: 'noUrl'
    },
    projectDetail: {
      website: '/website/pairInfo/{id}',
      console: '/website/pairInfo/{id}'
    },
    projectForm: {
      website: '/website/req/updateMyDetail',
      console: '/website/req/updateMyDetail',
    },
    projectStatusForm: {
      website: '/website/pairInfo/submitAudit',
      console: '/website/pairInfo/submitAudit',
    },
    dictList: {
      website: '',
      console: '/system/dict/data/type/{type}'
    }
  }[opt.api][project].replace(/{([^}]+)}/, ($0, $1) => opt.input[$1] || '')
}