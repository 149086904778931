<template>
  <my-ps-detail api="achievementDetail" :id="$route.query.id">
    <template #top="{detail}">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/achievement/list' }">创新成果</el-breadcrumb-item>
        <el-breadcrumb-item>{{ detail.output.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <template #left="{detail}">
      <my-org-left :orgId="detail.output.orgId" v-if="detail.output.orgId"></my-org-left>
    </template>
  </my-ps-detail>
</template>
<style scoped lang="scss">
:deep(*) {
  li > div:nth-of-type(1) span{
    display:none;
  }
  .list-time{
    display: none;
  }
  .large li{
    height: 200px;
  }
}
</style>