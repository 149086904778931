import mixin from "@/common/mixin";
import {getCurrentInstance, reactive} from "vue";
import ajax from "@/common/ajax";
import {useRoute, useRouter} from "vue-router";
import {ElMessage, ElMessageBox} from "element-plus";
import enums from "../../../../common/model/enums";
import {apis} from "@/utils/apis";

export default {
  mixins: [mixin],
  props: ['type', 'id'],
  setup (props) {
    const vm = getCurrentInstance()
    const router = useRouter()
    const route = useRoute()
    const user = vm.proxy.$root.userInfo
    const list = apis.list({
      api: 'myItemList',
      activeOne: undefined,
      input: {pageSize: 6, status: '3'},
      clickItem (i, e) {
        list.activeOne = i
        let li = mixin.methods.getPath(e).find(i => i.tagName === 'LI')
        let ali = li.parentNode.querySelector('li.active')
        li.className += ' active'
        if (!ali) return
        ali.className = ali.className.replace(/\s*active/, '')
      }
    })
    list.update()

    const detail = reactive({
      output: {},
      text: undefined,
      async update () {
        let res = await ajax({url: `/website/${props.type}/` + props.id})
        if (res.code !== 200) return
        detail.output = res.data
      }
    })
    detail.update()

    return {detail, router, route, list, user, enums}
  },
  methods: {
    async submit () {
      if (!this.list.activeOne || !this.detail.output) {
        ElMessageBox.alert(`您还没有选择要对接的${this.enums.itemType.getItem(this.user.type).label}!`, '温馨提示')
        return
      }
      let res = await ajax({
        url: '/website/pair', method: 'POST',
        data: { type: this.getSubmitPairType(this.list.activeOne, this.detail.output),
          sid: this.list.activeOne.requirementId || this.list.activeOne.provideId || this.list.activeOne.serviceId,
          sname: this.list.activeOne.name,
          fid: this.detail.output.requirementId || this.detail.output.provideId || this.detail.output.serviceId,
          fname: this.detail.output.name,
          reason: this.detail.text
        }
      })
      if (res.code === 200) {
        ElMessageBox.alert(`已提交对接请求，即将返回${this.enums.itemType.getItem(this.getType(this.detail.output)).label}详情页!`, '温馨提示', {
          callback: () => {
            this.$router.push({path: 'detail', query: {id: this.id}})
          }
        })
      } else {
        ElMessage.error(res.msg)
      }
    }
  }
}