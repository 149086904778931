import {getCurrentInstance} from 'vue'
import {useRoute, useRouter} from "vue-router";
import mixin from "@/common/mixin";

export default {
  mixins: [mixin],
  setup() {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    return {
      user: vm.proxy.$root.userInfo,
      toDetail () {
        router.push({path: '/my-project/edit', query: route.query})
      }
    }
  }
}