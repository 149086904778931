import {ref, reactive} from 'vue'
import ajax from "@/common/ajax";
export default {
  props: ['orgId', 'detail'],
  setup (props) {
    const detail = reactive({
      output: {},
      async update () {
        ajax({url: '/website/org/' + props.orgId,
          guid: Math.random(),
          success (res) {
            if (res.code !== 200) return
            detail.output = res.data
          }
        })
      }
    })
    if (props.detail) {
      detail.output = props.detail.output
    } else {
      detail.update()
    }

    const pairInfo = reactive({
      output: {total: 0, rows: []},
      input: {
        pageNum: 1, pageSize: 4,
        orgId: props.orgId || props.detail.output.orgId
      },
      async update () {
        let res = await ajax({url: '/website/common/pairInfoList', data: pairInfo.input})
        if (res.code !== 200) return
        pairInfo.output = res
      }
    })
    pairInfo.update()
    return {
      dialogVisible: ref(false), detail, pairInfo
    }
  }
}