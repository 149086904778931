import {getCurrentInstance, reactive, watch} from "vue";
import {useRoute,useRouter} from "vue-router";
export default {
  setup () {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router= useRouter()
    if (route.path.split('/').length === 3) {
      router.push({path: route.path + '/list'})
    }
    return {}
  }
}