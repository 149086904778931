<template>
  <a href="javascript:;" class="toTop" onclick="window.scroll(0,0)" v-if="showToTop"><i>&#xe70b;</i></a>
  <footer v-if="!['/identity', '/login', '/registered'].includes($route.path)">
    <section>
      <div fx>
        <router-link fx class="logo" to="/">
          <h4><i>&#xe722; </i> 产学研管一体化协同创新大平台</h4>
        </router-link>
        <p>电话：0771-2809056（08:30-12:00   15:00-18:00）</p>
        <p>邮箱：</p>
      </div>
      <div fx>
        <ul>
          <li>关于我们</li>
          <li><router-link fx to="javascript:;">社会创新资源服务</router-link></li>
          <li><router-link fx to="javascript:;">科技创新需求</router-link></li>
          <li><router-link fx to="javascript:;">创新资源</router-link></li>
        </ul>
        <ul>
          <li>友情链接</li>
          <li><a href="http://www.most.gov.cn" target="_blank" fx>科学技术部</a></li>
          <li><a href="http://gkg.kjt.gxzf.gov.cn" target="_blank" fx>广西科技管理信息平台</a></li>
          <li><a href="http://cxq.kjt.gxzf.gov.cn" target="_blank" fx>广西科技创新券服务平台</a></li>
        </ul>
      </div>
      <div fx>
        <div fx>
          <img src=../../assets/code.png>
          <span>公众号</span>
        </div>
        <div fx>
          <img src=../../assets/code.png>
          <span>公众号</span>
        </div>
      </div>
    </section>
    <!-- <p class="copyright"><span>网警备案号45010302001759 桂ICP备05000807号</span> <span>网站标识码：4500000052</span></p> -->
  </footer>
</template>
<script>
export default {
  data () {
    return {
      showToTop: false,
    }
  },
  mounted () {
    window.addEventListener('scroll', e => {
      if (window.scrollY > 50) {
        this.showToTop = true
      } else {
        this.showToTop = false
      }
    })
  }
}
</script>
<style scoped>
.toTop{
  transform:rotate(180deg);
  color:#666;
  background-color:#FFF;
  width:50px;
  height:50px;
  border-radius:50%;
  line-height:50px;
  text-align:center;
  position:fixed;
  bottom:100px;
  right:50px;
  box-shadow: 0px 0px 10px 0px rgba(74, 87, 112, 0.2);
  cursor: pointer;
  z-index:10;
}
footer{
  color: #9D9D9D;
  padding-top: 40px;
  overflow: hidden;
  flex-direction: column;
}
footer a{
  color:#9D9D9D;
}
footer::before{
  min-width: 1280px;
  content:'';
  position:absolute;
  background-color:#141B1E;
  width: 100vw;
  height:100%;
  left:50%;
  top:0;
  transform:translateX(-50%);
}
footer .logo{
  color:#FFF;
  height: 60px;
  width: 295px;
  margin-top: -65px;
}
footer .logo h4{
  font-weight:500
}
footer .logo img{
  height:25px;
  width:auto;
}
footer section > * {
  z-index:1;
}
footer section > div {
  width:33%;
}
footer section > div +  div{
  border-left: 1px solid #2D2F3A;
  padding-left: 72px;
  margin-left: 72px;
  align-items: baseline;
}
footer section > div:nth-of-type(1){flex-direction: column;align-items: baseline;}
footer section > div:nth-of-type(2){

}
footer section > div:nth-of-type(2) li:first-child{
  color:#FFF;
  margin-bottom:20px;
}
footer section > div:nth-of-type(2) li{
  margin-bottom:10px;
}
footer section > div:nth-of-type(3){width: 233px;}
footer section > div:nth-of-type(3) div {
  flex-direction:column;
  justify-content: center;
  font-size: 14px;
}
footer section > div:nth-of-type(3) img {
  width:70px;
  margin-bottom: 10px;
}
.copyright{
  text-align: center;
  padding-bottom: 30px;
  width: 100%;
  background-color: #141B1E;
  color: #9D9D9D;
  font-size: 12px;
  margin-top:80px;
  z-index:2;
}
</style>