<template>
  <main>
    <section>
      <div>
        <div class="card">
          <div><i style="background-image: linear-gradient(to bottom, rgb(67,165,249), rgb(55,140,242))">&#xe6fd;</i>资源目录</div>
          <div>
            <div class="tag-group">
              <div>成熟度</div>
              <ul>
                <li v-for="i in enums.maturity"
                    :class="{active: list.input.maturity.includes(i)}"
                    @click="list.input.maturity.add(i);list.update()"
                >{{i}}</li>
              </ul>
            </div>
            <div class="tag-group">
              <div>行业领域</div>
              <ul>
                <li
                    :class="{active: list.input.industryId.includes(j.industryId)}"
                    v-for="j in enums.industryList"
                    @click="list.input.industryId.add(j.industryId);list.update()">{{ j.name }}</li>
              </ul>
            </div>
            <div class="tag-group">
              <div>行业分类</div>
              <ul>
                <li
                    :class="{active: list.input.guidId.includes(j.guidId)}"
                    v-for="j in enums.guidList"
                    @click="list.input.guidId.add(j.guidId);list.update()">{{ j.name }}</li>
              </ul>
            </div>
            <div class="tag-group">
              <div>所属区域</div>
              <ul>
                <li
                    :class="{active: list.input.areaId.includes(j.areaId)}"
                    v-for="j in enums.areaList"
                    @click="list.input.areaId.add(j.areaId);list.update()">{{ j.areaName }}</li>
              </ul>
            </div>
            <div class="tag-group">
              <div>资金规模</div>
              <ul>
                <li
                    :class="{active: list.input.amountRange.includes(j)}"
                    v-for="j in enums.amountRange"
                    @click="list.input.amountRange.add(j);list.update()">{{ j }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div><i style="background-image: linear-gradient(to bottom, rgb(106,221,124), rgb(60,174,75))">&#xe6f7;</i>用户须知</div>
          <div>
            <ul class="question">
              <li><router-link to="/QA/service-manage">平台用户服务管理规则 （试 行）</router-link></li>
              <li><router-link to="/QA/publish">平台用户信息发布规则 （试 行）</router-link></li>
            </ul>
          </div>
        </div>
        <div class="card" v-if="pairInfo.output.total">
          <div><i style="background-image: linear-gradient(to bottom, rgb(253,209,114), rgb(246,147,42))">&#xe6f9;</i>最新交易</div>
          <div>
            <ul class="news">
              <li v-for="i in pairInfo.output.rows" style="cursor: default">
                <div>{{ i.name }}</div>
                <div fx>
                  <span>{{ i.rIndustryName }}</span>
                  <span>{{ i.createTime.replace(/\s.+$/, '') }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div class="search-bar" id="search-bar">
          <ul class="tabs">
            <li class="active">全部</li>
          </ul>
          <el-input placeholder="请输入内容" v-model="list.input.name" @keydown.enter="list.update({pageNum: 1})">
            <template #append>
              <i @click="list.update({pageNum: 1})">&#xe6f2;</i>
            </template>
          </el-input>
        </div>
        <div class="condition">
          <div fx class="select" style="color:#9D9D9D">
            <my-order v-model:value="list.input.orderBy" name="create_time" label="发布时间" @change="list.update({pageNum: 1})"></my-order>
            <div>合作方式：<my-hover-select v-model:value="list.input.cpMode"
                                       @change="list.update({pageNum: 1})"
            >
              <el-option
                  v-for="item in [{n: '全部', v: ''}].concat(enums.cpMode.map(i => ({n:i, v: i})))"
                  :key="item.n"
                  :label="item.n"
                  :value="item.v">
              </el-option>
            </my-hover-select></div>
          </div>

          <div style="width:100%" v-if="list.input.maturity.length">
            成熟度：
            <el-tag effect="plain"
                    closable style="margin-right:10px;margin-bottom:8px"
                    @close="list.input.maturity = list.input.maturity.filter(j => j !== i);list.update({pageNum: 1})"
                    v-for="i in list.input.maturity"
                    :disable-transitions="false">{{ i }}
            </el-tag>
          </div>
          <div style="width:100%" v-if="list.input.industryId.length">
            行业领域：
            <el-tag effect="plain"
                    closable style="margin-right:10px;margin-bottom:8px"
                    @close="list.input.industryId = list.input.industryId.filter(j => j !== i);list.update({pageNum: 1})"
                    v-for="i in list.input.industryId"
                    :disable-transitions="false">{{ enums.industryList.getItem(j => j.industryId == i).name }}
            </el-tag>
          </div>
          <div style="width:100%" v-if="list.input.guidId.length">
            行业分类：
            <el-tag effect="plain"
                    closable style="margin-right:10px;margin-bottom:8px"
                    @close="list.input.guidId = list.input.guidId.filter(j => j !== i);list.update({pageNum: 1})"
                    v-for="i in list.input.guidId"
                    :disable-transitions="false">{{ enums.guidList.getItem(j => j.guidId == i).name }}
            </el-tag>
          </div>
          <div style="width:100%" v-if="list.input.areaId.length">
            所属区域：
            <el-tag effect="plain"
                    closable style="margin-right:10px;margin-bottom:8px"
                    @close="list.input.areaId = list.input.areaId.filter(j => j !== i);list.update({pageNum: 1})"
                    v-for="i in list.input.areaId"
                    :disable-transitions="false">{{ enums.areaList.getItem(j => j.areaId == i).areaName }}
            </el-tag>
          </div>
          <div style="width:100%" v-if="list.input.amountRange.length">
            资金规模：
            <el-tag effect="plain"
                    closable style="margin-right:10px;margin-bottom:8px"
                    @close="list.input.amountRange = list.input.amountRange.filter(j => j !== i);list.update({pageNum: 1})"
                    v-for="i in list.input.amountRange"
                    :disable-transitions="false">{{ enums.amountRange.getItem(j => j == i) }}
            </el-tag>
          </div>
        </div>
        <my-list :list="list.output.rows" mode="large" style="margin-top:20px"></my-list>
        <el-pagination v-if="list.output.total > list.output.rows.length"
                       :page-size="list.input.pageSize"
                       :page="list.input.pageNum"
                       background @current-change="p => list.update({pageNum: p})"
                       layout="prev, pager, next"
                       :total="list.output.total">
        </el-pagination>
      </div>
    </section>
  </main>
</template>
<style src="../../requirement/list/index.scss" lang="scss" scoped></style>
<script src="./index.js"></script>
<style scoped>
.condition{
  background-color:#FFF;
  padding:20px;
  margin-top: -40px;
  margin-bottom:20px;
}
</style>