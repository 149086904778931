<template>
  <main>
    <section>
      <div fx class="shadow">
        <p class=title>登录</p>
        <div fx></div>
        <el-form :model="login.input" ref="form" label-width="0px" class="form">
          <p>用户名</p>
          <el-form-item prop="username" :rules="[{ required: true, message: '请输入用户名' }]">
            <el-input v-model="login.input.username" placeholder="请输入"></el-input>
          </el-form-item>
          <p>密码</p>
          <el-form-item prop="password" :rules="[{ required: true, message: '请输入密码' }]">
            <el-input type="password" show-password v-model="login.input.password" placeholder="请输入"></el-input>
          </el-form-item>
          <p>验证码</p>
          <el-form-item prop="code" class="custom" :rules="[{ required: true, message: '请输入验证码' }]">
            <div style="width:100%">
              <el-input v-model="login.input.code" placeholder="请输入" class="code-input"></el-input>
              <img v-if="captchaImage.output.img" @click="captchaImage.update" :src="'data:image/png;base64,' + captchaImage.output.img" alt="" class="code-img">
            </div>
          </el-form-item>
          <el-form-item prop="remember" class="custom">
            <el-checkbox style="width:100%" v-model="login.input.remember">记住密码</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="login.submit">登录</el-button>
            <div class="flexs">
              <p class="tip">还没有账号，去<router-link to="/identity">注册</router-link>一个</p>
              <div class="forget" @click="showPword">忘记密码？</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </section>
    <div class="forget-mask" v-if="showForget">
        <div class="forget-box">
          <span @click="close">X</span>
          <h2><img src="@/assets/tip.png" /> 如需重置密码，请联系平台运维管理方</h2>
          <div>
            <p>联系电话：0771-966118（热线）、8059089</p>
            <p>服务时间：工作日8:00-12:00、15:00-18:00</p>
          </div>
          <el-button type="primary" @click="close">确定</el-button>
        </div>
      </div>
  </main>
</template>
<script src="./index.js"></script>
<style scoped src="./index.scss" lang="scss"></style>