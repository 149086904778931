<template>
  <my-header></my-header>
  <router-view></router-view>
  <my-footer></my-footer>
</template>

<script>
import {reactive} from "vue";
import ajax from "@/common/ajax";
import {useRouter} from "vue-router";
import { ElMessageBox} from "element-plus";
import {userInfo, getUserInfo} from "@/common/user";
export default {
  name: 'App',
  setup () {
    const router= useRouter()

    const message = reactive({
      output: {total: 0, rows: []},
      input: {
        status: 1,
        pageNum: 1, pageSize: 1
      },
      async update (input = {}) {
        Object.assign(message.input, input)
        let res = await ajax({url: '/website/message/myMessagelist', data: message.input})
        if (!res) return
        if (res.code !== 200) return
        message.output = res
      }
    })
    localStorage.Authorization && message.update()

    router.beforeEach(async (to, from, next) => {
      if (!userInfo.name && /^\/user/.test(to.path)) {
        next({path: '/login'})
        return
      }
      let toType
      if (to.path.includes('/user/requirement')) {
        toType = '1'
      }
      if (to.path.includes('/user/provide')) {
        toType = '2'
      }
      if (to.path.includes('/user/service')) {
        toType = '3'
      }
      if (toType && toType !== userInfo.type) {
        await new Promise(res => {
          let typeEnum = {'1': '需求', '2': '资源', '3': '服务'}
          ElMessageBox.confirm(`您的[${typeEnum[userInfo.type]}方]账号无法访问该界面，是否前往注册[${typeEnum[toType]}方]账号`, '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback (action) {
              if (action === 'confirm') res()
            }
          })
        })
        next({path: '/registered', query: {type: toType}})
        return
      }
      next()
    })

    return {userInfo, message, getUserInfo}
  }
}
</script>
<style>
.form .el-form-item__label,.el-input__inner{
  height:40px;
  line-height:40px;
}
@media screen and (min-width: 1280px){
  html:not(.isMobile) *::-webkit-scrollbar {
    width:6px;
    background:#fff;
  }
  html:not(.isMobile) *::-webkit-scrollbar-thumb {
    background-color:#0E87E2!important;
  }
}
nav,header,footer,section{
  display:flex;
  justify-content:space-between;
  align-items:center;
  height:100%;
  box-sizing: border-box;
  position:relative;
}
section{
  width:1280px;
  margin:0 auto;
}
</style>