import mixin from "@/common/mixin";
import ajax from "@/common/ajax";
import {ref, reactive, getCurrentInstance} from 'vue'
import {useRoute, useRouter} from "vue-router";
import {apis} from "@/utils/apis";
import enums from "../../../../common/model/enums";

export default {
  mixins: [mixin],
  setup() {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    vm.proxy.$root.message.update()
    const user = reactive({
      output: vm.proxy.$root.userInfo,
      input: {}
    })

    const list = apis.list({
      api: enums.itemType.getItem(user.output.type).name + 'List',
      input: {pageSize: 3}
    });

    (async function () {
      if (!user.output.type) await new Promise(res => setTimeout(res, 200))
      list.update()
    })()

    return {user, message: vm.proxy.$root.message, list, route, router}
  }
}