import Items from "../model/Items";
import Url from "../model/Url";
import {reactive} from "vue";
import enums from "../model/enums";

export default class ItemsController {
  constructor(opt) {
    this.ajax = opt.ajax
    this.project = opt.project
    this.message = opt.message
    this.messageBox = opt.messageBox
    this.user = opt.user
  }
  list (opt = {}) {
    opt.input = opt.input || {}
    let inputItem = Object.assign(opt.input, {
      pageNum: opt.input.hasOwnProperty('pageNum') ? opt.input.pageNum : 1,
      pageSize: opt.input.hasOwnProperty('pageSize') ? opt.input.pageSize : 10,
    })
    const list = reactive(Object.assign(opt, {
      input: inputItem,
      loading: false,
      showSearch: true,
      ids: [],
      output: {total: 0, rows: []},
      update: async (input, callback) => {
        list.loading = true
        let data = Items.prototype.getRequestParams(Object.assign(list.input, input))
        list.params = !list.method || list.method?.toLowerCase?.() === 'get' ? data : undefined
        let res = await this.ajax(Object.assign({
          url: Url(opt, this.project, this.user),
        }, opt, {
          data: data,
          params: list.params
        }))
        if (!res || res.code !== 200) return
        res.rows = res.rows || res.data
        res.rows = res.rows.map(i => new Items(i))
        list.output = res
        callback && callback()
        list.loading = false
        return res
      }
    }))
    return list
  }
  itemList (opt = {}) {
    const list = this.list(opt)
    list.update = async (input, callback) => {
      list.loading = true
      let data = Object.assign(list.input, input)
      list.params = !list.method || list.method?.toLowerCase?.() === 'get' ? data : undefined
      opt.api = enums.itemType.getItem(data.itemType || '1').name + 'List'
      let res = await this.ajax(Object.assign({
        url: Url(opt, this.project, this.user),
      }, opt, {
        data: data,
        params: list.params
      }))
      if (!res || res.code !== 200) return
      res.rows = res.rows || res.data
      res.rows = res.rows.map(i => new Items(i))
      list.output = res
      callback && callback()
      list.loading = false
      return res
    }
    return list
  }
  detail (opt = {}) {
    const detail = reactive(Object.assign(opt, {
      input: opt.input || {},
      loading: false,
      output: {},
      method: opt.method || 'GET',
      update: async (input, callback) => {
        detail.loading = true
        Object.assign(detail.input, input)
        detail.params = !detail.method || detail.method?.toLowerCase?.() === 'get' ? detail.input : undefined
        let res = await this.ajax(Object.assign({
          url: Url(opt, this.project, this.user),
        }, opt, {
          data: detail.input,
          params: detail.params
        }))
        if (res.code !== 200) return
        Object.assign(detail.output, new Items(res.data))
        callback && callback()
        detail.loading = false
        return res
      }
    }))
    return detail
  }
  projectDetail (opt) {
    const detail = this.detail(Object.assign(opt, {
      api: 'projectDetail'
    }))
    const update = detail.update
    detail.update = async (input, callback) => {
      await update()
      detail.output.requirement = detail.output.params['需求方信息']
      detail.output.provide = detail.output.params['资源方信息']
      detail.output.service = detail.output.params['服务方信息']
      callback && callback()
    }
    return detail
  }
  form (opt = {}) {
    const form = reactive(Object.assign(opt, {
      dialogVisible: false,
      loading: false,
      input: opt.input,
      submit: async (formRef) => {
        if (formRef) {
          // 表单验证
          await new Promise(resolve => {
            formRef.validate(async valid => {
              if (!valid) {
                setTimeout(() => {
                  document.querySelector('.el-form-item__error').parentNode.querySelector('input').focus()
                }, 200)
                return
              }
              resolve()
            })
          })
        }
        // 表单提交
        let res = await this.ajax(Object.assign({
          url: Url(opt, this.project, this.user),
          method: opt.method || (form.input.id ? 'PUT' : 'POST')
        }, opt, {
          data: Items.prototype.getRequestParams(form.input)
        }))
        if (res.code === 200) {
          form.dialogVisible = false
          this.message.success(res.msg!=null?res.msg:"操作成功")
        } else {
          this.message.error(res.msg)
        }
        return res
      }
    }, opt))
    return form
  }
  upload (opt) {
    return this.ajax(Object.assign({
      url: Url(opt, this.project, this.user),
      data: opt.input
    }, opt))
  }
  del (opt) {
    return {
      submit: async (input, callback) => {
        await new Promise(res => {
          this.messageBox.confirm('您确定要删除吗？', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback (action) {
              if (action === 'confirm') res()
            }
          })
        })
        return this.ajax({
          method: 'delete',
          url: Url(Object.assign({}, opt, {input}), this.project, this.user),
          data: input
        }).then(res => {
          if (res.code !== 200) return
          callback && callback()
          this.message.success('操作成功')
        })
      }
    }
  }
}