export default class Items {
  constructor(i = {}) {
    Object.assign(this, i, {
      id: i.requirementId || i.serviceId || i.provideId || i.achievementId || i.pairInfoId || i.organizationId || i.orgId || i.noticeId, // 主键
      itemType:
        (i.requirementId && '1') ||
        (i.provideId && '2') ||
        (i.serviceId && '3') ||
        (i.achievementId && '4') ||
        (i.pairInfoId && '5') ||
        i.type, // item类型
      name: i.name || i.pName || i.orgName, // 标题
      industryName: i.industryName || i.rIndustryName, // 所属领域名
      labels: (i.labels && i.labels.split(',')) || [], // 标签
      cpMode: (i.cpMode && i.cpMode.split(',')) || [], // 合作方式
      docPath: (i.docPath || '').split(',').filter(i => i).map((id, index) => ({
        fileId: id,
        url: i.params?.files?.[index]?.url || i.fileList?.[index]?.url,
        name: i.params?.files?.[index]?.fileName || i.fileList?.[index]?.fileName,
        createTime: i.params?.files?.[index]?.createTime || i.fileList?.[index]?.createTime
      })), // 相关文档
      endDocPath: (i.endDocPath || '').split(',').filter(i => i).map((id, index) => ({
        fileId: id,
        url: i.params.endFiles?.[index]?.url,
        name: i.params.endFiles?.[index]?.fileName,
        createTime: i.params.endFiles?.[index]?.createTime
      })),
      contractDocPath: (i.contractDocPath || '').split(',')
        .filter(i => i).map((id, index) => ({
          fileId: id,
          url: i.params.contractFiles?.[index]?.url,
          name: i.params.contractFiles?.[index]?.fileName,
          createTime: i.params.contractFiles?.[index]?.createTime
        })), // 合同文件
      cover: i.cover || i.logo, // 封面
      orgId: i.orgId || i.rOrgId, // 所属组织ID
      orgName: i.orgName || i.rOrgName, // 所属组织名
    })
  }

  getRequestParams(input = {}) {
    let params = {}
    let format = {
      labels: arrToStr(input.labels),
      cpMode: arrToStr(input.cpMode),
      docPath: arrToStr(input.docPath && input.docPath.map(i => i.fileId)),
      endDocPath: arrToStr(input.endDocPath && input.endDocPath.map(i => i.fileId)),
      contractDocPath: arrToStr(input.contractDocPath && input.contractDocPath.map(i => i.fileId)),
      amountRange: arrToStr(input.amountRange)
    }
    Object.keys(input).forEach(key => {
      params[key] = format.hasOwnProperty(key) ? format[key] : input[key]
    })
    return params
  }
}
function arrToStr (val) {
  if (val instanceof Array) {
    return val.join(',')
  }
  return val
}