<template>
  <div class="form">
    <el-button class="edit-button" size="small" @click="router.push({path: '/user/info'})">取消</el-button>
    <div fx class="title">
      <div>修改密码</div>
    </div>
    <el-form :model="form.input" ref="formRef" label-width="150px">
      <el-form-item label="新的密码" prop="password" :rules="[{required: true, message: '请输入', trigger: 'input'},
      {
            validator: (rule, value, callback) => {
              const reg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}/;
              if (reg.test(value)) { callback();} else {
                callback(new Error());
              }
            },
            message: '密码必须由数字、字母、特殊字符组合,请输入8-16位',trigger: 'blur',
          }]">
        <el-input type="password" v-model="form.input.password" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="repassword" :rules="[{ required: true,
      validator: (r, v, c) => form.input.password == form.input.repassword ? c() : c('密码不一致')  }]">
        <el-input type="password" v-model="form.input.repassword" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script src="./index.js"></script>