<template>
  <main>
    <section>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item>用户中心</el-breadcrumb-item>
        <el-breadcrumb-item>消息详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div fx>
        <div class="left">
          <div class="card">
            <div><i style="background-image: linear-gradient(to bottom, rgb(106,221,124), rgb(60,174,75))">&#xe6f7;</i>用户须知</div>
            <div>
              <ul class="question">
                <li><router-link to="/QA/service-manage">平台用户服务管理规则 （试 行）</router-link></li>
                <li><router-link to="/QA/publish">平台用户信息发布规则 （试 行）</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">
            <h1>欢迎注册产学研管一体化协同创新大平台</h1>
            <p>系统推送<span>2022-03-21 08:21</span></p>
          </div>
          <div class="article">
            <p>尊敬的用户您好，</p>
            <p>欢迎加入产学研管一体化协同创新大平台。</p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<style scoped src="./index.scss" lang="scss"></style>