<template>
  <main v-loading="org.loading">
    <section>
      <div class="search-bar" id="search-bar">
        <ul class="tabs">
          <li :class="{active: !org.input.attributeType }" @click="org.update({pageNum: 1, attributeType: undefined})">全部</li>
          <li v-for="i in enums.attributeType" :key="i.value"
              :class="{active: org.input.attributeType == i.value }"
              @click="org.update({pageNum: 1, attributeType: i.value})">{{ i.label }}</li>
        </ul>
        <el-input placeholder="请输入内容" v-model="org.input.orgName" @keydown.enter="org.update({pageNum: 1})">
          <template #append>
            <i @click="org.update({pageNum: 1})">&#xe6f2;</i>
          </template>
        </el-input>
      </div>
      <my-list :list="org.output.rows" mode="small" style="margin-top:20px"></my-list>

      <el-pagination v-if="org.output.total > org.output.rows.length"
         :page-size="org.input.pageSize"
         :page="org.input.pageNum"
         background @current-change="p => org.update({pageNum: p})"
         layout="prev, pager, next"
         :total="org.output.total">
      </el-pagination>
    </section>
  </main>
</template>
<style src="./index.scss" scoped lang="scss"></style>
<script src="./index.js"></script>