<template>
  <header>
    <section fx>
      <div class="left">
        <router-link fx class="logo" to="/">
          <img src="../../assets/logo.png" alt="产学研管一体化协同创新大平台" title="产学研管一体化协同创新大平台">
          <h4>产学研管一体化协同创新大平台</h4>
        </router-link>
        <nav>
          <router-link fx to="/" :class="{active: /^.index/.test(route.path)}">首页</router-link>
          <router-link fx to="/requirement/list" :class="{active: /^.requirement/.test(route.path)}">创新需求</router-link>
          <router-link fx to="/provide/list" :class="{active: /^.provide/.test(route.path)}">创新资源</router-link>
          <router-link fx to="/service/list" :class="{active: /^.service/.test(route.path)}">创新服务</router-link>
          <router-link fx to="/achievement/list" :class="{active: /^.achievement/.test(route.path)}">创新成果</router-link>
          <router-link fx to="/org/list" :class="{active: /^.org/.test(route.path)}">产学研资源库</router-link>
          <router-link fx to="/case/list" :class="{active: /^.case/.test(route.path)}">成功案例</router-link>
        </nav>
      </div>
      <div fx class="right" v-if="['login', 'identity', 'registered'].includes(route.name)">
        <span v-if="route.name === 'identity'">已有账号？<router-link :to="{path: '/login'}" style="color:#358AF2">去登录</router-link></span>
        <span v-if="route.name === 'login'">没有账号？<router-link :to="{path: '/identity'}" style="color:#358AF2">去注册</router-link></span>
        <span v-if="route.name === 'registered'">已有账号？<router-link :to="{path: '/login'}" style="color:#358AF2">去登录</router-link></span>
      </div>
      <div fx class="right" v-else>
        <div class="search-box" :style="{left: search.left + 'px'}" id="search-box">
            <div>
              <el-input placeholder="请输入内容" v-model="search.input.name" @keydown.enter="search.submit" style="width:350px">
                <template #prepend>
                  <el-select placeholder="请选择" v-model="search.type" style="background-color:#fff">
                    <el-option label="找需求" value="1"></el-option>
                    <el-option label="找资源" value="2"></el-option>
                    <el-option label="找服务" value="3"></el-option>
                  </el-select>
                </template>
                <template #append>
                  <el-button style="margin-top:-9px" @click="search.submit"><i>&#xe6f2;</i></el-button>
                </template>
              </el-input>
              <div class="keyword">
                <ul fx>
                  <li @click="search.input.name = '科技重大项目'"><router-link :to="{path: '/search', query: {type: search.type, name: '科技重大项目'}}">科技重大项目</router-link></li>
                  <li @click="search.input.name = '重点研发'"><router-link :to="{path: '/search', query: {type: search.type, name: '重点研发'}}">重点研发</router-link></li>
                  <li @click="search.input.name = '科技基地'"><router-link :to="{path: '/search', query: {type: search.type, name: '科技基地'}}">科技基地</router-link></li>
                  <li @click="search.input.name = '自然科学基金'"><router-link :to="{path: '/search', query: {type: search.type, name: '自然科学基金'}}">自然科学基金</router-link></li>
                  <!-- <li @click="search.input.name = '重点研发'"><router-link :to="{path: '/search', query: {type: search.type, name: '重点研发'}}">重点研发</router-link></li> -->
                </ul>
              </div>
            </div>
          </div>
        <!-- <div id="search" @click.stop="!search.visible && search.showSearch($event)">
          <button class="i" title="搜索">&#xe6f2;</button>
          
        </div> -->
        <div id="user">
          <div fx class="login-btn" v-if="!user.userId">
            <router-link fx to="/login" >登录</router-link>
            <router-link fx to="/identity" class="button primary">注册</router-link>
          </div>
          <div fx v-if="user.userId">
            <div class="message"  @click="router.push({path: '/user/message/list'})">
              <el-badge :value="message.output.total" v-if="message.output.total">
                <i>&#xe6ea;</i>
              </el-badge>
              <i v-else>&#xe6ea;</i>
            </div>
            <el-dropdown v-if="user.userId" class="login-already">
              <span class="el-dropdown-link">
                {{ user.name || '匿名用户'}}<i style="font-size: 12px;
                margin-left: 5px;
                transform: scale(0.7);
                display: inline-block;">&#xe6f1;</i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item><router-link to="/user/info">用户中心</router-link></el-dropdown-item>
                  <el-dropdown-item><router-link to="/user/info/password">修改密码</router-link></el-dropdown-item>
                  <el-dropdown-item><router-link to="/my-project/list">参与的项目</router-link></el-dropdown-item>
                  <el-dropdown-item><router-link :to="'/org/detail?id=' + user.orgId">企业主页</router-link></el-dropdown-item>
                  <el-dropdown-item @click="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
    </section>
  </header>
</template>
<script src="./index.js"></script>
<style scoped lang="scss">
header{
  height: 130px;box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.0900);
  section{
    align-items: end;
    .logo{width: 320px;
      h4{color: #303036;}
    }
    nav{
      height: 68px;
      a{
        color: #4A5770;
        &.active{color: #358AF2;}
      }
    }
    .login-btn{
      border: 1px solid #358AF2;height: 36px;margin-left:30px;
      a{
        height: 36px;line-height: 36px;width: 60px;text-align: center;display: block;font-size: 14px;
        &.primary{color:#fff;background-color: #358AF2;}
      }
    }
    .login-already{height: 36px;line-height: 36px;}
  }
}

</style>