<template>
  <div style="position: relative">
<!--     v-loading="detail.loading"-->
    <el-dropdown class="status-btn" v-if="user.type !== '3' && (detail.output.status == 1 || detail.output.status == 2) && detail.output.auditStatus != 1">
      <el-button type="primary">
       项目操作<i class="down">&#xe6fc;</i>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-if="detail.output.status == 1" @click="toDetail">项目编辑</el-dropdown-item>
          <el-dropdown-item v-if="detail.output.status == 1" @click="detail.start">项目启动</el-dropdown-item>
          <el-dropdown-item v-if="detail.output.status == 2" @click="form.input.status = '3';timeoutShow(() => form.dialogVisibleEnd = true)">项目结项</el-dropdown-item>
          <el-dropdown-item v-if="detail.output.status == 2" @click="form.input.status = '0';timeoutShow(() => form.dialogVisibleEnd = true)">项目终止</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-button v-if="user.type === '3' && detail.output.status == 1" type="primary" class="status-btn" @click="toDetail">项目编辑</el-button>
    <div fx class="top">

      <div class="div-img" style="height:80px">
        <img :src="detail.output.cover" @load="resizeImage"/>
      </div>
      <div>
        <h1>{{ detail.output.name }}</h1>
        <div class="des">
          <span>行业分类：{{ detail.output.guidName || '无' }}</span>
          <span>需求类别：{{ detail.output.type || '无' }}</span>
          <span>交付周期：{{ detail.output.deliverDays }}天</span>
        </div>
        <div class="des">
          <span>行业领域：{{ detail.output.industryName }}</span>
          <span>资金规模：{{ detail.output.amountRange || '无' }}</span>
          <span v-if="detail.output.createTime">发布时间：{{ detail.output.createTime.replace(/\s.+$/, '') }}</span>
        </div>
        <div class="tag">
          标签：
          <div class="tags" v-if="detail.output.labels">
            <span v-for="j in detail.output.labels">{{ j }}</span>
          </div>
          <span v-else>无</span>
        </div>
        <div>
          <div class="linear" :style="{background:
                `linear-gradient(90deg, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0) 10%, rgba(255,255,255, 0) ${detail.barStatus / 4 * 100 - 5}%, rgba(255,255,255, 0.7) ${detail.barStatus / 4 * 100 + 5}%, rgba(255,255,255, 0.7) 90%, rgba(255,255,255, 1) 100%)`
                }"></div>
          <div class="line"></div>
          <i :style="{left: detail.barStatus / 4 * 100 + (detail.barStatus - 2) * 2 + '%'}">&#xe701;</i>
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <button style="z-index:2">已匹配</button>
          <button v-if="user.type === '3'">资源方启动</button>
          <button v-else>已启动</button>
          <button style="width:150px">项目结项/终止审核</button>
          <button>完成</button>
        </div>
      </div>
    </div>
    <div class="title-tag"><span>匹配情况</span></div>
    <div fx class="detail">

      <ul class="match">
        <li v-if="detail.output.requirement">
          <div class="icon" style="background-color: rgb(53,138,242)">
            <i>&#xe6f7;</i>
            <div>需求方</div>
          </div>
          <div class="content">
            <div class="title">{{ detail.output.requirement?.name }}</div>
            <div>{{ detail.output.requirement?.orgName }}</div>
          </div>
          <div class="link">
            <el-button type="text" @click="router.push({path: '/requirement/detail', query: {id: detail.output.requirement.requirementId}})">查看详情<i>&#xe6fc;</i></el-button>
          </div>
        </li>

        <li v-if="detail.output.provide">
          <div class="icon" style="background-color: rgb(53,182,242)">
            <i>&#xe70c;</i>
            <div>资源方</div>
          </div>
          <div class="content">
            <div class="title">{{ detail.output.provide?.name }}</div>
            <div>{{ detail.output.provide?.orgName }}</div>
          </div>
          <div class="link">
            <el-button type="text" @click="router.push({path: '/provide/detail', query: {id: detail.output.provide.provideId}})">查看详情<i>&#xe6fc;</i></el-button>
          </div>
        </li>

        <li v-if="detail.output.service">
          <div class="icon" style="background-color: rgb(53,182,242)">
            <i>&#xe70c;</i>
            <div>服务方</div>
          </div>
          <div class="content">
            <div class="title">{{ detail.output.service?.name }}</div>
            <div>{{ detail.output.service?.orgName }}</div>
          </div>
          <div class="link">
            <el-button type="text" @click="router.push({path: '/service/detail', query: {id: detail.output.service.serviceId}})">查看详情<i>&#xe6fc;</i></el-button>
          </div>
        </li>
      </ul>

    </div>

    <div class="title-tag" v-if="detail.output.describeInfo"><span>详细信息</span></div>
    <div class="detail ql-container ql-snow" v-if="detail.output.describeInfo"><div class="ql-editor" v-html="detail.output.describeInfo"></div></div>


    <div class="title-tag" v-if="detail.output.contractDocPath?.length"><span>合同文件</span></div>
    <div class="detail" v-if="detail.output.contractDocPath?.length">
      <ul class="docs">
        <li v-for="i in detail.output.contractDocPath">
          <a download :href="i.url" target="_blank">
            <div class="doc-icon">
              <i>&#xe6fd;</i>
            </div>
            <div class="doc-name">
              <p>{{ i.name }}</p>
              <span>{{ i.createTime }}</span>
            </div>
            <div class="doc-download">
              <i>&#xe70b;</i>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <div class="title-tag" v-if="detail.output.status != 1"><span>项目进度及阶段性成果</span></div>
    <div class="detail" v-if="detail.output.status != 1">
      <el-button size="mini" type="primary" class="add" @click="form.dialogVisible = true" v-if="detail.output.status == 1 || detail.output.status == 2">+添加进度报告</el-button>
      <ul class="docs" v-if="detail.output.docPath && detail.output.docPath.length">
        <li v-for="i in detail.output.docPath">
          <a download :href="i.url" target="_blank">
            <div class="doc-icon">
              <i>&#xe6fd;</i>
            </div>
            <div class="doc-name">
              <p>{{ i.name }}</p>
              <span>{{ i.createTime }}</span>
            </div>
            <div class="doc-download" @click.prevent="form.del(i.fileId)" v-if="(detail.output.status == 1 || detail.output.status == 2) && /my-project/.test(route.path)">
              <i style="font-size: 30px;color: red">&#xe744;</i>
            </div>
            <div class="doc-download">
              <i>&#xe70b;</i>
            </div>
          </a>
        </li>
      </ul>
      <el-empty v-else></el-empty>
      <el-dialog title="添加进度报告" v-model="form.dialogVisible" width="800px" append-to-body>
        <el-form ref="formRef" :model="form">
          <el-form-item prop="docPath" :rules="[{ required: true, validator: (r, v, c) => form.docPath.length ? c() : c('请上传相关文档') }]">
            <my-upload @change="timeoutShow(() => $refs['formRef'].validateField('docPath'))" v-model:value="form.docPath" :info="{type: '4', pairInfoId: form.input.pairInfoId}"></my-upload>
          </el-form-item>
          <el-form-item style="margin-top: 30px">
            <el-button type="primary" @click="form.submitDocPath($refs.formRef)">保存</el-button>
            <el-button @click="form.dialogVisible = false;form.docPath = []">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <div class="title-tag" v-if="detail.output.endDocPath?.length"><span>项目终结或终止文件材料</span></div>
    <div class="detail" v-if="detail.output.endDocPath?.length">
      <ul class="docs">
        <li v-for="i in detail.output.endDocPath">
          <a download :href="i.url" target="_blank">
            <div class="doc-icon">
              <i>&#xe6fd;</i>
            </div>
            <div class="doc-name">
              <p>{{ i.name }}</p>
              <span>{{ i.createTime }}</span>
            </div>
            <div class="doc-download">
              <i>&#xe70b;</i>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <el-dialog title="启动项目" v-model="detail.dialogVisible_start" width="800px" append-to-body>
      <el-descriptions :title="detail.output.name" :column="2">
        <el-descriptions-item label="项目周期">
          <template v-if="detail.output.deliverDays">{{detail.output.deliverDays}}天</template>
          <span class="not" v-else>未填写</span>
        </el-descriptions-item>
        <!-- 只有需求方、资源方才能启动项目 -->
        <!-- v-if 判断当前用户是不是需求方 -->

        <!-- 如果是需求方 -->
        <el-descriptions-item :label="detail.output.service ? `服务方` : `资源方`" v-if="user.type === '1'">
          <template v-if="detail.output.service">{{detail.output.service.orgName}}</template>
          <template v-if="detail.output.provide">{{detail.output.provide.orgName}}</template>
          <span class="not" v-if="!detail.output.service && !detail.output.provide">未填写</span>
        </el-descriptions-item>

        <!--如果是资源方-->
        <el-descriptions-item :label="detail.output.service ? `服务方` : `需求方`" v-if="user.type === '2'">
          <template v-if="detail.output.service">{{detail.output.service.orgName}}</template>
          <template v-if="detail.output.requirement">{{detail.output.requirement.orgName}}</template>
          <span class="not" v-if="!detail.output.service && !detail.output.requirement">未填写</span>
        </el-descriptions-item>

        <el-descriptions-item label="开始时间">
          <template v-if="detail.output.startTime">{{detail.output.startTime}}</template>
          <span class="not" v-else>未填写</span>
        </el-descriptions-item>
        <el-descriptions-item :label="enums.itemType.getItem(user.type).label + '方'">
          <template v-if="user.type === '1'">{{detail.output.requirement.orgName}}</template>
          <template v-if="user.type === '2'">{{detail.output.provide.orgName}}</template>
          <template v-if="user.type === '3'">{{detail.output.service.orgName}}</template>
        </el-descriptions-item>
        <el-descriptions-item label="合作方式">
          <template v-if="detail.output.cpMode">{{detail.output.cpMode.join('、')}}</template>
          <span class="not" v-else>未填写</span>
        </el-descriptions-item>
        <el-descriptions-item label="项目负责人">
          <template v-if="detail.output.director">{{detail.output.director}}</template>
          <span class="not" v-else>未填写</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-alert title="项目已启动" type="success" show-icon :closable="false" v-if="detail.output.status == 2">
        <p>项目进度及阶段性成果，可在【+添加进度报告】进行添加</p>
      </el-alert>
      <el-alert title="项目无法启动" type="warning" show-icon :closable="false" v-else>
        <p>失败原因：项目缺失必要信息，请通过【编辑项目】完善缺失信息后再启动项目。</p>
        <el-button type="text" @click="toDetail">点我修改完善信息</el-button>
      </el-alert>
    </el-dialog>

    <!--项目结项或终止-->
    <el-dialog title="项目结项或终止" v-model="form.dialogVisibleEnd" width="800px" append-to-body>
      <p style="font-size: 16px;margin-bottom: 20px;">请上传相关材料，通过审核后才能变更项目状态</p>
      <el-form ref="formEndRef" :model="form.input">
        <el-form-item prop="endDocPath">
          <my-upload @change="timeoutShow(() => $refs['formEndRef'].validateField('endDocPath'))" v-model:value="form.endDocPath" :info="{type: '4', pairInfoId: form.input.pairInfoId}"></my-upload>
        </el-form-item>
        <el-form-item style="margin-top: 30px">
          <el-button type="primary" @click="form.submitEndDocPath($refs.formEndRef)">保存</el-button>
          <el-button @click="form.dialogVisibleEnd = false;form.endDocPath = []">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script src="./index.js"></script>
<style src="./index.scss" scoped lang="scss"></style>