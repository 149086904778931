import { ElMessage } from 'element-plus'
import {getCurrentInstance} from 'vue'
import {router} from "@/main";
import {needVip, needNormal} from "@/common/user";

export default function ajax(opt) {
  const vm = getCurrentInstance()
  return new Promise(resolve => {
    var xhr = new XMLHttpRequest()
    xhr.onreadystatechange = async function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          var data = xhr.responseText
          try {data = JSON.parse(xhr.responseText)} catch (e) {}
          if (data.code === 500) {
            ElMessage.error(data.msg || data.error || '网络错误')
          }
          if (data.code === 401) return router.push('/login')
          if (data.code === 403001) return needVip(vm.proxy)
          if (data.code === 403002) return needNormal(vm.proxy)
          if (data?.data?.rows) data = data.data
          opt.success && await opt.success(data, opt.data)
        } else {
          opt.error && await opt.error(xhr, opt.data)
        }
        resolve(data)
      }
    }
    if (!opt.url) return
    let url = /^(http)/.test(opt.url) ? opt.url : '/api' + opt.url
    let ud = /\?/.test(url) ? '' : '?'
    if (!opt.method || opt.method === 'GET') {
      opt.data = opt.data ? JSON.parse(JSON.stringify(opt.data)) : {}
      Object.keys(opt.data || {}).forEach(k => {
        let v = opt.data[k]
        ud += k + '=' + v + '&'
      })
      url += ud
    }
    // formData 特殊处理
    if (opt.data instanceof FormData) {
      xhr.open('POST', url, true)
      window.localStorage.Authorization && xhr.setRequestHeader('Authorization', window.localStorage.Authorization)
      // http.setRequestHeader('Content-type', 'application/x-www-form-urlencode')
      xhr.send(opt.data)
    } else {
      opt.data = opt.data ? JSON.parse(JSON.stringify(opt.data)) : {}
      xhr.open(opt.method || 'GET', url, true)
      window.localStorage.Authorization && xhr.setRequestHeader('Authorization', window.localStorage.Authorization)
      xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8')
      xhr.send(JSON.stringify(opt.data))
    }
  })
}
