<template>
  <div class="form">
    <el-button class="edit-button" size="small" @click="router.push({path: '/user/info'})">取消</el-button>
    <div fx class="title">
      <div>编辑信息<span>(*为必填)</span></div>
    </div>
    <el-form :model="user.input.organization" ref="form" label-width="150px">
      <h2>企业logo</h2>
      <div fx class="upload-section">
        <el-form-item prop="avatar">
          <el-upload
              class="avatar-uploader"
              :before-upload="upload"
              action="呵"
              :show-file-list="false">
            <img v-if="user.input.organization.logo" :src="user.input.organization.logo" class="avatar">
            <div fx class="upload-icon" v-else>
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <span>上传</span>
            </div>
          </el-upload>
          <div>支持JPG，PNG，GIF，1M以内</div>
        </el-form-item>
      </div>
      <h2>基本信息</h2>
      <el-form-item label="统一社会信用代码" prop="shxyNo">
        <el-input v-model="user.input.organization.shxyNo" disabled placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="orgName" :rules="[{required: true, message: '必填项', trigger: 'input'}]">
        <el-input v-model="user.input.organization.orgName" disabled @input="val => user.input.orgName = val" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="所属区域" prop="areaId" :rules="[{ required: true, message: '请选择所属区域' }]">
        <el-select v-model="user.input.organization.areaId" placeholder="请选择" @change="id => user.input.organization.areaName = enums.areaList.getItem(i => i.areaId === id).areaName">
          <el-option :label="i.areaName" :value="i.areaId" v-for="i in enums.areaList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="行业领域" prop="industryId">
        <el-select v-model="user.input.organization.industryId" placeholder="请选择">
          <el-option :label="i.name" :value="i.industryId" v-for="i in enums.industryList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="法人代表" prop="legalPsnName">
        <el-input v-model="user.input.organization.legalPsnName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="注册资金" prop="registCapital">
        <el-input v-model="user.input.organization.registCapital" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="企业规模" prop="taffCnt">
        <el-input v-model="user.input.organization.staffCnt" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="成立时间" prop="establishDate">
        <el-date-picker type="date" placeholder="选择日期" v-model="user.input.organization.establishDate" style="width: 100%;"></el-date-picker>
      </el-form-item>
      <el-form-item label="公司简介" prop="manageRange">
        <el-input v-model="user.input.organization.manageRange" placeholder="请输入"></el-input>
      </el-form-item>
      <h2>实力展示</h2>
      <el-form-item label="擅长服务类型" prop="expertServType">
        <el-input v-model="user.input.organization.expertServType" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="擅长技能" prop="expertSkill">
        <el-input v-model="user.input.organization.expertSkill" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="擅长领域" prop="expertIndustry">
        <el-input v-model="user.input.organization.expertIndustry" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="年度投入经费" prop="techInvestment">
        <el-input v-model="user.input.organization.techInvestment" placeholder="请输入"></el-input>
      </el-form-item>
      <h2>客服信息</h2>
      <el-form-item label="官方客服" prop="customerService">
        <el-input v-model="user.input.organization.customerService" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="服务时间" prop="serviceTime">
        <el-input v-model="user.input.organization.serviceTime" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button @click="router.push({path: '/user/info'})">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script src="./index.js"></script>