import ItemsController from "../../../common/controller/ItemsController";
import ajax from "@/common/ajax";
import { ElMessage, ElMessageBox } from 'element-plus'
import {userInfo} from "@/common/user";

export const apis = new ItemsController({
  ajax,
  project: 'website',
  message: ElMessage,
  messageBox: ElMessageBox,
  user: userInfo
})