<template>
  <main>
    <section>
      <div class="search-bar" id="search-bar">
        <ul class="tabs">
          <li class="active">全部</li>
        </ul>
        <el-input placeholder="请输入内容" v-model="pairInfoList.input.name">
          <template #append>
            <i @click="pairInfoList.update({pageNum: 1})">&#xe6f2;</i>
          </template>
        </el-input>
      </div>
      <my-list :list="pairInfoList.output.rows.map(i => ({
          name: i.name,
          describeInfo: i.rdetail || i.pdetail || i.sdetail,
          labels: i.labels,
          cover: i.cover,
          price: i.price,
          serviceId: i.type === '3' && i.rid,
          provideId: i.type === '2' && i.rid,
          requirementId: i.type === '1' && i.rid
        }))" mode="small" style="margin-top:20px"></my-list>

      <el-pagination v-if="pairInfoList.output.total > pairInfoList.output.rows.length"
         :page-size="pairInfoList.input.pageSize"
         :page="pairInfoList.input.pageNum"
         background @current-change="p => pairInfoList.update({pageNum: p})"
         layout="prev, pager, next"
         :total="pairInfoList.output.total">
      </el-pagination>
    </section>
  </main>
</template>
<style src="./index.scss" scoped lang="scss"></style>
<script src="./index.js"></script>