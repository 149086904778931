<template>
  <main>
    <section>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <!-- 详情面包屑 -->
        <template v-if="/detail/.test(route.path)">
          <el-breadcrumb-item :to="{ path: router.currentRoute.value.path.replace(/\/[^\/]*$/, '') + '/list' }">用户中心</el-breadcrumb-item>
          <el-breadcrumb-item>查看详情</el-breadcrumb-item>
        </template>

        <!-- 对接面包屑 -->
        <template v-else-if="/pair/.test(route.path)">
          <el-breadcrumb-item @click="router.go(-1)">用户中心</el-breadcrumb-item>
          <el-breadcrumb-item>对接意向人</el-breadcrumb-item>
        </template>

        <!-- 默认面包屑 -->
        <el-breadcrumb-item v-else>用户中心</el-breadcrumb-item>
      </el-breadcrumb>
      <div fx>
        <div class="left">
          <div class="user">
            <div fx><img :src="user.output.organization.logo || '/banner.jpg'" alt="" @load="resizeImage"></div>
            <h5>{{ user.output.name }}</h5>
            <p>{{ user.output.organization.orgName }}</p>
            <button @click="router.push({path: '/user/requirement/add'})" v-if="user.output.type === '1'" v-need-vip>发布需求</button>
            <button @click="router.push({path: '/user/provide/add'})" v-if="user.output.type === '2'" v-need-vip>发布资源</button>
            <button @click="router.push({path: '/user/service/add'})" v-if="user.output.type === '3'" v-need-vip>发布服务</button>
            <button @click="router.push({path: '/user/achievement/add',query: {type:'cg'}})" v-need-vip>上传创新成果</button>
          </div>
          <div class="card">
            <div><i style="background-image: linear-gradient(to bottom, rgb(253,209,114), rgb(246,147,42))">&#xe6f5;</i>创新资源推荐</div>
            <my-list :list="list.output.rows" mode="mini"></my-list>
          </div>
        </div>
        <div class="right">
          <div class="search-bar" id="search-bar" v-if="!/pair/.test(route.path) && !/detail/.test(route.path)">
            <ul class="tabs">
              <li :class="{active: /requirement/.test(route.path)}" @click="router.push({path: '/user/requirement/list'})" v-if="user.output.type === '1'" v-need-vip>需求管理</li>
              <li :class="{active: /provide/.test(route.path)}" @click="router.push({path: '/user/provide/list'})" v-if="user.output.type === '2'" v-need-vip>资源管理</li>
              <li :class="{active: /service/.test(route.path)}" @click="router.push({path: '/user/service/list'})" v-if="user.output.type === '3'" v-need-vip>服务管理</li>
              <li :class="{active: /achievement/.test(route.path)}" @click="router.push({path: '/user/achievement/list'})" v-need-vip>成果管理</li>
              <li :class="{active: /message/.test(route.path)}" @click="router.push({path: '/user/message/list'})">消息通知<span v-if="message.output.total">({{message.output.total}})</span></li>
              <li :class="{active: /info/.test(route.path)}" @click="router.push({path: '/user/info'})">用户信息</li>
              <li :class="{active: /tags/.test(route.path)}" @click="router.push({path: '/user/tags'})">标签管理</li>
            </ul>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </section>
  </main>
</template>
<script src="./index.js"></script>
<style scoped src="./index.scss" lang="scss"></style>