<template>
  <div class="form">
    <el-button class="edit-button" size="small" @click="router.push({path: '/user/info'})">取消</el-button>
    <div fx class="title">
      <div>高级会员申请<span>(*为必填)</span></div>
    </div>
    <el-form :model="user.input.organization" ref="form" label-width="150px">
      <el-form-item label="单位名称" prop="orgName" :rules="[{required: true, message: '必填项', trigger: 'change'}]">
        <el-input v-model="user.input.organization.orgName" :disabled="readonly" @input="val => user.input.orgName = val" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="shxyNo" :rules="[{required: true, message: '必填项', trigger: 'change'},{
            validator: (rule, value, callback) => {
              const reg = /^$|[a-zA-Z0-9]{18}$/;
              if (reg.test(value)) { callback();} else {
                callback(new Error());
              }
            },
            message: '社会统一信用代码是18位数字字母的组合',trigger: 'blur',
          }]">
        <el-input v-model="user.input.organization.shxyNo" :disabled="readonly" placeholder="请输入18位数字字母组合的社会统一信用代码"></el-input>
      </el-form-item>
      <el-form-item label="法人代表" prop="legalPsnName" :rules="[{required: true, message: '必填项', trigger: 'change'}]">
        <el-input v-model="user.input.organization.legalPsnName" :disabled="readonly" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="单位注册地址" prop="registeredAddress" :rules="[{required: true, message: '必填项', trigger: 'change'}]">
        <el-input :disabled="readonly" v-model="user.input.organization.registeredAddress" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="用户类型" prop="type" :rules="[{required: true, message: '必填项', trigger: 'change'}]">
        <el-radio-group v-model="user.input.organization.type" :disabled="readonly">
          <el-radio
              v-for="i in enums.itemType"
              :key="i.value"
              :label="i.value"
          >{{ i.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="单位类型" prop="attributeType" :rules="[{required: true, message: '必填项', trigger: 'change'}]">
        <el-radio-group v-model="user.input.organization.attributeType" :disabled="readonly">
          <el-radio
              v-for="i in enums.attributeType"
              :key="i.value"
              :label="i.value"
          >{{ i.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="企业管理员姓名" prop="managerName" :rules="[{required: true, message: '必填项', trigger: 'change'}]">
        <el-input v-model="user.input.organization.managerName" :disabled="readonly" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label="企业管理员手机" prop="managerPhone" :rules="[{required: true, message: '必填项', trigger: 'change'},
          {
            validator: (rule, value, callback) => {
              const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
              if (reg.test(value)) { callback();} else {
                callback(new Error());
              }
            },
            message: '请输入正确的手机号码',trigger: 'blur',
          }]">
        <el-input v-model="user.input.organization.managerPhone" :disabled="readonly" placeholder="请输入11位数字的手机号码"></el-input>
      </el-form-item>

      <el-form-item label="营业执照" props="businessLicense" :rules="[{required: true, message: '必填项', trigger: 'change'}]">
        <my-upload v-model:value="user.input.organization.businessLicense" :disabled="readonly" :info="{type: user.input.organization.type}"></my-upload>
      </el-form-item>

      <el-form-item label="认证状态" v-if="readonly">
        {{enums.orgStatus.getItem(user.input.organization.status).label}}
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submit">保存</el-button>
        <el-button @click="router.push({path: '/user/info'})">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script src="./index.js"></script>