<template>
  <main>
    <section>
      <p class="num">共为你找到 {{ search.output.total }} 条相关结果</p>
    </section>
    <section v-loading="search.loading">
      <my-list :list="search.output.rows" mode="small" style="margin-top:20px"></my-list>
      <el-pagination v-if="search.output.total > search.output.rows.length"
                     :page-size="search.input.pageSize"
                     :page="search.input.pageNum"
                     background @current-change="p => search.update({pageNum: p})"
                     layout="prev, pager, next"
                     :total="search.output.total"/>
    </section>
  </main>
</template>
<script src="./index.js"></script>
<style src="./index.scss" scoped lang="scss"></style>