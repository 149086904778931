import mixin from "@/common/mixin";
import {getCurrentInstance, reactive, watch} from "vue";
import ajax from "@/common/ajax";
import {useRouter} from "vue-router";
import {apis} from "@/utils/apis";
import enums from "../../../../../common/model/enums";

export default {
  mixins: [mixin],
  setup () {
    const vm = getCurrentInstance()
    const router = useRouter()

    const list = apis.list({
      api: 'provideList',
      input: {
        orderBy: undefined, industryId: [], amountRange: [],
        guidId: [], cpMode: [], type: [],
        pageNum: 1, pageSize: 20, areaId: [], maturity: []
      }
    })
    const update = list.update;
    list.update = async (input = {}) => {
      window.scroll(0,0)
      await update(input)
      setTimeout(() => {
        Array.prototype.forEach.call(document.querySelectorAll('.large.list h5 .lt'), h5 => {
          if (list.input.name) {
            h5.innerHTML = h5.innerText.replace(new RegExp(list.input.name, 'g'), `<span style="color:red">${list.input.name}</span>`)
          } else {
            h5.innerHTML = h5.innerText
          }
        })
      })
    }
    list.update()

    const pairInfo = reactive({
      output: {total: 0, rows: []},
      input: {
        pageNum: 1, pageSize: 4
      },
      async update () {
        let res = await ajax({url: '/website/common/pairInfoList', data: pairInfo.input})
        if (res.code !== 200) return
        pairInfo.output = res
      }
    })
    pairInfo.update()

    return {list, enums, pairInfo, router}
  }
}