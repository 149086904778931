<template>
  <main>
    <section style="border:none;background:none">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item>产学研管一体化协同创新大平台用户服务管理规则 （试 行）</el-breadcrumb-item>
      </el-breadcrumb>
    </section>
    <section>
      <div class="ql-editor">
        <h1 style="padding-top:20px" class="ql-align-center">产学研管一体化协同创新大平台用户服务管理规则 （试 行）</h1>
        <p><br></p>
        <h2 class="ql-align-center">第一章&nbsp;&nbsp;总&nbsp;&nbsp;则</h2>
        <p class="ql-align-center"><br></p>
        <p>第一条&nbsp;&nbsp;为进一步优化产学研管一体化协同创新大平台（以下简称“平台”）的科技经济融合发展生态，广泛高效链接人才、技术、资本、产业/企业、服务机构，促进科技成果、创新人才供给与地方产业发展、企业需求高效匹配支持科技服务新模式、新业态发展，推动深化科技经济融合发展，引导平台服务创新创业，加快推动科技创新和科技成果转化，特制定本办法。本办法所指用户是指在平台注册、使用、管理平台账号的用户。
        </p>
        <p>第二条&nbsp;&nbsp;平台由平台运营机构具体承接开展各项服务管理工作。
        </p>
        <p><br></p>
        <h2 class="ql-align-center">第二章&nbsp;&nbsp;用户入驻申请条件及程序</h2>
        <p class="ql-align-center"><br></p>
        <p>第三条&nbsp;&nbsp;机构用户申请基础条件
        </p>
        <p>（一）具有良好的信誉，具备健全的执业规则及其他相应的管理制度，无不良记录；</p>
        <p>（二）在各自的专业领域内，具备提供优质数据资源或优质科技服务的能力；</p>
        <p>（三）提供相应主体的登记/备案文件（如营业执照、事业单位法人证书、社会团体法人证书等）</p>
        <p>第四条&nbsp;&nbsp;机构用户入驻程序
        </p>
        <p>（一）申请。符合以上申请条件的相关机构可通过平台直接进行注册申请。</p>
        <p>（二）审核。平台对收到的申请材料进行审核，于七个工作日内作出通过或者不予通过机构注册成为平台用户的决定。</p>
        <p>（三）创建账号。通过审核后，平台将为机构创建账号。</p>
        <p><br></p>
        <h2 class="ql-align-center">第三章&nbsp;&nbsp;账号使用规则</h2>
        <p class="ql-align-center"><br></p>
        <p>第五条&nbsp;&nbsp;用户应遵循平台所有规则，并有义务对本人/本机构账号在平台所有行为（包括但不限于发布各类信息、点击同意各类规则协议、参与交流互动、发起举报（维权）、申请注销账号等）负责。
        </p>
        <p>第六条&nbsp;&nbsp;用户通过申请入驻后，应尽快完善相关个人/机构信息；登录后，用户可发布信息，包含但不限于需求信息、成果信息、人才信息等；发布的信息应真实、准确、完整并符合相关规定。平台将对有关信息进行审核与认证。
        </p>
        <p>第七条&nbsp;&nbsp;用户可随时更改账号密码。若出现遗忘密码、账号密码被盗用等情况，用户可通过平台技术支持人员找回。
        </p>
        <p><br></p>
        <h2 class="ql-align-center">第四章&nbsp;&nbsp;用户行为规范</h2>
        <p class="ql-align-center"><br></p>
        <p>第八条&nbsp;&nbsp;机构用户如有名称、注册地址、法定代表人、经营范围变更或注销等情况的，应在完成工商登记手续之日起30个自然日内登录平台更新相关信息。
        </p>
        <p>第九条&nbsp;&nbsp;&nbsp;在线交流与互动
        </p>
        <p>用户可通过平台实现在线交流与互动，不得发布、传播违法信息、不良信息和违法平台规定的信息；原则上，平台有权根据相应管理规则作出相应处理。
        </p>
        <p>第十条&nbsp;&nbsp;在线服务</p>
        <p>（一）平台自主提供的服务包括供需发布、供需匹配、资源检索、定制推送等。</p>
        <p>（二）专业服务机构按照公平竞争、平等互利和诚实信用的原则在平台上开展科创服务活动，包括但不限于需求分析、成果审核评价、线上接洽等；不得损害国家和社会公共利益，不得侵犯他人合法权益。
        </p>
        <p>（三）专业服务机构与平台用户签订合同提供收费服务应合法合规，在用户签订合同之前应向用户进行明显提示且获得用户的确认同意。</p>
        <p>第十一条&nbsp;&nbsp;纠纷调解</p>
        <p>平台用户在使用平台服务过程中发生纠纷，可向平台提交纠纷协调申请。平台进行纠纷调解时，专业服务机构应积极配合平台进行调查及取证工作。</p>
        <p><br></p>
        <h2 class="ql-align-center">第五章&nbsp;&nbsp;平台服务管理</h2>
        <p><br></p>
        <p>平台面向产学研服用各类创新主体，提供包括供需对接、资讯查询、交流传播等专业科创服务：</p>
        <p>第十二条&nbsp;&nbsp;供需对接服务</p>
        <p>（一）供需发布服务</p>
        <p>用户可按照平台的数据准入标准，在线填写相关表单，进行需求或成果信息的发布。平台运营机构将在3个工作日内内针对成果/需求信息的规范性、完整性和真实性进行审核，用户可于个人账户查询发布进展和状态。
        </p>
        <p>（二）需求分析服务</p>
        <p>平台可支持第三方机构用户（如科技社团、专业技术服务机构、行业专家等）可在线对接，对咨询、研发、人才、融资、配套等需求进行拆解分析。平台将基于技术手段，开展基于用户需求的成果、机构、文献、资讯等精准服务。
        </p>
        <p>（三）成果审核评价服务</p>
        <p>平台可支持第三方机构可在线接单开展成果审核评价服务，包括对上线成果的成熟度评价、先进性评价、产业化评价等评估，得分高的项目将获得平台认证并置顶推介。
        </p>
        <p>（四）&nbsp;&nbsp;供需匹配和线上接洽服务</p>
        <p>1.平台支持技术转让、技术许可、专家咨询、技术培训等基础技术交易行为的张榜/挂牌、接洽、下单、服务、交付、交易等全服务流程；
        </p>
        <p>2.平台支持用户享有已发布信息（包括但不限于需求、成果、人才、服务等）的精准匹配，平台将匹配结果信息通过个人页面展示、推送通知、链接地址发送等方式推送给用户；
        </p>
        <p>第十三条&nbsp;&nbsp;资源查询服务</p>
        <p>（一）资源检索服务</p>
        <p>平台支持用户可通过关键词检索或按照资源分类、所属领域、发布时间、认证状态、预算投入等条件交叉检索。</p>
        <p>（二）定制推送服务</p>
        <p>平台支持用户可通过设置个人/机构兴趣标签等进行资源定制；平台将根据用户浏览行为标签、用户关注内容标签等，进行资源定向推送。
        </p>
        <p><br></p>
        <h2 class="ql-align-center">第六章&nbsp;&nbsp;退出管理</h2>
        <p class="ql-align-center"><br></p>
        <p>第十四条&nbsp;&nbsp;专业服务机构可于其接单任务服务全部履约完成后申请退出。退出后，平台将不再为该专业服务机构提供信息展示、在线交流、平台接单、需求推荐等服务。平台将按国家法律法规和政府部门有关规定，对其机构信息、服务记录、操作记录数据在一定期限内予以保留。
        </p>
        <p><br></p>
        <h2 class="ql-align-center">第七章&nbsp;&nbsp;附&nbsp;&nbsp;&nbsp;则</h2>
        <p class="ql-align-center"><br></p>
        <p>第十五条&nbsp;&nbsp;其他未尽事宜由平台运营方与相关责任主体另行协商确定。
        </p>
        <p>第十六条&nbsp;&nbsp;本规则由平台运营方负责制定、解释、修订。
        </p>
        <p>第十七条&nbsp;&nbsp;本规则自发布之日开始试行。
        </p></div>
    </section>
  </main>
</template>
<style scoped>
section {
  padding: 20px;
  background-color: #FFF;
  border: 1px solid #DDD;
}

p {
  line-height: 2;
  text-indent: 2em;
}
h1{
  font-size:24px;
  font-weight:400;
}
h2 {
  font-weight:400;
  font-size:18px;
}
</style>