<template>
  <div class="left">
    <div class="card">
      <div><i style="background-image: linear-gradient(to bottom, rgb(88,212,107), rgb(40,183,61))">&#xe6fa;</i>客服信息</div>
      <div class="call">
        <div>官方客服：{{ detail.output.customerService }}</div>
        <div>服务时间</div>
        <p>{{ detail.output.serviceTime }}</p>
      </div>
    </div>
    <div class="card">
      <div><i style="background-image: linear-gradient(to bottom, rgb(253,209,114), rgb(246,147,42))">&#xe6f9;</i>最新对接</div>
      <div>
        <ul class="news">
          <li v-for="i in pairInfo.output.rows" style="cursor: default">
            <div>{{ i.name }}</div>
            <div fx>
              <span>{{ i.rIndustryName }}</span>
              <span>{{ i.createTime.replace(/\s.+$/, '') }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>

<!--    <div class="card">-->
<!--      <div><i style="background-image: linear-gradient(to right, rgb(223,202,127), rgb(224,154,88))">&#xe6fb;</i>访问统计</div>-->
<!--      <div fx class="view">-->
<!--        <div>-->
<!--          <div>今日浏览量</div>-->
<!--          <b>123</b>-->
<!--        </div>-->
<!--        <div>-->
<!--          <div>一周浏览量</div>-->
<!--          <b>25122</b>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script src="./index.js"></script>
<style scoped>
.left {
  width: 266px;
  margin-right: 20px;
}

.call > div {
  font-weight: 500
}

.call > div:first-child {
  margin-bottom: 20px
}

.call > div:first-child + div {
  padding-top: 20px;
  padding-bottom: 10px;
  border-top: 1px solid #EEE;
}

.call p {
  font-size: 14px;
}

.list {
  padding: 0 20px;
}

.list li {
  padding: 20px 0;
}

.list li + li {
  border-top: 1px solid #EEE;
}

.list li > div:first-child {
  width: 66px;
  height: 66px;
}

.list li > div:first-child + div {
  flex: 1;
  margin-left: 10px;
}

.list b {
  font-size: 20px;
  color: #358AF2;
  margin-right: 3px;
}

.list p {
  color: #9D9D9D;
}

.list p span {
  color: #333;
}

.view {
  text-align: center;
}

.view > div {
  width: 50%;
}

.view > div:first-child {
}

.view > div:last-child {
  border-left: 1px solid #eee;
}

.view b {
  font-weight: 500;
  color: #358AF2;
  font-size: 30px;
}
</style>