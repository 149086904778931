<template>
  <section class="banner" style="margin-top: -20px;">
    <img src="../../assets/banner.jpg" alt="">
    <div fx>
      <div>
        <span style="font-size:22px;font-weight:500">{{ detail.output.orgName }}</span>
        <el-tag effect="plain" size="mini">
          <span v-if="detail.output.status==1">普通会员</span>
          <span v-if="detail.output.status==2">高级会员</span>
          <span v-if="detail.output.vipStatus==3">受限会员</span>
        </el-tag>
        <el-button type="primary" size="small" @click="dialogVisible = true">查看基本信息</el-button>
      </div>
      <div class="r-btn" v-if="num.output.count"><i>&#xe6f0;</i>成功对接次数：{{num.output.count}}</div>
    </div>
    <el-dialog
        v-model="dialogVisible"
        title="基本信息" top="3vh"
        width="50%">
      <my-org-detail :detail="detail.output" style="margin-top:-60px"></my-org-detail>
      <template #footer>
        <div style="text-align: center">
          <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </section>
</template>
<script src="./index.js"></script>
<style scoped lang="scss">
.banner {
  width: 100%;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 100%;
  color: #FFF;
  padding: 0;
  margin-bottom: 75px;
}

.banner > div {
  height: 110px;
  color: #333;
  position: absolute;
  width: 1280px;
  left: 50%;
  bottom: 0;
  padding: 20px;
  transform: translate(-50%, 50%);
  background-color: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
}

.banner h2 {
  height: 50px;
  font-size: 36px;
  font-weight: 600;
  color: #FFF;
  line-height: 50px;
  margin-bottom: 15px;
}

.banner a {
  justify-content: center;
  line-height: 44px;
  height: 44px;
}

.banner a + a {
  margin-left: 12px;
}

.banner a:first-child {
  color: #FFF;
  width: 136px;
  background: #06468E;
  box-shadow: 0 6px 16px 0 #1C62B8;
}

.banner a:last-child {
  width: 136px;
  background: #FFFFFF;
  color: #1C62B8;
  box-shadow: 0 6px 16px 0 rgba(28, 98, 184, 0.56);
}

.banner img {
  width: 100%;
}

.banner .el-tag {
  margin: 0 10px;
}

.banner .r-btn {
  width: 150px;
  height: 30px;
  background: #35B6F2;
  border-radius: 15px;
  line-height: 30px;
  color: #FFF;
  font-size: 14px;
  text-align: center;
  i{
    font-size:12px;
    margin-right:5px;
  }
}
</style>