import mixin from "@/common/mixin";
import {computed, getCurrentInstance, reactive} from "vue";
import ajax from "@/common/ajax";
import {useRoute, useRouter} from "vue-router";
import {ElMessage, ElMessageBox} from "element-plus";
import Items from "../../../../common/model/Items";
export default {
  mixins: [mixin],
  props: ['url', 'id'],
  setup (props) {
    const vm = getCurrentInstance()
    const router = useRouter()
    const route = useRoute()
    const detail = reactive({
      output: {scRequirement: {}, fileList: [], scPair: {}, scPairInfo: {}, scProvide: {}},
      loading: true,
      async update () {
        let res = await ajax({url: props.url})
        if (res.code !== 200) return
        if (res.data.pairInfoId) {
          detail.output.scPairInfo = new Items(res.data)
        } else {
          res.data.scPairInfo = new Items(res.data.scPairInfo)
          res.data.scRequirement = new Items(res.data.scRequirement)
          res.data.scPair = new Items(res.data.scPair)
          res.data.scProvide = new Items(res.data.scProvide)
          detail.output = res.data
        }
        detail.loading = false
      },
      async changeStatus (status) {
        await new Promise(res => {
          ElMessageBox.confirm('您确定要改变项目状态吗？', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback (action) {
              if (action === 'confirm') res()
            }
          })
        })
        let res = await ajax({url: '/website/req/changeProjectStatus', data: {
            pairId: detail.output.scPair.id,
            status: status,
            fid: detail.output.scRequirement.requirementId
          }, method: 'POST'})
        if (res.code !== 200) return
        detail.output.scPair.status = status
        ElMessage.success('操作成功')
      }
    })
    detail.update()

    const user = vm.proxy.$root.userInfo
    const canPair = computed(() => {
      if (!user.type) return false
      if (!detail.output.name) return false
      // 需求，只能让非需求方对接
      if (detail.output.requirementId && user.type === '1') return false
      // 资源，只能让非资源方对接
      if (detail.output.provideId && user.type === '2') return false
      // 服务，只能让非服务方对接
      if (detail.output.serviceId && user.type === '3') return false
      // 其他的都能自由对接
      return true
    })
    return {detail, router, route, canPair}
  }
}