import mixin from "@/common/mixin";
import ajax from "@/common/ajax";
import {reactive, getCurrentInstance, ref} from "vue";
import {ElLoading} from "element-plus";
import {useRouter} from "vue-router";
import {apis} from "@/utils/apis";
import enums from "../../../../common/model/enums";

export default {
  el: "main",
  mixins: [mixin],
  setup () {
    const vm = getCurrentInstance()
    const router = useRouter()
    const user = vm.proxy.$root.userInfo

    const pairInfo = apis.list({api: 'pairList', input: {pageSize: 15}})
    pairInfo.update(pairInfo.input, () => {
      // 没有5条都滚不起来
      while (pairInfo.output.rows.length > 0 && pairInfo.output.rows.length < 5) {
        pairInfo.output.rows = pairInfo.output.rows.concat(pairInfo.output.rows)
      }
    })

    const requirement = apis.list({api: 'requirementList', input: {pageSize: 6}})
    requirement.update()

    const provide = apis.list({api: 'provideList', input: {pageSize: 6}})
    provide.update()

    const service = apis.list({api: 'serviceList', input: {pageSize: 6}})
    service.update()

    const cases = apis.list({api: 'caseList', input: {pageSize: 6}})
    cases.update()

    const achievement = apis.list({api: 'achievementList', input: {pageSize: 6, status: '1'}})
    achievement.update()

    const showImg = ref([{},{show: false},{show: false},{show: false},{show: false},{show: false},{show: false},{show: false}])

    const notice = apis.list({
      api: 'noticeList',
      input: {
        pageSize: 3,
        type: 1
      },
      activeOne: {}
    })
    notice.myUpdate = name => {
      notice.update({type: name === '政策法规' ? 2 : 1}, () => {
        notice.activeOne = notice.output.rows[0] || {}
      })
    }
    notice.myUpdate()

    const orgList = ref([])
    const org = apis.list({
      api: 'orgList',
      activeType: 1,
      input: {
        pageSize: 4,
        status: 2
      }
    })
    const mouseOver = (p) => {
      showImg.value[p].show = true;
    }
    const mouseLeave = (p) => {
      showImg.value[p].show = false;
    }
    enums.attributeType.forEach(i => org.update({attributeType: i.value}, () =>
      orgList.value = orgList.value.concat(org.output.rows)))

    return {org, orgList, notice, requirement, provide, user, service, pairInfo, enums, router, achievement, showImg, mouseOver, mouseLeave,cases}
  }
}