<template>
  <main>
    <section style="border:none;background:none">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item>平台用户信息发布规则 （试 行）</el-breadcrumb-item>
      </el-breadcrumb>
    </section>
    <section>
      <div class="ql-editor">
        <h1 style="padding-top:20px" class="ql-align-center">平台用户信息发布规则 （试 行）</h1>
        <p><br></p>
        <h2 class="ql-align-center">第一章&nbsp;&nbsp;总&nbsp;&nbsp;&nbsp;则</h2>
        <p class="ql-align-center"><br></p>
        <p>第一条&nbsp;为维护产学研管一体化协同创新大平台（以下简称“平台”）正常运行秩序，规范平台用户信息发布行为，根据国家相关法律法规制定本规则。
        </p>
        <p>第二条&nbsp;&nbsp;平台用户是指注册、使用、管理平台账号的用户，包括机构、组织、个人、科技服务单位、科研院所等，本规则适用于所有入驻平台的用户。
        </p>
        <p><br></p>
        <h2 class="ql-align-center">第二章&nbsp;&nbsp;信息发布</h2>
        <p class="ql-align-center"><br></p>
        <p>第三条&nbsp;&nbsp;用户于平台发布的信息，包括创新需求、创新资源、创新服务、创新成果等内容，包括文字、图片、语音、视频等形式。
        </p>
        <p>第四条&nbsp;&nbsp;用户应确保在平台上发布的信息真实、准确，无误导性。用户在平台发布的信息，需遵守法律法规和本平台的相关规定，并经平台运营机构审核通过后才能发布。
        </p>
        <p>第五条&nbsp;&nbsp;用户应确保对上传到平台的内容拥有或取得必须的权利（包括但不限于：用户所有权或已取得必要的许可、授权、准许使用所有与上传作品有关的所有专利、商标、商业秘密、著作权、邻接权及其他相关权利）。未经相关权利人同意，用户不得对平台上发布的科技服务涉及的相关网页、应用、软件等进行反向工程、反向汇编、反向编译等，不得在平台上复制、传播、转让或许可第三方使用他人的知识成果等违反国家知识产权相关规定的内容。如被第三方提起任何投诉、索赔、诉讼或其他类型的诉求，用户应承担全部的法律责任，给平台造成的损失（包括但不限于经济、商誉等）均由该用户承担。
        </p>
        <p>第六条&nbsp;&nbsp;用户发布的所有信息，平台仅以普通或者非专业人员的知识水平标准对用户上传的资料和发布的信息进行辨别，不对用户提供科技服务的内容、品质、真伪及相关权利承担任何担保责任。
        </p>
        <p>第七条&nbsp;&nbsp;用户禁止在本平台上发布、传播违法信息、不良信息、违反平台规定的信息。
        </p>
        <p>（一）违法信息包括但不限于：</p>
        <p>1.反对宪法所确定的基本原则的；</p>
        <p>2.危害国家安全，泄露国家秘密 ，颠覆国家政权，破坏国家统一的；</p>
        <p>3.损害国家荣誉和利益的；</p>
        <p>4.歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</p>
        <p>5.宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
        <p>6.煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>7.破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>8.散布谣言，扰乱经济秩序和社会秩序的；</p>
        <p>9.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>10.侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；</p>
        <p>11.法律、行政法规禁止的其他内容。</p>
        <p>（二）不良信息包括但不限于：</p>
        <p>1.使用夸张标题，内容与标题严重不符的；</p>
        <p>2.不当评述自然灾害、重大事故等灾难的；</p>
        <p>3.带有性暗示、性挑逗等易使人产生性联想的；</p>
        <p>4.展现血腥、惊悚、残忍等致人身心不适的；</p>
        <p>5.煽动人群歧视、地域歧视等的；</p>
        <p>6.宣扬低俗、庸俗、媚俗内容的；</p>
        <p>7.可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；</p>
        <p>8.其他对网络生态造成不良影响的内容。</p>
        <p>&nbsp;（三）违反平台规定的信息包括但不限于：</p>
        <p>1.广告、垃圾类信息。</p>
        <p>2.数据作假、作弊等虚假信息。</p>
        <p>3.重复发布同一内容的信息。</p>
        <p>4.侵犯第三方知识产权的信息。</p>
        <p>5.包含多个需求的单条信息。</p>
        <p>6.推广自己或他人需求的信息。</p>
        <p>7.含有引导客户到其他网站、平台进行交易的信息。</p>
        <p>8.可能会干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒、计算机代码、文档和程序等。</p>
        <p>9.未经授权泄露他人隐私或企业内部数据（含个人联系方式、个人手机定位、电话清单查询、银行账户查询等内容）。</p>
        <p>10.发布过期的营业执照、社会统一信用代码、资质证书、职业资格证书等信息。</p>
        <p>11.包含极限词汇、夸张用语等信息。</p>
        <p>12.涉及恶意竞争、扰乱交易秩序的信息。</p>
        <p>13.威胁、敲诈勒索等评价信息。</p>
        <p>14.可能套取其他用户身份证、邮箱、手机号、银行账号等隐私内容的信息。</p>
        <p>15.非专业服务机构的平台用户发布提供法律、金融、税务服务内容的信息。</p>
        <p>16.违反本平台规定的其他信息。</p>
        <p><br></p>
        <h2 class="ql-align-center">第三章&nbsp;&nbsp;违规行为处理</h2>
        <p class="ql-align-center"><br></p>
        <p>第八条&nbsp;&nbsp;如用户出现第二章所列违规情况，按照相应法律及政策制度进行处理。
        </p>
        <p><br></p>
        <h2 class="ql-align-center">第四章&nbsp;&nbsp;附&nbsp;&nbsp;则</h2>
        <p class="ql-align-center"><br></p>
        <p>第九条&nbsp;&nbsp;其他未尽事宜由平台运营单位与相关责任主体另行协商确定。
        </p>
        <p>第十条&nbsp;&nbsp;本规则由平台运营单位负责制定、解释、修订。
        </p>
        <p>第十一条&nbsp;&nbsp;本规则自发布之日起开始试行。
        </p>
        <p>&nbsp;</p></div>
    </section>
  </main>
</template>
<style scoped>
section {
  padding: 20px;
  background-color: #FFF;
  border: 1px solid #DDD;
}

p {
  line-height: 2;
  text-indent: 2em;
}
h1{
  font-size:24px;
  font-weight:400;
}
h2 {
  font-weight:400;
  font-size:18px;
}
</style>