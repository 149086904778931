import {getCurrentInstance, reactive, ref, onMounted} from "vue";
import {useRoute,useRouter} from "vue-router";
import ajax from "@/common/ajax";
import {ElMessage, ElMessageBox} from "element-plus";
import {BarController, Chart, CategoryScale, LinearScale, BarElement, Tooltip} from 'chart.js';
Chart.register(BarController)
Chart.register(CategoryScale)
Chart.register(LinearScale)
Chart.register(BarElement)
Chart.register(Tooltip)


export default {
  setup () {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router= useRouter()
    const user = vm.proxy.$root.userInfo

    const tagsList = reactive({
      input: {
        orgId: user.orgId,
        type: '2'
      },
      output: {
        rows: []
      },
      activeOne: undefined,
      async setActive (i) {
        if (!i.type) return tagsList.activeOne = undefined
        let res = await ajax({url: '/website/label/' + i.id, method: 'GET'})
        tagsList.activeOne = res.data
        await new Promise(res => setTimeout(res))
        let chart = document.querySelector('#chart')
        let parent = chart.parentNode
        parent.removeChild(chart)
        parent.innerHTML += `<canvas id="chart"></canvas>`
        tagsList.chart = new Chart(document.querySelector('#chart'), {
          type: 'bar',
          data: {
            labels: ['需求方引用数', '资源方引用数', '服务方引用数'],
            datasets: [{
              axis: 'y',
              label: '次数',
              data: [
                tagsList.activeOne.requirementCount,
                tagsList.activeOne.provideCount,
                tagsList.activeOne.serviceCount,
              ],
              fill: false,
              backgroundColor: [
                'rgb(54, 162, 235)'
              ]
            }]
          },
          options: {
            indexAxis: 'y'
          }
        })
      },
      async update (input = {}) {
        Object.assign(tagsList.input, input)
        let res1 = ajax({url: '/website/label/list', data: {type: 1}, guid: Math.random()})
        let res2 = ajax({url: '/website/label/list', data: tagsList.input})
        tagsList.output = {
          rows: [
            {id: 'co',name: '公共标签', children: (await res1).rows},
            {id: 'cu', name: '自定义标签', children: (await res2).rows}
          ]
        }
        setTimeout(() => document.querySelector('.el-tree-node__children').querySelector('.el-tree-node__content').click())
      }
    })
    tagsList.update()


    const form = ref(null)
    const tag = reactive({
      input: {
        status: '1',
        type: '2'
      },
      dialogVisible: false,
      async submit () {
        form.value.validate(async (valid) => {
          if (!valid) return
          let res = await ajax({url: '/website/label', data: tag.input, method: tag.input.id ? 'PUT' : 'POST'})
          if (res.code !== 200) return
          ElMessage.success('操作成功')
          tag.dialogVisible = false
          tag.input = {
            status: '1',
            type: '2'
          }
          tagsList.update()
        })
      },
      async del (i) {
        await new Promise(res => {
          ElMessageBox.confirm('您确定要删除吗？', '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback (action) {
              if (action === 'confirm') res()
            }
          })
        })
        let res = await ajax({url: '/website/label/' + i.id, method: 'DELETE'})
        if (res.code !== 200) return
        tagsList.update()
        ElMessage.success('操作成功')
      }
    })

    return {tagsList, route, router, tag, form}
  }
}