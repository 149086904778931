import mixin from "@/common/mixin";
import ajax from "@/common/ajax";
import {ref, reactive, getCurrentInstance, watch} from 'vue'
import {useRoute} from "vue-router";
import Items from "../../../../common/model/Items";

export default {
  setup() {
    const vm = getCurrentInstance()
    const route = useRoute()
    const search = reactive({
      visible: false,
      left: 0,
      type: route.query.type || '1',
      output: {rows: [], total: 0},
      input: {
        pageNum: 1, pageSize: 15, name: route.query.name
      },
      loading: true,
      showSearch (e) {
        search.left = e.layerX + 20
        search.visible = true
        setTimeout(() => {
          document.querySelector('#search-box').querySelector('.el-input-group > input').focus()
        })
      },
      async submit () {
        search.loading = true
        let res
        if (search.type === '1') {
          res = await ajax({url: '/website/common/scRequirementList', data: search.input})
        }
        if (search.type === '2') {
          res = await ajax({url: '/website/common/scProvideList', data: search.input})
        }
        if (search.type === '3') {
          res = await ajax({url: '/website/common/scServiceList', data: search.input})
        }
        if (res.code !== 200) return
        search.loading = false
        res.rows = res.rows.map(i => new Items(i))
        search.output = res
        setTimeout(() => {
          Array.prototype.forEach.call(document.querySelectorAll('.list h5 .lt'), h5 => {
            if (search.input.name) {
              h5.innerHTML = h5.innerText.replace(new RegExp(search.input.name, 'g'), `<span style="color:red">${search.input.name}</span>`)
            } else {
              h5.innerHTML = h5.innerText
            }
          })
        })
      }
    })
    search.submit()
    watch(() => route.query, () => {
      search.input.name = route.query.name
      search.type = route.query.type || '1'
      search.submit()
    }, {deep: true})

    return {route, search}
  },
  methods: {

  }
}