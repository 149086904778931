import {computed, getCurrentInstance, reactive} from "vue";
import ajax from "@/common/ajax";
import {useRoute, useRouter} from "vue-router";
import {ElMessage, ElMessageBox} from "element-plus";
import mixin from "@/common/mixin";
import Url from "../../../../common/model/Url";
import enums from "../../../../common/model/enums";
import {userInfo} from "@/common/user";
import {apis} from "@/utils/apis";

export default {
  props: ['id', 'type'],
  mixins: [mixin],
  setup (props) {
    const vm = getCurrentInstance()
    const router = useRouter()
    const route = useRoute()
    const user = vm.proxy.$root.userInfo
    const detail = apis.detail({
      api: props.type+ 'Detail',
      input: {
        id: props.id
      }
    })
    detail.update()
    const list = apis.list({
      api: 'myPairList',
      input: {
        rid: props.id,
        sid: props.id,
        pid: props.id,
        pageSize: 10,
        pageNum: 1
      },
      async changeItem (i, c) {
        await new Promise(res => {
          ElMessageBox.confirm(`您确定${c.status == '1' ? '确认合作' : '不合适'}吗？`, '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback (action) {
              if (action === 'confirm') res()
            }
          })
        })
        let res = await ajax({url: `/website/req/changeStatus`, data: Object.assign(i, c), method: 'POST'})
        if (res.code !== 200) return
        if (res.data.status == 4) {
          // 合作成功，返回上一页
          router.go(-1)
        }
        if (res.data.status == 2) {
          // 不合适，更新列表
          list.update()
        }
        ElMessage.success('操作成功')
      }
    })
    list.update()

    return {detail, route, router, list, user, enums}
  }
}