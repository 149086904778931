<template>
  <el-form-item label="行业领域" prop="industryId" :rules="[{required: true, message: '必填项'}]">
    <el-select v-model="input.industryId" placeholder="请选择"
               @change="id => {input.industryName = enums.industryList.getItem(i => i.industryId === id).name}">
      <el-option :label="i.name" :value="i.industryId" v-for="i in enums.industryList"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="行业分类" prop="subGuidId" :rules="[{required: true, message: '必填项'}]">
    <el-select v-model="input.guidId" placeholder="请选择分类" style="width:50%"
               @change="id => {
                     input.subGuidId = undefined;
                     input.guidName = enums.guidList.getItem(i => i.guidId === id).name}">
      <template  v-for="i in enums.guidList">
        <el-option :label="i.name" :value="i.guidId" v-if="i"></el-option>
      </template>
    </el-select>
    <el-select v-model="input.subGuidId" placeholder="请选择二级分类" style="width:50%"
               @change="id => {
                     input.subGuidName = enums.subGuidList(input.guidId).getItem(i => i.subGuidId === id).name}">
      <template v-for="i in enums.subGuidList(input.guidId)">
        <el-option :label="i.name" :value="i.guidId" v-if="i"></el-option>
      </template>
    </el-select>
  </el-form-item>
</template>
<script>
import enums from "../../model/enums";
export default {
  props: ['input'],
  setup () {
    return {enums}
  }
}
</script>