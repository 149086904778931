<template>
  <main>
    <my-org-top :detail="detail" v-if="detail.output.orgId"></my-org-top>
    <section>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item v-if="route.query.pairId" :to="{path: '/org/detail', query: {id:route.query.id }}">{{ detail.output.orgName }}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>{{ detail.output.orgName }}</el-breadcrumb-item>
        <el-breadcrumb-item v-if="route.query.pairId">成功案例</el-breadcrumb-item>
      </el-breadcrumb>
    </section>
    <section style="margin-top:20px;align-items: flex-start">
      <my-org-left  :detail="detail" v-if="detail.output.orgId"></my-org-left>
      <div class="right" v-if="!route.query.pairId">
        <div class="search-bar" id="search-bar">
          <ul class="tabs">
            <li :class="{active: activeTab == 1}" @click="activeTab = 1">
              <span v-if="detail.output.type === '1'">企业需求</span>
              <span v-if="detail.output.type === '2'">企业资源</span>
              <span v-if="detail.output.type === '3'">企业服务</span>
            </li>

            <li :class="{active: activeTab == 2}" @click="activeTab = 2">成功案例</li>
          </ul>
        </div>
        <div v-if="activeTab == 1" style="margin-top:20px">
          <div fx class="condition">
            <div fx>
              <div>状态：<el-select
                  v-model="list.input.status" placeholder="请选择" style="width:100px" @change="list.update({pageNum: 1})">
                <el-option label="全部" :value="undefined"></el-option>
                <el-option
                    v-for="i in enums.itemType"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value">
                </el-option>
              </el-select></div>
              <!--              <div>发布日期：<el-date-picker-->
              <!--                  type="daterange"-->
              <!--                  range-separator="至"-->
              <!--                  start-placeholder="开始日期"-->
              <!--                  end-placeholder="结束日期">-->
              <!--              </el-date-picker></div>-->
            </div>
          </div>
          <my-list :list="list.output.rows" mode="large"></my-list>
          <el-pagination v-if="list.output.total > list.output.rows.length"
                         :page-size="list.input.pageSize"
                         :page="list.input.pageNum"
                         background @current-change="p => list.update({pageNum: p})"
                         layout="prev, pager, next"
                         :total="list.output.total">
          </el-pagination>
        </div>
        <div v-if="activeTab == 2" style="margin-top:20px">
          <my-list :list="pList.output.rows" mode="large" stopToDetail>
            <!-- <template #right="{i}">
              <el-button @click="router.push({path: '/org/detail', query: {pairId: i.pairInfoId, id:route.query.id }})">查看详情</el-button>
            </template> -->
          </my-list>
          <el-pagination v-if="pList.output.total > pList.output.rows.length"
                         :page-size="pList.input.pageSize"
                         :page="pList.input.pageNum"
                         background @current-change="p => pList.update({pageNum: p})"
                         layout="prev, pager, next"
                         :total="pList.output.total">
          </el-pagination>
        </div>
      </div>
      <div class="right hideRightBtn" v-else style="padding:20px;background-color:#FFF">
        <!--<my-project-detail :url="'/website/pair/infoBYPairId/' + $route.query.pairId"></my-project-detail>-->

        <my-project-detail
                           :id="route.query.pairId"
        ></my-project-detail>
      </div>
    </section>
  </main>
</template>
<style src="./index.scss" scoped lang="scss"></style>
<script src="./index.js"></script>