<template>
  <main>
    <section class="banner">
      <div class="left">
        <el-tabs model-value="通知公告" type="border-card" @tab-change="notice.myUpdate">
          <el-tab-pane v-for="name in ['通知公告', '政策法规']" :label="name" :name="name" v-loading="notice.loading">
            <ul class="list" v-if="notice.output.rows.length">
              <router-link :to="{path: '/notice/list', query: {type: notice.input.type}}" class="more">查看更多></router-link>
              <li v-for="(i, index) in notice.output.rows"
                  :class="{active: notice.activeOne.noticeId === i.noticeId}"
                  @mouseenter="notice.activeOne = notice.output.rows[index]">
                <router-link class="title" :to="{path: '/notice/detail', query: {id: i.noticeId}}">{{ i.title }}</router-link>
                <div>{{ (i.createTime || '').replace(/\s.+$/, '') }}</div>
              </li>
            </ul>
            <el-empty v-else></el-empty>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="right">
        <img v-if="notice.activeOne.cover" @click="router.push({path: '/notice/detail', query: {id: notice.activeOne.noticeId}})" :src="notice.activeOne.cover" @load="resizeImage">
        <el-empty v-else description="暂无图片"></el-empty>
        <div class="title">
          <p @click="router.push({path: '/notice/detail', query: {id: notice.activeOne.noticeId}})">{{ notice.activeOne.title }}</p>
          <div>
            <span v-for="(i, index) in notice.output.rows"
                  :class="{active: notice.activeOne.noticeId === i.noticeId}"
                  @mouseenter="notice.activeOne = notice.output.rows[index]"></span>
          </div>
        </div>
      </div>
    </section>
    <section class="gray entry">
      <div>
        <img src="@/assets/index-s1.png"/>
        <div class="hes" @click="router.push({path: '/requirement/list'})">
          <div class="title">
            <my-png height="20px" style="transform:translateY(4px)">
              <img src="@/assets/index-ss1.png" :style="{color: '#358AF2',width: '17px',height: '14px'}"/>
            </my-png>
            找需求</div>
          <p>查找平台发布的各类需求信息，寻求合作方</p>
          <my-png class="arrow" height="10px" style="transform:translateY(4px) rotate(90deg)">
            <img src="@/assets/up.png" :style="{color: '#9D9D9D',width: '10px',height: '6px'}"/>
          </my-png>
        </div>
        <div class="hes" @click="router.push({path: '/user/requirement/add'})" v-need-vip>
          <div class="title">
            <my-png height="20px" style="transform:translateY(4px)">
              <img src="@/assets/index-ss2.png" :style="{color: '#358AF2',width: '17px',height: '14px'}"/>
            </my-png>
            发需求</div>
          <p>发布需求至平台，寻求各匹配合作方达成合作</p>
          <my-png class="arrow" height="10px" style="transform:translateY(4px) rotate(90deg)">
            <img src="@/assets/up.png" :style="{color: '#9D9D9D',width: '10px',height: '6px'}"/>
          </my-png>
        </div>
      </div>
      <div>
        <img src="@/assets/index-s2.png"/>
        <div class="hes" @click="router.push({path: '/provide/list'})">
          <div class="title">
            <my-png height="20px" style="transform:translateY(4px)">
              <img src="@/assets/index-ss1.png" :style="{color: '#5CCCA0',width: '17px',height: '14px'}"/>
            </my-png>
            找资源</div>
          <p>查找平台发布的各类资源信息，寻求合作方</p>
          <my-png class="arrow" height="10px" style="transform:translateY(4px) rotate(90deg)">
            <img src="@/assets/up.png" :style="{color: '#9D9D9D',width: '10px',height: '6px'}"/>
          </my-png>
        </div>
        <div class="hes" @click="router.push({path: '/user/provide/add'})" v-need-vip>
          <div class="title">
            <my-png height="20px" style="transform:translateY(4px)">
              <img src="@/assets/index-ss2.png" :style="{color: '#5CCCA0',width: '17px',height: '14px'}"/>
            </my-png>
            发资源</div>
          <p>发布资源至平台，寻求各匹配合作方达成合作</p>
          <my-png class="arrow" height="10px" style="transform:translateY(4px) rotate(90deg)">
            <img src="@/assets/up.png" :style="{color: '#9D9D9D',width: '10px',height: '6px'}"/>
          </my-png>
        </div>
      </div>
      <div>
        <img src="@/assets/index-s3.png"/>
        <div class="hes" @click="router.push({path: '/service/list'})">
          <div class="title">
            <my-png height="20px" style="transform:translateY(4px)">
              <img src="@/assets/index-ss1.png" :style="{color: '#E5C229',width: '17px',height: '14px'}"/>
            </my-png>
            找服务</div>
          <p>查找平台发布的各类服务信息，寻求合作方</p>
          <my-png class="arrow" height="10px" style="transform:translateY(4px) rotate(90deg)">
            <img src="@/assets/up.png" :style="{color: '#9D9D9D',width: '10px',height: '6px'}"/>
          </my-png>
        </div>
        <div class="hes" @click="router.push({path: '/user/service/add'})" v-need-vip>
          <div class="title">
            <my-png height="20px" style="transform:translateY(4px)">
              <img src="@/assets/index-ss2.png" :style="{color: '#E5C229',width: '17px',height: '14px'}"/>
            </my-png>
            发服务</div>
          <p>查找平台发布的各类服务信息，寻求合作方</p>
          <my-png class="arrow" height="10px" style="transform:translateY(4px) rotate(90deg)">
            <img src="@/assets/up.png" :style="{color: '#9D9D9D',width: '10px',height: '6px'}"/>
          </my-png>
        </div>
      </div>
    </section>
    <section class="scroll">
      <div class="left">
        <div class="title">政府创新资源服务</div>
        <ul>
          <li @mouseover="mouseOver(1)"
              @mouseleave="mouseLeave(1)">
            <router-link :to="{path: '/provide/list'}">
              <img src="@/assets/w1.png" v-if="!showImg[1].show">
              <img src="@/assets/w11.png" v-if="showImg[1].show">
              <span>虚拟服务大厅</span>
            </router-link>
          </li>
          <li @mouseover="mouseOver(2)"
              @mouseleave="mouseLeave(2)">
            <a href="http://gkg.kjt.gxzf.gov.cn" target="_blank">
              <img src="@/assets/w2.png" v-if="!showImg[2].show">
              <img src="@/assets/w22.png" v-if="showImg[2].show">
              <span>报项目</span>
            </a>
          </li>
          <li @mouseover="mouseOver(3)"
              @mouseleave="mouseLeave(3)">
            <a href="https://www.gxat.cn/Home/Main" target="_blank">
              <img src="@/assets/w3.png" v-if="!showImg[3].show">
              <img src="@/assets/w33.png" v-if="showImg[3].show">
              <span>分析检测</span>
            </a>
          </li>
          <li @mouseover="mouseOver(4)"
              @mouseleave="mouseLeave(4)">
            <a href="http://cxq.kjt.gxzf.gov.cn" target="_blank">
              <img src="@/assets/w4.png" v-if="!showImg[4].show">
              <img src="@/assets/w44.png" v-if="showImg[4].show">
              <span>创新券</span>
            </a>
          </li>
          <li @mouseover="mouseOver(5)"
              @mouseleave="mouseLeave(5)">
            <a href="http://gxgq.gxinfo.org:88" target="_blank">
              <img src="@/assets/w5.png" v-if="!showImg[5].show">
              <img src="@/assets/w55.png" v-if="showImg[5].show">
              <span>高企申报</span>
            </a>
          </li>
          <li @mouseover="mouseOver(6)"
              @mouseleave="mouseLeave(6)">
            <a href="http://gxgq.gxinfo.org:88" target="_blank">
              <img src="@/assets/w6.png" v-if="!showImg[6].show">
              <img src="@/assets/w66.png" v-if="showImg[6].show">
              <span>查文献</span>
            </a>
          </li>
          <li @mouseover="mouseOver(7)"
              @mouseleave="mouseLeave(7)">
            <a href="http://kjcx.gxinfo.org" target="_blank">
              <img src="@/assets/w7.png" v-if="!showImg[7].show">
              <img src="@/assets/w77.png" v-if="showImg[7].show">
              <span>科技创新</span>
            </a>
          </li>
          <li class="more">
            <a href="javascript:;">
              <img src="@/assets/more.png">
              <span>敬请期待</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="right">
        <div fx class="shadow">
          <div style="padding:20px 30px">
            <div fx class="total"><b>配对动态</b>/ 本月累计对接 <span> {{ pairInfo.output.total }} </span> 笔</div>
            <div fx>
              <ul :style="{animationDuration: pairInfo.output.rows.length + 's'}" v-if="pairInfo.output.rows.length">
                <li v-for="(i, index) in pairInfo.output.rows.concat(pairInfo.output.rows)" :key='index'>
                  <b class="title">{{i.name}}</b>
                  <span>{{ i.createTime.replace(/\s.+$/, '') }}</span>
                </li>
              </ul>
              <el-empty description="暂无数据" v-else/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="gray">
      <h4>创新需求<span>Innovation Needs</span><router-link fx to="/requirement/list">查看更多></router-link></h4>
      <ul class="tabs">
        <li @click="requirement.update({industryId: i.industryId})" :class="{active: i.industryId == requirement.input.industryId}" v-for="i in [{name: '全部'}].concat(enums.industryList)">{{i.name}}</li>
      </ul>
      <my-list :list="requirement.output.rows" mode="small"></my-list>
    </section>
    <section>
      <h4>创新资源<span>Innovation Resources</span><router-link fx to="/provide/list">查看更多></router-link></h4>
      <ul class="tabs">
        <li @click="provide.update({industryId: i.industryId})" :class="{active: i.industryId == provide.input.industryId}" v-for="i in [{name: '全部'}].concat(enums.industryList)">{{i.name}}</li>
      </ul>
      <my-list :list="provide.output.rows" mode="small"></my-list>
    </section>
    <section class="gray">
      <h4>创新服务<span>Innovative Services</span><router-link fx to="/service/list">查看更多></router-link></h4>
      <ul class="tabs">
        <li @click="service.update({type: i.value})" :class="{active: i.value == service.input.type}" v-for="i in [{name: '全部'}].concat(enums.serviceType.map(i => ({name: i, value: i})))">{{i.name}}</li>
      </ul>
      <my-list :list="service.output.rows" mode="small"></my-list>
    </section>
    <section>
      <h4>创新成果<span>Innovative Product</span><router-link fx to="/achievement/list">查看更多></router-link></h4>
      <ul class="tabs">
        <li @click="achievement.update({industryId: i.industryId})" :class="{active: i.industryId == achievement.input.industryId}" v-for="i in [{name: '全部'}].concat(enums.industryList)">{{i.name}}</li>
      </ul>
      <my-list :list="achievement.output.rows" mode="small"></my-list>
    </section>
    <section>
      <h4>产学研资源库<span>Industry-university-research Resource Library</span><router-link fx to="/org/list">查看更多></router-link></h4>
      <div class="res-lib">
        <div :class="{active: org.activeType == 1}" @mouseenter="org.activeType = 1">
          <img src="@/assets/index-qyk-a.png" class="active">
          <img src="@/assets/index-qyk.png">
          <div class="title">企业库</div>
          <p>Enterprise Library</p>
          <ul>
            <li v-for="i in orgList.filter(i => i.attributeType == 1)">
              <router-link :to="{path: '/org/detail', query: {id: i.organizationId}}">{{ i.name }}</router-link>
            </li>
          </ul>
        </div>
        <div :class="{active: org.activeType == 2}" @mouseenter="org.activeType = 2">
          <img src="@/assets/index-gxk-a.png" class="active">
          <img src="@/assets/index-gxk.png">
          <div class="title">高校库</div>
          <p>University Library</p>
          <ul>
            <li v-for="i in orgList.filter(i => i.attributeType == 2)">
              <router-link :to="{path: '/org/detail', query: {id: i.organizationId}}">{{ i.name }}</router-link>
            </li>
          </ul>
        </div>
        <div :class="{active: org.activeType == 3}" @mouseenter="org.activeType = 3">
          <img src="@/assets/index-kyys-a.png" class="active">
          <img src="@/assets/index-kyys.png">
          <div class="title">科研院所库</div>
          <p>Library Of Research Institutes</p>
          <ul>
            <li v-for="i in orgList.filter(i => i.attributeType == 3)">
              <router-link :to="{path: '/org/detail', query: {id: i.organizationId}}">{{ i.name }}</router-link>
            </li>
          </ul>
        </div>
        <div :class="{active: org.activeType == 4}" @mouseenter="org.activeType = 4">
          <img src="@/assets/index-zjfw-a.png" class="active">
          <img src="@/assets/index-zjfw.png">
          <div class="title">中介服务机构</div>
          <p>Intermediary Service Agency</p>
          <ul>
            <li v-for="i in orgList.filter(i => i.attributeType == 4)">
              <router-link :to="{path: '/org/detail', query: {id: i.organizationId}}">{{ i.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="gray">
      <h4>成功案例<span>Innovative Product</span><router-link fx to="/case/list">查看更多></router-link></h4>
      <my-list :list="cases.output.rows" mode="case"></my-list>
    </section>
  </main>
</template>
<script src="./index.js"></script>
<style scoped src="./index.scss" lang="scss"></style>