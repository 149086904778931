<template>
  <main>
    <section style="margin-bottom:20px">
      <slot name="top" :detail="detail"></slot>
    </section>
    <section>
      <div fx style="align-items: flex-start">
        <div class="left">
          <div class="card">
            <div><i style="background-image: linear-gradient(to bottom, rgb(253,209,114), rgb(246,147,42))">&#xe720;</i>我要对接</div>
            <div>
              <my-list :list="[list.activeOne]" mode="small" v-if="list.activeOne" stopToDetail></my-list>
              <el-empty description="请选择右侧资源列表进行对接" v-else style="height:412px"/>
              <el-input v-model="detail.text" type="textarea" style="margin-bottom:20px" :autosize="{ minRows: 10, maxRows: 400 }" placeholder="10~300个字符，描述你对项目的理解，过往的相关经验和采用的技术方案，详细地描述增加你的选中参与项目的机率"></el-input>
              <div style="text-align:center">
                <el-button type="primary" @click="submit">请求对接</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="card">
            <div><i style="background-image: linear-gradient(to bottom, rgb(67,165,249), rgb(55,140,242))">&#xe717;</i>选择对接资源</div>
            <div style="height:696px;overflow:auto">
              <my-list :list="list.output.rows" mode="large" stopToDetail @row-click="list.clickItem"></my-list>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<style src="./index.scss" scoped lang="scss"></style>
<script src="./index.js"></script>