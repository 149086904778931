<template>
  <my-r-detail :id="$route.query.id"></my-r-detail>
</template>
<style scoped lang="scss">
:deep(*) {
  > section{
    margin-top:-50px;
    width:100%;
  }
}
</style>