import mixin from "@/common/mixin";
import ajax from "@/common/ajax";
import {ref, reactive, getCurrentInstance} from 'vue'
import {useRouter} from "vue-router";

export default {
  data() {
    return {
      showForget:false
    };
  },
  el: "main",
  mixins: [mixin],
  setup () {
    const form = ref(null)
    const router = useRouter()
    const vm = getCurrentInstance()

    vm.proxy.$root.userInfo.clear()
    vm.proxy.$root.message.output.total = 0
    localStorage.removeItem('Authorization')
    localStorage.removeItem('userInfo')

    vm.proxy.$root.getUserInfo(() => {
      router.push({path: '/user/info'})
    })

    let remember = localStorage.rememberPassword ? JSON.parse(localStorage.rememberPassword) : {}
    const login = reactive({
      input: Object.assign({}, remember),
      remember: false,
      submit () {
        form.value.validate(async (valid) => {
          if (!valid) return
          let res = await ajax({
            url: '/website/login',
            data: Object.assign({}, captchaImage.output, login.input),
            method: 'POST'
          })
          if (res.code === 200) {
            window.localStorage.Authorization = 'Bearer ' + res.token
            if (login.input.remember) {
              let data = JSON.parse(JSON.stringify(login.input))
              delete data.code
              localStorage.setItem('rememberPassword', JSON.stringify(data))
            } else {
              localStorage.removeItem('rememberPassword')
            }
            vm.proxy.$root.getUserInfo(() => {
              router.push({path: '/user/info'})
            })
          } else {
            captchaImage.update()
          }
        })
      }
    })
    const captchaImage = reactive({
      output: {},
      async update () {
        let res = await ajax({url: '/captchaImage'})
        if (res.code === 200) {
          captchaImage.output = res
        }
      }
    })
    captchaImage.update()
    return {form,login,captchaImage}
  },
  methods: {
    showPword () {
      this.showForget = true
    },
    close(){
      this.showForget = false
    }
  }
}