<template>
  <div @click="currentValue = name + ' desc'" v-if="currentValue === (name + ' asc')" style="color:#358AF2">{{ label }} <caret-top /></div>
  <div @click="currentValue = undefined" v-else-if="currentValue === (name + ' desc')" style="color:#358AF2">{{ label }} <caret-bottom /></div>
  <div @click="currentValue = name + ' asc'" v-else>{{ label }} <d-caret /></div>
</template>
<script>
import {DCaret, CaretTop, CaretBottom} from '@element-plus/icons-vue'
export default {
  props: ['value', 'name', 'label'],
  components: {DCaret, CaretTop, CaretBottom},
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (e) {
        this.$emit('update:value', e)
        this.$emit('change', e)
      }
    }
  }
}
</script>
<style scoped>
div{
  cursor:pointer;
  user-select: none;
}
svg{
  width:15px;
  height:15px;
  transform:translateY(2px);
}
</style>