<template>
  <main>
    <section>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/my-project/list' }">我参与的项目</el-breadcrumb-item>
        <el-breadcrumb-item>项目编辑</el-breadcrumb-item>
      </el-breadcrumb>
      <div fx>
        <div class="left">
          <div class="user" style="height:200px">
            <div fx><img :src="user.organization.logo || '/banner.jpg'" alt="" @load="resizeImage"></div>
            <h5>{{ user.name }}</h5>
            <p>{{ user.orgName }}</p>
          </div>
        </div>
        <div class="form right">
          <el-button class="edit-button" size="small" @click="back">取消</el-button>
          <div fx class="title">
            <div>项目编辑<span>(*为必填)</span></div>
          </div>
          <el-form :model="form.input" ref="formRef" label-width="150px">
            <el-form-item label="名称" prop="name">
              <el-input v-model="form.input.name" placeholder="请输入"></el-input>
            </el-form-item>
            <my-industry :input="form.input"></my-industry>
            <el-form-item label="详细信息" prop="describeInfo" style="width:950px">
              <my-editor v-model:value="form.input.describeInfo"></my-editor>
            </el-form-item>
            <el-form-item label="合同文件" prop="contractDocPath">
              <my-upload v-model:value="form.input.contractDocPath" :info="{type: '4', pairInfoId: form.input.pairInfoId}"></my-upload>
            </el-form-item>
            <el-form-item label="项目周期" prop="deliverDays" :rules="[{required: true, message: '必填项'}]">
              <el-input v-model="form.input.deliverDays" placeholder="请输入">
                <template #append>天</template>
              </el-input>
            </el-form-item>
            <el-form-item label="开始时间" prop="startTime" :rules="[{required: true, message: '必填项'}]">
              <el-date-picker
                  v-model="form.input.startTime"
                  value-format="YYYY-MM-DD"
                  type="date"
                  placeholder="请选择"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="合作方式" prop="cpMode" :rules="[{required: true, message: '必填项'}]">
              <el-checkbox-group v-model="form.input.cpMode">
                <el-checkbox :label="i" :value="i" v-for="i in enums.cpMode"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="封面" prop="cover">
              <my-upload v-model:value="form.input.cover" :info="{type: '4', pairInfoId: form.input.pairInfoId}"></my-upload>
            </el-form-item>
            <el-form-item label="项目负责人" prop="director" :rules="[{required: true, message: '必填项'}]">
              <el-input v-model="form.input.director" placeholder="请输入" style="margin-bottom:10px"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="tel" :rules="[{required: true, message: '必填项', trigger: 'change'},
              {
                validator: (rule, value, callback) => {
                  const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
                  if (reg.test(value)) { callback();} else {
                    callback(new Error());
                  }
                },
                message: '请输入正确的联系电话',trigger: 'blur',
              }]">
              <el-input v-model="form.input.tel" placeholder="请输入" style="margin-bottom:10px"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱" prop="techDirector">
              <el-input v-model="form.input.email" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">保存</el-button>
              <el-button @click="back">取消</el-button>
            </el-form-item>


            <ul class="match">
              <li v-if="detail.output.requirement">
                <div class="icon" style="background-color: rgb(53,138,242)">
                  <i>&#xe6f7;</i>
                  <div>需求方</div>
                </div>
                <div class="content">
                  <div class="title">{{ detail.output.requirement?.name }}</div>
                  <div>{{ detail.output.requirement?.orgName }}</div>
                </div>
                <div class="link">
                  <el-button type="text" @click="router.push({path: '/requirement/detail', query: {id: detail.output.requirement.requirementId}})">查看详情<i>&#xe6fc;</i></el-button>
                </div>
              </li>

              <li v-if="detail.output.provide">
                <div class="icon" style="background-color: rgb(53,182,242)">
                  <i>&#xe70c;</i>
                  <div>资源方</div>
                </div>
                <div class="content">
                  <div class="title">{{ detail.output.provide?.name }}</div>
                  <div>{{ detail.output.provide?.orgName }}</div>
                </div>
                <div class="link">
                  <el-button type="text" @click="router.push({path: '/provide/detail', query: {id: detail.output.provide.provideId}})">查看详情<i>&#xe6fc;</i></el-button>
                </div>
              </li>

              <li v-if="detail.output.service">
                <div class="icon" style="background-color: rgb(53,138,242)">
                  <i>&#xe6f7;</i>
                  <div>服务方</div>
                </div>
                <div class="content">
                  <div class="title">{{ detail.output.service?.name }}</div>
                  <div>{{ detail.output.service?.orgName }}</div>
                </div>
                <div class="link">
                  <el-button type="text" @click="router.push({path: '/service/detail', query: {id: detail.output.service.serviceId}})">查看详情<i>&#xe6fc;</i></el-button>
                </div>
              </li>
            </ul>
          </el-form>
        </div>
      </div>
    </section>
  </main>
</template>
<script src="./index.js"></script>
<style scoped src="../../user/index.scss" lang="scss"></style>
<style src="./index.scss" scoped lang="scss"></style>