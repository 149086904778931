<template>
  <my-ps-detail api="provideDetail" :id="$route.query.id"></my-ps-detail>
</template>
<style scoped lang="scss">
:deep(*) {
  .banner{
    height:0;
    margin: 0!important;
    margin-top: -40px!important;
    overflow:hidden;
  }
  section{
    width:100%;
  }
}
</style>