<template>
  <div>
    <my-list v-if="detail.output.name" :list="[detail.output]" mode="large"></my-list>
    <div v-for="i in list.output.rows" class="company shadow" v-if="list.output.total">
      <div fx>
        <div fx class="div-img">
          <img :src="i.logo" alt="" @load="resizeImage">
        </div>
        <div>
          <div class="name">{{ i.rorgName || i.porgName || i.sorgName }}<el-button plain type="primary" size="small">
            <router-link style="color:currentColor" target="_blank" :to="{path: '/org/detail', query: {id: i.rorgId || i.porgId || i.sorgId}}">查看主页</router-link>
          </el-button></div>
          <div style="color:#999;font-size:14px;line-height:2">胜任理由：<span style="color:#666">{{ i.reason }}</span></div>
          <div style="color:#999;font-size:14px;line-height:2;display:flex">
            <div style="margin-right:120px">匹配来源：<span style="color:#666">{{ enums.pairType.getItem(i.pairType).label }}</span></div>
            <div v-if="i.pairType == 1">报名时间：<span style="color:#666">{{ i.applyDate }}</span></div>
            <div v-else>匹配时间：<span style="color:#666">{{ i.applyDate }}</span></div>
          </div>
        </div>
      </div>
      <div>
        <div class="title">提供</div>
        <my-list :list="[{
          name: i.rname || i.pname || i.sname,
          describeInfo: i.rdetail || i.pdetail || i.sdetail,
          labels: i.labels,
          cover: i.cover,
          price: i.price,
          amountRange: i.amountRange,
          industryName: i.industryName,
          brief: i.detail,
          serviceId: i.type === '3' && i.rid,
          provideId: i.type === '2' && i.rid,
          requirementId: i.type === '1' && i.rid
        }]" mode="large"></my-list>
      </div>
      <div v-if="i.status == '0'">
        <el-button @click="list.changeItem(i, {status: '1', fid: detail.output[enums.itemType.getItem(user.type).name + 'Id']})" plain type="primary">
          <i style="font-size:12px;margin-right:5px">&#xe6f0;</i>确认合作
        </el-button>
        <el-button @click="list.changeItem(i, {status: '0', fid: detail.output[enums.itemType.getItem(user.type).name + 'Id']})" plain type="danger">
          <i style="font-size:20px;margin-right:3px">&#xe70a;</i>不合适
        </el-button>
      </div>
      <div v-if="i.status == '-1'" style="text-align: center;color: #999;display:block">已失效</div>
      <div v-if="i.status == '1'" style="text-align: center;color: #999;display:block">等待对方确认合作</div>
      <div v-if="i.status == '2'" style="text-align: center;color: #999;display:block">已拒绝</div>
      <div v-if="i.status == '3'" style="text-align: center;color: #999;display:block">对方已拒绝</div>
      <div v-if="i.status == '4'" style="text-align: center;color: #358AF2;display:block">已达成合作</div>
    </div>
    <el-empty v-else description="暂无对接意向人"></el-empty>
    <el-pagination v-if="list.output.total > list.output.rows.length"
                   :page-size="list.input.pageSize"
                   :page="list.input.pageNum"
                   background @current-change="p => list.update({pageNum: p})"
                   layout="prev, pager, next"
                   :total="list.output.total"/>
  </div>
</template>
<script src="./index.js"></script>
<style scoped src="./index.scss" lang="scss"></style>