import mixin from "@/common/mixin";
import ajax from "@/common/ajax";
import enums from "../../../../common/model/enums";

export default {
  mixins: [mixin],
  data: function () {
    // var checkPass  = (rule, value, callback) => {
    //   if(value.length <8){
    //     return callback('密码不能小于8位')
    //   }
    // }
    return {
      enums,
      register: {
        input: {},
        repassword: undefined,
        agree: false
      },
      rules:{
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              const reg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}/;
              if (reg.test(value)) { callback();} else {
                callback(new Error());
              }
            },
            message: "密码必须由数字、字母、特殊字符组合,请输入8-16位",trigger: "blur",
          }]
      }
    }
  },
  created: function () {
    this.register.input.type = this.$route.query.type
  },
  methods: {
    submit: function () {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return
        let res = await ajax({url: '/website/register', data: this.register.input, method: 'POST'})
        if (res.code === 200) {
          this.$alert('注册成功', '温馨提示', {
            confirmButtonText: 'OK',
            callback: (action) => {
              this.$router.push('/login')
            }
          })
        }
      })
    }
  }
}