<template>
  <main>
    <section style="border:none;background:none">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i>&#xe6ee;</i></el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/notice/list', query: {type: notice.output.type }}" v-if="notice.output.type == 1">通知公告</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/notice/list', query: {type: notice.output.type }}" v-if="notice.output.type == 2">政策法规</el-breadcrumb-item>
        <el-breadcrumb-item>{{ notice.output.title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </section>
    <section>
      <div class="ql-editor title" v-html="notice.output.title"></div>
      <p>{{notice.output.createTime}}</p>
      <div class="detail ql-container ql-snow"><div class="ql-editor" v-html="notice.output.describeInfo"></div></div>

      <div class="title-tag" v-if="notice.output.docPath && notice.output.docPath.length"><span>相关文档</span></div>
      <div class="detail" v-if="notice.output.docPath && notice.output.docPath.length">
        <ul class="docs">
          <li v-for="i in notice.output.params.files">
            <a download :href="i.url" target="_blank">
              <div class="doc-icon">
                <i>&#xe6fd;</i>
              </div>
              <div class="doc-name">
                <p>{{ i.fileName }}</p>
                <span>{{ i.createTime }}</span>
              </div>
              <div class="doc-download">
                <i>&#xe70b;</i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>
<script>
import {useRouter, useRoute} from "vue-router";
import {apis} from "@/utils/apis";
export default {
  setup () {
    const route = useRoute()

    const notice = apis.detail({
      api: 'noticeDetail',
      input: { type: route.query.type, noticeId: route.query.id}
    })
    notice.update()

    return {notice}
  }
}
</script>
<style scoped lang="scss">
section {
  padding: 20px;
  background-color: #FFF;
  border: 1px solid #DDD;
  display: block;
}
.title{
  font-size:18px;
  text-align: center;
  + p{
    color: #999;
    font-size: 14px;
    text-align: center;
  }
}
p {
  line-height: 2;
  text-indent: 2em;
}
h1{
  font-size:24px;
  font-weight:400;
}
h2 {
  font-weight:400;
  font-size:18px;
}
</style>