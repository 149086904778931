import { getCurrentInstance} from 'vue'
import {useRoute, useRouter} from "vue-router";
import enums from "../../../../../common/model/enums";
import mixin from "@/common/mixin";
import {apis} from "@/utils/apis";
export default {
  mixins: [mixin],
  setup() {
    const vm = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    const user = vm.proxy.$root.userInfo

    const detail = apis.projectDetail({
      input: JSON.parse(JSON.stringify(route.query))
    });
    (async function () {
      await detail.update()
      Object.assign(form.input, detail.output)
    })()

    const form = apis.form({
      api: 'projectForm', method: 'POST',
      input: {
        labels: [], contractDocPath: []
      }
    })

    return {form, route, router, user, enums, detail}
  },
  methods: {
    async submit () {
      this.form.input.brief = this.html_f30(this.form.input.detail)
      await this.form.submit(this.$refs.formRef)
      this.back()
    },
    back () {
      this.router.push({path: `/my-project/list`})
    }
  }
}