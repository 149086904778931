<template>
  <div fx class="list" :class="mode" v-if="mode === 'large'">
    <ul v-if="list.length">
      <li v-for="i in list" @click="toDetail(i, $event)">
        <div fx>
          <span v-if="i.pairInfoId" :class="'ps' + i.status">{{ enums.pairStatus.getItem(i.status).label || '无' }}</span>
          <template v-else-if="i.achievementId"><span v-if="i.status == '0'">下架</span></template>
          <span v-else-if="i.status !== '3'" :class="'s' + i.status">{{ enums.itemStatus.getItem(i.status).label || '无' }}</span>
          <img :src="i.cover" alt="" @load="resizeImage">
        </div>
        <div fx :style="{flex: hasRightSlot ? '' : '1', paddingTop: i.itemType === '4' ? '10px' : ''}">
          <h5>
<!--            <span v-if="i.industryName" class="type">{{ i.industryName }}</span>-->
            <span class="lt">{{ i.name || i.pname  }}</span>
            <div fx class="price" v-if="i.areaName && !hasRightSlot"><i style="color:#358AF2">&#xe71e;</i>{{ i.areaName }}</div>
          </h5>
          <p style="margin-bottom:10px">{{ html_f30(i.brief || i.describeInfo || '暂无描述') }}</p>
          <div fx class="org">
            <span :title="i.industryName">行业领域：{{ i.industryName || '无' }}</span>
            <span v-if="i.itemType === '1'">需求类型：{{ i.type || '无' }}</span>
            <span v-if="i.itemType === '2' || i.itemType === '4'">成熟度：{{ i.maturity || '无' }}</span>
            <span v-if="i.itemType === '3'">服务类型：{{ i.type || '无' }}</span>
            <span v-if="i.itemType === '1'">交付周期：{{ i.deliverDays || '0' }}天</span>
            <span v-if="i.itemType === '4'">行业分类：{{ i.guiName || '无' }}</span>
            <span>资金规模：{{ i.amountRange || '面议' }}</span>
            <span v-if="i.itemType === '4'">发布单位：{{ i.orgName || '无' }}</span>
            <span v-if="i.itemType === '4'">发布时间：{{ i.createTime || '无' }}</span>
          </div>
          <div fx class="tags">
            <span v-for="j in i.labels" :key="j">{{ j }}</span>
          </div>
          <div v-if="!hasRightSlot" class="list-time" style="position:absolute;right:20px;bottom:20px;color: #999">{{i.createTime}}</div>
        </div>
        <div v-if="hasRightSlot">
          <slot name="right" :i="i"></slot>
        </div>
      </li>
    </ul>
    <el-empty description="暂无数据" v-else/>
  </div>
  <div fx class="list" :class="mode" v-if="mode === 'small'">
    <ul v-if="list.length">
      <li v-for="i in list" @click="toDetail(i, $event)">
        <div fx>
          <span v-if="!i.achievementId && i.status !== '3'" :class="'s' + i.status">{{ enums.itemStatus.getItem(i.status).label || '无' }}</span>
          <img :src="i.cover" alt="" @load="resizeImage">
        </div>
        <div fx>
          <h5>
            <span v-if="i.attributeType" class="type">{{ enums.attributeType.getItem(i.attributeType).label }}</span>
            <span class="lt">{{ i.name }}</span>
          </h5>
          <p>{{ html_f30(i.describeInfo || i.manageRange || '暂无描述') }}</p>
          <div fx class="tags" v-if="!i.attributeType">
            <span v-for="j in i.labels">{{ j }}</span>
          </div>
          <div fx class="type">
            <span>{{ i.industryName || enums.industryList.getItem(j => j.industryId == i.industryId).name || '未分类'}}</span>
            <div fx v-if="i.amountRange"><b>{{i.amountRange}}</b></div>
          </div>
          <div fx class="org">
            <span>{{ i.orgName }}</span>
            <span>{{ i.createTime ? new Date(i.createTime).format('yyyy-MM-dd') : '无' }}</span>
          </div>
        </div>
      </li>
    </ul>
    <el-empty description="暂无数据" v-else/>
  </div>
  <!-- 成功案例 -->
  <div fx class="list" :class="mode" v-if="mode === 'case'">
    <ul v-if="list.length">
      <li v-for="i in list" @click="toDetail(i, $event)">
        <div fx>
          <span v-if="!i.achievementId && i.status !== '3'" :class="'s' + i.status">{{ enums.itemStatus.getItem(i.status).label || '无' }}</span>
          <img :src="i.cover" alt="" @load="resizeImage">
        </div>
        <div fx>
          <h5>
            <span class="lt">{{ i.rName }}</span>
          </h5>
          <p>项目结题时间:{{i.updateTime}}</p>
          <div class="org">
            <p><span>需求方：</span>{{ i.rOrgName }}</p>
            <p><span>资源方：</span>{{ i.pOrgName }}</p>
          </div>
        </div>
      </li>
    </ul>
    <el-empty description="暂无数据" v-else/>
  </div>
  <div fx class="list" :class="mode" v-if="mode === 'mini'">
    <ul v-if="list.length">
      <li v-for="i in list" @click="toDetail(i, $event)">
        <div fx class="div-img">
          <img :src="i.cover" alt="" @load="resizeImage">
        </div>
        <div>
          <h5 style="margin:2px 0px">
            <span class="lt">{{ i.name }}</span>
          </h5>
          <div fx class="type" style="margin-top:2px;margin-bottom:0">
            <span :title="i.industryName || '未分类'">{{ i.industryName || '未分类' }}</span>
            <div fx v-if="!i.requirementId"><b>{{ i.amountRange }}</b></div>
          </div>
          <div fx class="org">
            <span>{{ i.orgName }}</span>
            <span>{{ i.createTime ? i.createTime.replace(/\s.+$/, '') : '无' }}</span>
          </div>
        </div>
      </li>
    </ul>
    <el-empty description="暂无数据" v-else/>
  </div>
</template>
<script src="./index.js"></script>
<style scoped lang="scss">
:deep(*) {
  .el-button{
    height:30px;
    line-height:30px;
    border-color:currentColor;
  }
  .el-button+.el-button{
    margin-left:0;
    margin-top:5px;
  }
  .el-button + p{
    margin-top:5px;
  }
}
li > div:nth-of-type(1) span{
  &.s1{
    background-color:rgb(254, 145, 25);
  }
  &.s2{
    background-color:rgb(235, 116, 112);
  }
  &.s4{
    background-color: #999;
  }
  &.s5{
    background-color: #1faf47;
  }
}
li{
  position:relative;
}
li > div:nth-of-type(1) span{
  &.ps1{
    background-color:rgb(254, 145, 25);
  }
  &.ps2{
    background-color:#358AF2;
  }
  &.ps0{
    background-color: #999;
  }
  &.ps3{
    background-color: #1faf47;
  }
}

.list {
  overflow: hidden;
  display: block;
}
img {
  transition: transform .3s;
}

h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: 500;
  margin-bottom:8px;
  position:relative;
}



h5 + p {
  color: #9D9D9D;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
}

.tags {
  font-size: 14px;
  height: 27px;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom:10px;
  justify-content: flex-start;
}

.tags span {
  text-align: center;
  line-height: 25px;
  padding: 0 6px;
  height: 25px;
  border: 1px solid #EBF4FC;
  margin-bottom: 10px;
}

.tags span + span {
  margin-left: 10px;
}

.type {
  padding-bottom: 10px;
  border-bottom: 1px solid #EBF4FC;
  margin-bottom: 15px;
  margin-top: 10px;
  height: 40px;
}

.type span {
  background-color: #E3E8F0;
  padding: 4px 7px;
  font-size: 12px;
  color: #4A5770;
  overflow: hidden;
  height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.type div {
  font-size: 14px;
  align-items: baseline;
  white-space: nowrap;
}

.type b {
  font-size: 18px;
  font-weight: 600;
  color: #358AF2;
  margin-right: 3px;
}
li:hover {
  box-shadow: 0px 6px 10px 0px rgba(0, 121, 218, 0.2);
}
li div {
  height: auto;
}
li div:first-child{
  overflow:hidden;
  height:180px;
  background-color:#F3F3F3;
}
li:hover img {
  transform: scale(1.05);
}

li > div:nth-of-type(1) span {
  z-index: 2;
  position: absolute;
  min-width: 54px;
  height: 24px;
  padding: 0 8px;
  color: #FFF;
  background: #358AF2;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  left: 0;
  top: 0;
}
.small ul,.case ul  {
  width: calc(100% + 20px);
  overflow: hidden;
}
.mini li + li{
  border-top:1px solid #EEE
}
.small .org{
  color: #9D9D9D;
  font-size: 14px;
}
.mini .org {
  color: #9D9D9D;
  font-size: 12px;
}
.large .org{
  color: #333;
  font-size: 14px;
  width: calc(100% + 30px);
  margin-left: -30px;
  display:block;
}
.large .org span{
  display:inline-block;
  width:calc((100% - 60px) / 2);
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left:30px;
}
h5 .type{
  background-color: #E3E8F0;
  padding: 4px 7px;
  font-size: 12px;
  color: #4A5770;
  margin-right:5px;
  height:14px;
  margin-top: 18px;
}
.large h5{
  display:flex;
  height:25px;
  align-items: center;
  justify-content: space-between;
}
.large .lt{
  flex:1;
}
.large .price{
  font-size:14px;
  b{
    font-size:24px;
    color:#358AF2;
    font-weight:600;
    margin-right:5px;
  }
}

.small li,.case li {
  cursor: pointer;
  float: left;
  width: calc((100% - 60px) / 3);
  min-width:410px;
  margin-right: 20px;
  transition: box-shadow .3s;
  margin-bottom: 20px;
  border: 1px solid #EBF4FC;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
}

.small li:hover,.case li:hover {
  &:after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    background-color: #358AF2;
    bottom: 0;
    left: 0;
  }
}


.small li > div:first-child,.case li > div:first-child{
  width: 100%;
  height: 180px;
  justify-content: center;
  overflow: hidden;
  background-color:#DDD;
}

.small li > div:first-child + div,.case li > div:first-child + div{
  padding: 20px;
  display: block;
  background-color: #FFF;
}

.large {
  width: 100%;

  ul {
    width: 100%;
  }
}

.large li {
  background-color: #FFF;
  border: 1px solid #EBF4FC;
  height: 180px;
  margin-bottom: 20px;
  display: flex;
}

.large li > div:nth-of-type(1) {
  width: 287px;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
}



.case{
  li{
    padding-bottom: 15px;
    .org{
      border-top: 1px solid #EBF4FC;margin-top: 16px;padding-top: 10px;
      p{
        font-size: 14px;padding: 5px 0;
        span{color:#9D9D9D;}
      }
    }
  }
}


.large li > div:nth-of-type(2) {
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  width:538px;
  font-size: 14px;
  display: block;
}
.large li > div:nth-of-type(3) {
  border-left: 1px solid #EEE;
  text-align:center;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  flex:1;
  font-size:14px;
  line-height:1.5;
  button{
    height:30px;
    border-color:currentColor;
    color:#358AF2;
    margin-bottom:5px;
  }
}
.mini ul{
  width: 100%;
}
.mini li{
  margin-right: 0;
  width: 100%;
  border-left: none;
  border-right:none;
  border-bottom:none;
  margin-bottom: 0;
  padding-bottom: 20px;
  cursor: pointer;
}
.mini li + li{
  padding-top: 20px;
  border-top: 1px solid #EBF4FC;
}
.mini li:hover{
  box-shadow:none;
  border-bottom:none;
}
.mini li:hover + li{
  border-color:#358AF2;
}
.mini li:hover h5{
  color:#358AF2;
}
.mini h5{

  font-size: 17px;

  margin-top: 20px;

  margin-bottom: 10px;
}
.mini .type{
  padding-top:0;
  border-bottom: none;
  padding-bottom: 0;
}
.mini .type b{
  font-size: 16px;
}
</style>