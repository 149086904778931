import mixin from "@/common/mixin";
import {getCurrentInstance, reactive, watch} from "vue";
import ajax from "@/common/ajax";
import {useRouter} from "vue-router";
import {ElMessage, ElMessageBox} from "element-plus";
import enums from "../../../../../../common/model/enums";
import Items from "../../../../../../common/model/Items";

export default {
  mixins: [mixin],
  setup () {
    const vm = getCurrentInstance()
    const router = useRouter()
    const provide = reactive({
      output: {total: 0, rows: []},
      input: {
        industryId: undefined,
        pageNum: 1, pageSize: 6
      },
      async update (input = {}) {
        Object.assign(provide.input, input)
        window.scroll(0,0)
        let res = await ajax({url: '/website/provide/myProvidelist', data: provide.input})
        if (res.code !== 200) return
        res.rows = res.rows.map(i => new Items(i))
        provide.output = res
      }
    })
    provide.update()

    return {provide, enums, router}
  },
  methods: {
    async changeStatus (id, status) {
      await new Promise(res => {
        ElMessageBox.confirm('您确定要改变这条记录的状态吗？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          callback (action) {
            if (action === 'confirm') res()
          }
        })
      })
      await ajax({url: '/website/provide', data: {provideId: id, status}, method: 'PUT'})
      this.provide.update()
    }
  }
}